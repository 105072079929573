import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MessageBar,
  MessageBarType,
  ChoiceGroup,
  DefaultButton,
  Checkbox,
  TextField,
  Separator,
  TooltipHost,
  NormalPeoplePicker,
  Persona,
  IconButton,
} from 'office-ui-fabric-react';
import { Label } from 'office-ui-fabric-react/lib/Label';
import {
  AllocationTypePicker,
  GenericPicker,
  StatePicker,
  YearPicker,
  ElectionCyclePicker,
  FederalPacExpenditureTypePicker,
  FECElectionCyclePicker,
  PaymentTypesPicker,
  ExpenditureTypePicker,
  BudgetCategoryPicker,
  ContactPicker,
  ContributionRefundPicker,
  LoanPicker,
  DisbursementCategoryPicker,
  GAPaymentCodePicker, DatePicker,
} from '../Pickers';
import {
  disbursementCategories,
  DisbursementCategories,
  ExpenditureTypes,
  federalOfficesOptions,
  FederalPacExpenditureTypes,
  frCampaignTypes,
  FecEntityCodes,
} from '../../helpers/constants';
import { getLabel, businessNameLabel } from '../../helpers/labelHelper';
import { Grid, GridRow, Column, HelpIcon } from '../common';
import { getRecipientTypes } from './ExpenditureFormValidations';
import {
  CashPayment,
  CheckPayment,
  CreditCardPayment,
  DebitCardPayment,
} from '../PaymentSections';
import { getCampaign } from '../../selectors';
import {
  getFRCampaignType,
  canEditTransactionIdNumber,
} from '../../helpers/campaignHelper';
import { isBetaUser } from '../../helpers/sessionHelper';

import EndRecipient from './EndRecipient';
import './ExpenditureForm.css';

const {
  FederalCandidate,
  FederalNonCandidate,
} = frCampaignTypes;

const ExpenditureForm = ({
  transactionIdNumber,
  activityOrEventIdentifier,
  isAllocated,
  allocatedActivityOrEventType,
  electionYear,
  election,
  otherElectionType,
  expenditureType,
  disbursementCategory,
  budgetCategoryId,
  budgetCategories,
  contactType,
  paymentCode,
  paymentCodeOther,
  paymentType,
  selectedContact,
  forceItemization,
  isRedesignation,
  tags,
  amount,
  federalShare,
  nonFederalShare,
  datePaid,
  referenceId,
  purpose,
  checkNumber,
  cardLast4,
  cardType,
  endRecipients,
  totalRecipientAmount,
  actions,
  isCandidate,
  errors,
  validations,
  session,
  salutation,
  firstName,
  middleName,
  lastName,
  suffix,
  employer,
  occupation,
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
  county,
  phone1,
  phone2,
  email,
  memoCode,
  memoText,
  sourceCode,
  businessName,
  businessType,
  contactName,
  committeeAffiliation,
  cardholderName,
  cardholderEmployer,
  cardholderOccupation,
  committeeFecId,
  isContributionProcessingFee,
  selectedConduit,
  selectedSupportOppose,
  candidateOffice,
  candidateState,
  candidateDistrict,
  candidateFecId,
  candidateFirstName,
  candidateMiddleName,
  candidateLastName,
  refundContributionId,
  loanId,
  contributionOfficeSought,
  contributionOfficeState,
  contributionDistrict,
  contributionElectionYear,
  contributionElection,
  contributionOtherElectionType,
  supportOpposeCode,
  disseminationDate,
  completingFirstName,
  completingLastName,
  completingDateSigned,
}) => {
  const campaign = useSelector(getCampaign);
  const frCampaignType = getFRCampaignType(campaign);
  const canEditTransactionId = canEditTransactionIdNumber(campaign) && isBetaUser(session);

  const renderPaymentTypeForm = () => {
    const { paymentInfoActions, onBlurCheckNumber } = actions;
    const { checkNumberError } = validations;

    const amountRemaining =
      parseFloat(totalRecipientAmount || 0) - parseFloat(amount || 0);
    const showAmountRemaining =
      expenditureType === 'Credit Card' || expenditureType === 'Reimbursement' || expenditureType === 'In Kind';
    const nonFederalShareLabel = expenditureType === 'Federal Election Activity' ? 'Levin Share' : 'Non-Federal Share';

    const paymentTypeMap = {
      Cash: () => (
        <CashPayment
          actions={paymentInfoActions}
          amount={amount}
          amountRemaining={amountRemaining}
          amountErrorMessage={errors.amountErrorMessage}
          federalShareErrorMessage={errors.federalShareError}
          nonFederalShareErrorMessage={errors.nonFederalShareError}
          splitAmounts={isAllocated}
          federalShare={federalShare}
          nonFederalShare={nonFederalShare}
          nonFederalShareLabel={nonFederalShareLabel}
          dateReceived={datePaid}
          dateReceivedErrorMessage={errors.datePaidErrorMessage}
          referenceId={referenceId}
          sourceCode={sourceCode}
          dateLabel="Date Paid"
          showAmountRemaining={showAmountRemaining}
          showPurpose
          description={purpose}
          descriptionLabel="Purpose"
          descriptionFieldName="purpose"
        />
      ),
      Check: () => (
        <CheckPayment
          actions={paymentInfoActions}
          amount={amount}
          amountRemaining={amountRemaining}
          amountErrorMessage={errors.amountErrorMessage}
          federalShareErrorMessage={errors.federalShareError}
          nonFederalShareErrorMessage={errors.nonFederalShareError}
          splitAmounts={isAllocated}
          federalShare={federalShare}
          nonFederalShare={nonFederalShare}
          nonFederalShareLabel={nonFederalShareLabel}
          dateReceived={datePaid}
          dateReceivedErrorMessage={errors.datePaidErrorMessage}
          checkNumber={checkNumber}
          description={purpose}
          referenceId={referenceId}
          sourceCode={sourceCode}
          dateLabel="Date Paid"
          descriptionLabel="Purpose"
          descriptionFieldName="purpose"
          showAmountRemaining={showAmountRemaining}
          onBlurCheckNumber={onBlurCheckNumber}
          checkNumberError={checkNumberError}
        />
      ),
      'Credit Card': () => (
        <CreditCardPayment
          actions={paymentInfoActions}
          amount={amount}
          amountRemaining={amountRemaining}
          amountErrorMessage={errors.amountErrorMessage}
          federalShareErrorMessage={errors.federalShareError}
          nonFederalShareErrorMessage={errors.nonFederalShareError}
          splitAmounts={isAllocated}
          federalShare={federalShare}
          nonFederalShare={nonFederalShare}
          nonFederalShareLabel={nonFederalShareLabel}
          dateReceived={datePaid}
          dateReceivedErrorMessage={errors.datePaidErrorMessage}
          description={purpose}
          referenceId={referenceId}
          sourceCode={sourceCode}
          cardLast4={cardLast4}
          cardType={cardType}
          dateLabel="Date Paid"
          descriptionLabel="Purpose"
          descriptionFieldName="purpose"
          includeCardholderName={true}
          cardholderName={cardholderName}
          showAmountRemaining={showAmountRemaining}
        />
      ),
      'Debit Card': () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={amount}
          amountRemaining={amountRemaining}
          amountErrorMessage={errors.amountErrorMessage}
          federalShareErrorMessage={errors.federalShareError}
          nonFederalShareErrorMessage={errors.nonFederalShareError}
          splitAmounts={isAllocated}
          federalShare={federalShare}
          nonFederalShare={nonFederalShare}
          nonFederalShareLabel={nonFederalShareLabel}
          dateReceived={datePaid}
          dateReceivedErrorMessage={errors.datePaidErrorMessage}
          description={purpose}
          referenceId={referenceId}
          dateLabel="Date Paid"
          descriptionLabel="Purpose"
          descriptionFieldName="purpose"
          showAmountRemaining={showAmountRemaining}
        />
      ),
      'Wire Transfer': () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={amount}
          amountRemaining={amountRemaining}
          amountErrorMessage={errors.amountErrorMessage}
          federalShareErrorMessage={errors.federalShareError}
          nonFederalShareErrorMessage={errors.nonFederalShareError}
          splitAmounts={isAllocated}
          federalShare={federalShare}
          nonFederalShare={nonFederalShare}
          nonFederalShareLabel={nonFederalShareLabel}
          dateReceived={datePaid}
          dateReceivedErrorMessage={errors.datePaidErrorMessage}
          description={purpose}
          referenceId={referenceId}
          sourceCode={sourceCode}
          dateLabel="Date Paid"
          descriptionLabel="Purpose"
          descriptionFieldName="purpose"
          showAmountRemaining={showAmountRemaining}
        />
      ),
      ACH: () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={amount}
          amountRemaining={amountRemaining}
          amountErrorMessage={errors.amountErrorMessage}
          federalShareErrorMessage={errors.federalShareError}
          nonFederalShareErrorMessage={errors.nonFederalShareError}
          splitAmounts={isAllocated}
          federalShare={federalShare}
          nonFederalShare={nonFederalShare}
          nonFederalShareLabel={nonFederalShareLabel}
          dateReceived={datePaid}
          dateReceivedErrorMessage={errors.datePaidErrorMessage}
          description={purpose}
          referenceId={referenceId}
          sourceCode={sourceCode}
          dateLabel="Date Paid"
          descriptionLabel="Purpose"
          descriptionFieldName="purpose"
          showAmountRemaining={showAmountRemaining}
        />
      ),
      Other: () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={amount}
          amountRemaining={amountRemaining}
          amountErrorMessage={errors.amountErrorMessage}
          federalShareErrorMessage={errors.federalShareError}
          nonFederalShareErrorMessage={errors.nonFederalShareError}
          splitAmounts={isAllocated}
          federalShare={federalShare}
          nonFederalShare={nonFederalShare}
          nonFederalShareLabel={nonFederalShareLabel}
          dateReceived={datePaid}
          dateReceivedErrorMessage={errors.datePaidErrorMessage}
          description={purpose}
          referenceId={referenceId}
          sourceCode={sourceCode}
          dateLabel="Date Paid"
          descriptionLabel="Purpose"
          descriptionFieldName="purpose"
          showAmountRemaining={showAmountRemaining}
        />
      ),
    };

    if (paymentTypeMap[paymentType]) {
      return (
        <>
          <Separator />
          <div className="payment-type-section">
            {paymentTypeMap[paymentType]()}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                label="Mark as Contribution Processing Fee"
                checked={isContributionProcessingFee}
                onChange={actions.handleChange('isContributionProcessingFee')}
              />
              <TooltipHost
                content={`Check this box if this ${getLabel(
                  'expenditure',
                  session,
                )} is a service fee for imported contributions`}
                closeDelay={750}
              >
                <HelpIcon />
              </TooltipHost>
            </div>
          </div>
        </>
      );
    }

    if (expenditureType === 'In Kind') {
      return (
        <>
          <Separator />
          <div className="payment-type-section">
            <DebitCardPayment
              actions={paymentInfoActions}
              amount={amount}
              amountRemaining={amountRemaining}
              amountErrorMessage={errors.amountErrorMessage}
              dateReceived={datePaid}
              dateReceivedErrorMessage={errors.datePaidErrorMessage}
              description={purpose}
              referenceId={referenceId}
              sourceCode={sourceCode}
              dateLabel="Date Paid"
              descriptionLabel="Purpose"
              descriptionFieldName="purpose"
              showAmountRemaining={showAmountRemaining}
            />
          </div>
        </>
      );
    }

    return null;
  };

  const disbursementType = disbursementCategories.find(
    dc => dc.key === disbursementCategory,
  );

  const isOtherElection = election => {
    const result =
      session.isFederal() &&
      Boolean(election) &&
      election === 'Other';
    return result;
  };

  const businessNameErrorMessage = () => {
    if (expenditureType === 'Credit Card') {
      return errors.creditCardCompanyError;
    }

    return errors.businessNameError;
  };

  const onRenderItem = itemProps => {
    const { displayName, _id } = itemProps.item;

    const idLabel = _id ? `(Id: ...${_id.substring(_id.length - 4, _id.length + 1)})` : '';

    const name = `${displayName} ${idLabel}`;
    return (
      <div className="chosen-contact" key={itemProps.item._id}>
        <Persona text={name} size={10} />
        <IconButton
          iconProps={{ iconName: 'Times' }}
          onClick={() => actions.supportOpposeActions.onRemoveSupportOppose(itemProps.onRemoveItem)}
        />
      </div>
    );
  };

  const selectedConduitContact = selectedConduit ? [{ ...selectedConduit, key: 0 }] : [];
  const selectedSupportOpposeContact = selectedSupportOppose ? [{ ...selectedSupportOppose, key: 0 }] : [];
  const hasContactSelected = Boolean(selectedContact?._id);
  const showAllocatedCheck = expenditureType === 'Monetary' || expenditureType === 'Federal Election Activity';

  const getContactForm = () => {
    return (
      <GridRow>
        <Column>
          {errors.selectedContactError && (
            <MessageBar messageBarType={MessageBarType.error}>
              {errors.selectedContactError}
            </MessageBar>
          )}
          <ContactPicker
            session={session}
            isSpecialCCExpenditure={['Credit Card'].includes(expenditureType)}
            selectedContact={selectedContact}
            clearSelectedContact={actions.contactActions.clearSelectedContact}
            onResolveSuggestions={actions.onResolveSuggestions}
            onRenderSuggestionsItem={
              actions.onRenderSuggestionsItem
            }
            onItemSelected={actions.contactActions.onItemSelected}
            onChangeContactField={actions.handleChange}
            resetContactFields={actions.contactActions.resetContactFields}
            contactType={{
              label: 'Recipient Type',
              field: 'contactType',
              required: true,
              show: true,
              errorMessage: errors.recipientTypeError,
              value: contactType,
              options: getRecipientTypes(session.isFederal()),
              disabled: expenditureType === 'Credit Card',
            }}
            committeeFecId={{
              label: 'FEC ID',
              field: 'committeeFecId',
              value: committeeFecId,
              required: false,
              errorMessage: '',
            }}
            salutation={{
              label: 'Salutation',
              field: 'salutation',
              value: salutation,
              required: false,
              show: true,
            }}
            firstName={{
              label: 'First Name',
              field: 'firstName',
              required: true,
              show: true,
              errorMessage: errors.firstNameErrorMessage,
              value: firstName,
            }}
            middleName={{
              label: 'Middle Name',
              field: 'middleName',
              value: middleName,
              show: true,
            }}
            lastName={{
              label: 'Last Name',
              field: 'lastName',
              value: lastName,
              required: true,
              show: true,
              errorMessage: errors.lastNameErrorMessage,
            }}
            suffix={{
              label: 'Suffix',
              field: 'suffix',
              value: suffix,
              required: false,
              show: true,
            }}
            employer={{
              label: 'Employer',
              field: 'employer',
              value: employer,
              show: true,
            }}
            occupation={{
              label: 'Occupation',
              field: 'occupation',
              value: occupation,
              show: true,
            }}
            address1={{
              label: getLabel('Address Line 1', session),
              field: 'addressLine1',
              value: addressLine1,
              show: true,
            }}
            address2={{
              label: getLabel('Address Line 2', session),
              field: 'addressLine2',
              value: addressLine2,
              show: true,
            }}
            city={{
              label: 'City',
              field: 'city',
              value: city,
              show: true,
            }}
            state={{
              label: 'State',
              field: 'state',
              value: state,
              show: true,
            }}
            zipCode={{
              label: 'Zip Code',
              field: 'zipCode',
              value: zipCode,
              show: true,
            }}
            county={{
              label: 'County',
              field: 'county',
              value: county,
              show: true,
            }}
            phone1={{
              label: 'Phone 1',
              value: phone1,
              field: 'phone1',
              show: true,
            }}
            phone2={{
              label: 'Phone 2',
              field: 'phone2',
              value: phone2,
              show: true,
            }}
            email={{
              label: 'Email Address',
              field: 'email',
              value: email,
              show: true,
              errorMessage: errors.emailErrorMessage,
            }}
            businessName={{
              label: businessNameLabel(contactType),
              field: 'businessName',
              value: businessName,
              required: true,
              show: true,
              errorMessage: businessNameErrorMessage(),
            }}
            businessType={{
              label: 'Business Type',
              field: 'businessType',
              value: businessType,
              show: true,
            }}
            contactName={{
              label: 'Contact Name',
              field: 'contactName',
              value: contactName,
              show: true,
            }}
            committeeAffiliation={{
              label: 'Committee Type',
              field: 'committeeAffiliation',
              value: committeeAffiliation,
              show: true,
            }}
            cardholderName={{
              label: 'Cardholder Name',
              field: 'cardholderName',
              value: cardholderName,
              show: true,
            }}
            cardholderEmployer={{
              label: 'Cardholder Employer',
              field: 'cardholderEmployer',
              value: cardholderEmployer,
              show: true,
            }}
            cardholderOccupation={{
              label: 'Cardholder Occupation',
              field: 'cardholderOccupation',
              value: cardholderOccupation,
              show: true,
            }}
            candidateOffice={{
              label: 'Candidate Office',
              field: 'candidateOffice',
              value: candidateOffice,
              show: true,
            }}
            candidateState={{
              label: 'Candidate State',
              field: 'candidateState',
              value: candidateState,
              show: true,
            }}
            candidateDistrict={{
              label: 'Candidate District',
              field: 'candidateDistrict',
              value: candidateDistrict,
              show: true,
            }}
            candidateFecId={{
              label: 'Candidate FEC ID',
              field: 'candidateFecId',
              value: candidateFecId,
              show: true,
            }}
            candidateFirstName={{
              label: 'Candidate First Name',
              field: 'candidateFirstName',
              value: candidateFirstName,
              show: true,
            }}
            candidateMiddleName={{
              label: 'Candidate Middle Name',
              field: 'candidateMiddleName',
              value: candidateMiddleName,
              show: true,
            }}
            candidateLastName={{
              label: 'Candidate Last Name',
              field: 'candidateLastName',
              value: candidateLastName,
              show: true,
            }}
          />
        </Column>
      </GridRow>
    );
  };

  const getFederalCandidateForm = () => {
    // Presidential campaigns do not have a disbursement category of RefundsOfContributions
    // Use the expenditure type of Refund for presidential to determine isContributionRefund
    const isContributionRefund = disbursementCategory === DisbursementCategories.RefundsOfContributions || (campaign.office === 'P' && expenditureType === ExpenditureTypes.Refund);
    const isLoanRepayment = disbursementCategory === DisbursementCategories.LoanRepayments;
    const hasContactAndElectionSelected = Boolean(selectedContact?._id) && Boolean(electionYear) && Boolean(election);

    return (
      <Grid>
        {budgetCategories.length > 0 &&
        <GridRow>
          <Column lg={4}>
            <BudgetCategoryPicker
              label="Budget Category"
              selectedKey={budgetCategoryId}
              onChange={actions.handleChange('budgetCategoryId')}
              errorMessage={errors.budgetCategoryId}
              options={budgetCategories}
            />
          </Column>
        </GridRow>
        }
        <GridRow>
          <Column lg={isOtherElection(election) ? 2 : 4}>
            <YearPicker
              label="Election Year"
              required
              value={electionYear}
              onChange={actions.handleChange('electionYear')}
              errorMessage={errors.electionYearError}
            />
          </Column>
          <Column lg={isOtherElection(election) ? 3 : 4}>
            <FECElectionCyclePicker
              label="Disbursement For"
              required
              value={election}
              onChange={actions.handleFederalElectionChange}
              placeholder="Select"
              errorMessage={errors.electionError}
            />
          </Column>
          {isOtherElection(election) && (
            <Column lg={4}>
              <TextField
                label="Disbursement For Other"
                value={otherElectionType}
                required
                errorMessage={errors.otherElectionTypeError}
                onChange={actions.handleChange('otherElectionType')}
              />
            </Column>
          )}
          <Column lg={isOtherElection(election) ? 3 : 4}>
            <ExpenditureTypePicker
              session={session}
              selectedKey={expenditureType}
              onChange={actions.handleChange('expenditureType')}
              errorMessage={errors.expenditureTypeError}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column lg={6}>
            <DisbursementCategoryPicker
              disbursementCategory={disbursementCategory}
              errorMessage={errors.disbursementCategoryError}
              handleChange={actions.handleChange('disbursementCategory')}
            />
          </Column>
          {disbursementType && disbursementType.included.length > 0 && (
            <Column lg={6}>
              <p
                style={{ margin: '8px 0 0' }}
              >{`Valid Expenses for ${disbursementType.text}:`}</p>
              <ul>
                {disbursementType.included.map(type => (
                  <li key={type} style={{ fontWeight: 400 }}>
                    {type}
                  </li>
                ))}
              </ul>
            </Column>
          )}
        </GridRow>
        {(isContributionRefund && !hasContactAndElectionSelected) &&
        <GridRow>
          <Column lg={6}>
            <Label>Select an existing contact, &apos;Election Year&apos;, and &apos;Disbursement For&apos; to enable associating a contribution to a refund</Label>
          </Column>
        </GridRow>
        }
        {(isContributionRefund && hasContactAndElectionSelected) &&
        <GridRow>
          <Column lg={9}>
            <ContributionRefundPicker
              value={refundContributionId}
              label="Refunded Contribution"
              onChange={actions.handleContributionRefundChange}
              electionCycle={election}
              electionYear={electionYear}
              contactId={selectedContact._id}
              errorMessage={errors.refundContributionErrorMessage}
            />
          </Column>
        </GridRow>
        }
        {isLoanRepayment &&
        <GridRow>
          <Column lg={6}>
            <LoanPicker
              value={loanId}
              label="Loan"
              onChange={actions.handleLoanChange}
              errorMessage={errors.refundContributionErrorMessage}
            />
          </Column>
        </GridRow>
        }
        {expenditureType !== 'In Kind' && (
          <>
            <Separator />
            <GridRow>
              <Column>
                <PaymentTypesPicker
                  required
                  selectedKey={paymentType}
                  onChange={actions.handleChange('paymentType')}
                  errorMessage={errors.paymentTypeError}
                />
              </Column>
            </GridRow>
          </>
        )}
        <Separator />
        {getContactForm()}
        <GridRow>
          <Label>Conduit Contact</Label>
          <NormalPeoplePicker
            itemLimit={1}
            onResolveSuggestions={actions.onResolveSuggestions}
            onRenderSuggestionsItem={actions.onRenderSuggestionsItem}
            onItemSelected={actions.conduitActions.onConduitSelected}
            onRenderItem={onRenderItem}
            selectedItems={selectedConduitContact}
          />
        </GridRow>
        {renderPaymentTypeForm()}
        <Separator />
        <GridRow>
          <Column>
            <Checkbox
              label="Is Redesignation"
              checked={isRedesignation}
              onChange={actions.handleCoupledChange('isRedesignation', 'memoCode')}
            />
          </Column>
          <Column>
            <Checkbox
              label="Memo Code"
              checked={memoCode}
              onChange={actions.handleChange('memoCode')}
              disabled={isRedesignation}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <TextField
              label="Memo Text"
              value={memoText}
              onChange={actions.handleChange('memoText')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={3}>
            <TextField
              value={tags}
              onChange={actions.handleChange('tags')}
              label="Tags"
            />
          </Column>
          <Column md={3}>
            <TextField
              value={transactionIdNumber}
              label="Transaction Id Number"
              readOnly={!canEditTransactionId}
              onChange={canEditTransactionId ? actions.handleChange('transactionIdNumber') : null}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <Checkbox
              checked={forceItemization}
              label="Force Itemization"
              onChange={actions.handleChange('forceItemization')}
              checkmarkIconProps={{
                iconName: 'Check',
              }}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={isCandidate}
                label="Expenditure to Candidate"
                checkmarkIconProps={{
                  iconName: 'Check',
                }}
                onChange={actions.handleChange('isCandidate')}
              />
              {isLoanRepayment &&
              <TooltipHost
                content="Checking this box makes this item included in summary line 19a. When this box is unchecked, it will summarize to line 19b."
                closeDelay={750}
              >
                <HelpIcon />
              </TooltipHost>
              }
            </div>
          </Column>
        </GridRow>
        {(expenditureType === 'Credit Card' ||
          expenditureType === 'Reimbursement' ||
          expenditureType === 'In Kind') && (
          <GridRow>
            <Column sm={12} lg={4}>
              <DefaultButton
                text="Add End Recipient"
                iconProps={{
                  iconName: 'Plus',
                }}
                onClick={actions.openAddRecipientDialog}
              />
              {isBetaUser(session) &&
              <DefaultButton
                styles={{ root: { marginLeft: '24px' } }}
                text="Add Bulk End Recipient"
                iconProps={{
                  iconName: 'Plus',
                }}
                onClick={actions.openBulkAddRecipientDialog}
              />
              }
            </Column>
            {endRecipients.length > 0 &&
              endRecipients.filter(er => !er.isRemoved).length > 0 && (
                <Column sm={12} lg={8}>
                  <h3>End Recipients</h3>
                  {endRecipients
                    .filter(e => !e.isRemoved)
                    .map(er => (
                      <EndRecipient
                        recipient={er}
                        key={er._id}
                        editRecipient={actions.editRecipient}
                        deleteRecipient={actions.deleteRecipient}
                      />
                    ))}
                </Column>
            )}
          </GridRow>
        )}
      </Grid>
    );
  };

  const pacAllocatedSection = () => {
    return (
      <GridRow>
        <Column lg={3}>
          <AllocationTypePicker
            label="Allocation Type"
            selectedKey={allocatedActivityOrEventType}
            onChange={actions.handleChange('allocatedActivityOrEventType')}
            errorMessage={errors.allocatedActivityOrEventTypeError}
          />
        </Column>
        <Column lg={3}>
          <TextField
            label="Activity or Event Identifier"
            value={activityOrEventIdentifier}
            errorMessage={errors.activityOrEventIdentifierError}
            onChange={actions.handleChange('activityOrEventIdentifier')}
          />
        </Column>
      </GridRow>
    );
  };

  const candidateContributionSection = () => {
    return (
      <>
        <GridRow>
          <Column lg={isOtherElection(contributionElection) ? 2 : 4}>
            <YearPicker
              label="Contribution Election Year"
              required
              value={contributionElectionYear}
              onChange={actions.handleChange('contributionElectionYear')}
              errorMessage={errors.contributionElectionYearError}
            />
          </Column>
          <Column lg={isOtherElection(contributionElection) ? 3 : 4}>
            <FECElectionCyclePicker
              label="Contribution Disbursement For"
              required
              value={contributionElection}
              onChange={actions.handleContributionFederalElectionChange}
              placeholder="Select"
              errorMessage={errors.contributionElectionError}
            />
          </Column>
          {isOtherElection(contributionElection) && (
            <Column lg={4}>
              <TextField
                label="Contribution Disbursement For Other"
                value={contributionOtherElectionType}
                required
                errorMessage={errors.contributionOtherElectionTypeError}
                onChange={actions.handleChange('contributionOtherElectionType')}
              />
            </Column>
          )}
        </GridRow>
        <GridRow>
          <Column lg={3}>
            <GenericPicker
              fieldLabel="Office Sought"
              selectedKey={contributionOfficeSought}
              onChange={actions.handleChange('contributionOfficeSought')}
              errorMessage={errors.contributionOfficeSoughtError}
              options={federalOfficesOptions}
            />
          </Column>
          <Column lg={3}>
            <StatePicker
              fieldLabel="Office State"
              selectedKey={contributionOfficeState}
              onChange={actions.handleChange('contributionOfficeState')}
              errorMessage={errors.contributionOfficeStateError}
            />
          </Column>
          <Column lg={3}>
            <TextField
              label="Contribution District"
              value={contributionDistrict}
              required
              errorMessage={errors.contributionDistrictError}
              onChange={actions.handleChange('contributionDistrict')}
            />
          </Column>
        </GridRow>
      </>
    );
  };

  const getFederalNonCandidateForm = () => {
    const isContributionRefund = disbursementCategory === DisbursementCategories.RefundsOfContributions;
    const isLoanRepayment = disbursementCategory === DisbursementCategories.LoanRepayments;
    const isIndependentExpenditure = expenditureType === FederalPacExpenditureTypes.IndependentExpenditure;
    const isForFederalCandidateOrCommittee = contactType === FecEntityCodes.Candidate || contactType === FecEntityCodes.CandidateCampaignCommitteeFederallyRegistered;

    return (
      <Grid>
        {budgetCategories.length > 0 &&
        <GridRow>
          <Column lg={4}>
            <BudgetCategoryPicker
              label="Budget Category"
              selectedKey={budgetCategoryId}
              onChange={actions.handleChange('budgetCategoryId')}
              errorMessage={errors.budgetCategoryId}
              options={budgetCategories}
            />
          </Column>
        </GridRow>
        }
        <GridRow>
          <Column lg={6}>
            <FederalPacExpenditureTypePicker
              session={session}
              selectedKey={expenditureType}
              onChange={actions.handleChange('expenditureType')}
              errorMessage={errors.expenditureTypeError}
            />
          </Column>
          {isIndependentExpenditure &&
          <Column lg={6}>
            <ChoiceGroup
              required
              label="Support/Oppose"
              selectedKey={supportOpposeCode}
              onChange={actions.handleChange('supportOpposeCode')}
              styles={{ flexContainer: { display: 'flex' } }}
              options={[
                {
                  key: 'S',
                  text:
                    'Support',
                },
                {
                  key: 'O',
                  text:
                    'Oppose',
                  styles: { field: { marginLeft: '16px' } },
                },
              ]}
            />
          </Column>
          }
          {showAllocatedCheck &&
          <Column lg={2}>
            <Checkbox
              label="Is Allocated"
              checked={isAllocated}
              onChange={actions.handleChange('isAllocated')}
              styles={{ root: { marginTop: '34px' } }}
            />
          </Column>
          }
        </GridRow>
        <GridRow>
          <Column lg={6}>
            <DisbursementCategoryPicker
              disbursementCategory={disbursementCategory}
              errorMessage={errors.disbursementCategoryError}
              handleChange={actions.handleChange('disbursementCategory')}
              isIndependentExpenditure={isIndependentExpenditure}
            />
          </Column>
          {disbursementType && disbursementType.included.length > 0 && (
            <Column lg={6}>
              <p
                style={{ margin: '8px 0 0' }}
              >{`Valid Expenses for ${disbursementType.text}:`}</p>
              <ul>
                {disbursementType.included.map(type => (
                  <li key={type} style={{ fontWeight: 400 }}>
                    {type}
                  </li>
                ))}
              </ul>
            </Column>
          )}
        </GridRow>
        {(isAllocated && expenditureType !== FederalPacExpenditureTypes.FederalElectionActivity) &&
          pacAllocatedSection()
        }
        <GridRow>
          {isOtherElection(election) && (
            <Column lg={4}>
              <TextField
                label="Disbursement For Other"
                value={otherElectionType}
                required
                errorMessage={errors.otherElectionTypeError}
                onChange={actions.handleChange('otherElectionType')}
              />
            </Column>
          )}
        </GridRow>
        {isLoanRepayment &&
        <GridRow>
          <Column lg={6}>
            <LoanPicker
              value={loanId}
              label="Loan"
              onChange={actions.handleLoanChange}
              errorMessage={errors.refundContributionErrorMessage}
            />
          </Column>
        </GridRow>
        }
        {(isContributionRefund && !hasContactSelected) &&
        <GridRow>
          <Column lg={6}>
            <Label>Select an existing contact, to enable associating a contribution to a refund</Label>
          </Column>
        </GridRow>
        }
        {(isContributionRefund && hasContactSelected) &&
        <GridRow>
          <Column lg={9}>
            <ContributionRefundPicker
              value={refundContributionId}
              label="Refunded Contribution"
              onChange={actions.handleContributionRefundChange}
              electionCycle={null}
              electionYear={null}
              contactId={selectedContact._id}
              errorMessage={errors.refundContributionErrorMessage}
            />
          </Column>
        </GridRow>
        }
        {expenditureType !== 'In Kind' && (
          <>
            <Separator />
            <GridRow>
              <Column>
                <PaymentTypesPicker
                  required
                  selectedKey={paymentType}
                  onChange={actions.handleChange('paymentType')}
                  errorMessage={errors.paymentTypeError}
                />
              </Column>
            </GridRow>
          </>
        )}
        <Separator />
        {getContactForm()}
        {isIndependentExpenditure &&
        <GridRow>
          <Column md={6}>
            <Label>Support/Oppose Candidate</Label>
            <NormalPeoplePicker
              itemLimit={1}
              onResolveSuggestions={actions.onResolveSuggestions}
              onRenderSuggestionsItem={actions.onRenderSuggestionsItem}
              onItemSelected={actions.supportOpposeActions.onSupportOpposeSelected}
              onRenderItem={onRenderItem}
              selectedItems={selectedSupportOpposeContact}
            />
          </Column>
        </GridRow>
        }
        {isForFederalCandidateOrCommittee &&
        candidateContributionSection()
        }
        {isIndependentExpenditure &&
        <>
          <GridRow>
            <Column md={6}>
              <DatePicker
                onChange={actions.handleChangeDate('disseminationDate')}
                value={disseminationDate}
                required
                errorMessage={errors.disseminationDateError}
                label="Dissemination Date"
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={4}>
              <TextField
                value={completingFirstName}
                label="Completing First Name"
                onChange={actions.handleChange('completingFirstName')}
                required
              />
            </Column>
            <Column md={4}>
              <TextField
                value={completingLastName}
                label="Completing Last Name"
                onChange={actions.handleChange('completingLastName')}
                required
              />
            </Column>
            <Column md={4}>
              <DatePicker
                onChange={actions.handleChangeDate('completingDateSigned')}
                value={completingDateSigned}
                required
                errorMessage={errors.completingDateSignedError}
                label="Completing Date Signed"
              />
            </Column>
          </GridRow>
        </>
        }

        {!isIndependentExpenditure &&
        <GridRow>
          <Label>Conduit Contact</Label>
          <NormalPeoplePicker
            itemLimit={1}
            onResolveSuggestions={actions.onResolveSuggestions}
            onRenderSuggestionsItem={actions.onRenderSuggestionsItem}
            onItemSelected={actions.conduitActions.onConduitSelected}
            onRenderItem={onRenderItem}
            selectedItems={selectedConduitContact}
          />
        </GridRow>
        }
        {renderPaymentTypeForm()}
        <Separator />
        <GridRow>
          <Column>
            <Checkbox
              label="Is Redesignation"
              checked={isRedesignation}
              onChange={actions.handleCoupledChange('isRedesignation', 'memoCode')}
            />
          </Column>
          <Column>
            <Checkbox
              label="Memo Code"
              checked={memoCode}
              onChange={actions.handleChange('memoCode')}
              disabled={isRedesignation}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <TextField
              label="Memo Text"
              value={memoText}
              onChange={actions.handleChange('memoText')}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column md={3}>
            <TextField
              value={tags}
              onChange={actions.handleChange('tags')}
              label="Tags"
            />
          </Column>
          <Column md={3}>
            <TextField
              value={transactionIdNumber}
              label="Transaction Id Number"
              readOnly={!canEditTransactionId}
              onChange={canEditTransactionId ? actions.handleChange('transactionIdNumber') : null}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <Checkbox
              checked={forceItemization}
              label="Force Itemization"
              onChange={actions.handleChange('forceItemization')}
              checkmarkIconProps={{
                iconName: 'Check',
              }}
            />
          </Column>
        </GridRow>
        {(expenditureType === 'Credit Card' ||
          expenditureType === 'Reimbursement' ||
          expenditureType === 'In Kind') && (
          <GridRow>
            <Column sm={12} lg={4}>
              <DefaultButton
                text="Add End Recipient"
                iconProps={{
                  iconName: 'Plus',
                }}
                onClick={actions.openAddRecipientDialog}
              />
              {isBetaUser(session) &&
                <DefaultButton
                  styles={{ root: { marginLeft: '24px' } }}
                  text="Add Bulk End Recipient"
                  iconProps={{
                    iconName: 'Plus',
                  }}
                  onClick={actions.openBulkAddRecipientDialog}
                />
              }
            </Column>
            {endRecipients.length > 0 &&
              endRecipients.filter(er => !er.isRemoved).length > 0 && (
                <Column sm={12} lg={8}>
                  <h3>End Recipients</h3>
                  {endRecipients
                    .filter(e => !e.isRemoved)
                    .map(er => (
                      <EndRecipient
                        recipient={er}
                        key={er._id}
                        editRecipient={actions.editRecipient}
                        deleteRecipient={actions.deleteRecipient}
                      />
                    ))}
                </Column>
              )}
          </GridRow>
        )}
      </Grid>
    );
  };

  const getNonFederalForm = () => {
    const isContributionRefund = expenditureType === ExpenditureTypes.Refund;
    const isLoanRepayment = expenditureType === ExpenditureTypes.LoanRepayment;
    const hasContactAndElectionSelected = Boolean(selectedContact?._id) && Boolean(electionYear) && Boolean(election);

    return (
      <Grid>
        {budgetCategories.length > 0 &&
        <GridRow>
          <Column lg={4}>
            <BudgetCategoryPicker
              label="Budget Category"
              selectedKey={budgetCategoryId}
              onChange={actions.handleChange('budgetCategoryId')}
              errorMessage={errors.budgetCategoryId}
              options={budgetCategories}
            />
          </Column>
        </GridRow>
        }
        <GridRow>
          <Column lg={isOtherElection(election) ? 2 : 4}>
            <YearPicker
              label="Election Year"
              required
              value={electionYear}
              onChange={actions.handleChange('electionYear')}
              errorMessage={errors.electionYearError}
            />
          </Column>
          <Column lg={isOtherElection(election) ? 3 : 4}>
            <ElectionCyclePicker
              label="Election"
              required
              value={election}
              onChange={actions.handleChange('election')}
              errorMessage={errors.electionError}
            />
          </Column>
          {isOtherElection(election) && (
            <Column lg={4}>
              <TextField
                label="Disbursement For Other"
                value={otherElectionType}
                required
                errorMessage={errors.otherElectionTypeError}
                onChange={actions.handleChange('otherElectionType')}
              />
            </Column>
          )}
          <Column lg={isOtherElection(election) ? 3 : 4}>
            <ExpenditureTypePicker
              session={session}
              selectedKey={expenditureType}
              onChange={actions.handleChange('expenditureType')}
              errorMessage={errors.expenditureTypeError}
            />
          </Column>
        </GridRow>
        <Separator />
        {campaign.officeType !== 'Local' &&
        <GridRow>
          <Column sm={12} lg={8}>
            <GAPaymentCodePicker
              pickerValue={paymentCode}
              otherValue={paymentCodeOther}
              onPickerChange={actions.handleChange('paymentCode')}
              onOtherChange={actions.handleChange('paymentCodeOther')}
            />
          </Column>
        </GridRow>
        }
        {expenditureType !== 'In Kind' && (
          <>
            <GridRow>
              <Column>
                <PaymentTypesPicker
                  required
                  selectedKey={paymentType}
                  onChange={actions.handleChange('paymentType')}
                  errorMessage={errors.paymentTypeError}
                />
              </Column>
            </GridRow>
          </>
        )}
        {(isContributionRefund && !hasContactAndElectionSelected) &&
        <GridRow>
          <Column lg={6}>
            <Label>Select an existing contact, &apos;Election Year&apos;, and &apos;Disbursement For&apos; to enable associating a contribution to a refund</Label>
          </Column>
        </GridRow>
        }
        {isLoanRepayment &&
          <GridRow>
            <Column lg={6}>
              <LoanPicker
                value={loanId}
                label="Loan"
                onChange={actions.handleLoanChange}
                errorMessage={errors.refundContributionErrorMessage}
              />
            </Column>
          </GridRow>
        }
        {(isContributionRefund && hasContactAndElectionSelected) &&
        <GridRow>
          <Column lg={9}>
            <ContributionRefundPicker
              value={refundContributionId}
              label="Refunded Contribution"
              onChange={actions.handleContributionRefundChange}
              electionCycle={election}
              electionYear={electionYear}
              contactId={selectedContact._id}
              errorMessage={errors.refundContributionErrorMessage}
            />
          </Column>
        </GridRow>
        }
        <Separator />
        {getContactForm()}
        {renderPaymentTypeForm()}
        <Separator />
        <GridRow>
          <Column md={3}>
            <TextField
              value={tags}
              onChange={actions.handleChange('tags')}
              label="Tags"
            />
          </Column>
          <Column md={3}>
            <TextField
              value={transactionIdNumber}
              label="Transaction Id Number"
              readOnly={!canEditTransactionId}
              onChange={canEditTransactionId ? actions.handleChange('transactionIdNumber') : null}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <Checkbox
              checked={forceItemization}
              label="Force Itemization"
              onChange={actions.handleChange('forceItemization')}
              checkmarkIconProps={{
                iconName: 'Check',
              }}
            />
          </Column>
        </GridRow>
        {(expenditureType === 'Credit Card' ||
          expenditureType === 'Reimbursement' ||
          expenditureType === 'In Kind') && (
          <GridRow>
            <Column sm={12} lg={4}>
              <DefaultButton
                text="Add End Recipient"
                iconProps={{
                  iconName: 'Plus',
                }}
                onClick={actions.openAddRecipientDialog}
              />
              {isBetaUser(session) &&
              <DefaultButton
                styles={{ root: { marginLeft: '24px' } }}
                text="Add Bulk End Recipient"
                iconProps={{
                  iconName: 'Plus',
                }}
                onClick={actions.openBulkAddRecipientDialog}
              />
              }
            </Column>
            {endRecipients.length > 0 &&
              endRecipients.filter(er => !er.isRemoved).length > 0 && (
                <Column sm={12} lg={8}>
                  <h3>End Recipients</h3>
                  {endRecipients
                    .filter(e => !e.isRemoved)
                    .map(er => (
                      <EndRecipient
                        recipient={er}
                        key={er._id}
                        editRecipient={actions.editRecipient}
                        deleteRecipient={actions.deleteRecipient}
                      />
                    ))}
                </Column>
              )}
          </GridRow>
        )}
      </Grid>
    );
  };

  if (frCampaignType === FederalCandidate) {
    return getFederalCandidateForm();
  }

  if (frCampaignType === FederalNonCandidate) {
    return getFederalNonCandidateForm();
  }

  return getNonFederalForm();
};

// contributionOfficeState,
// contributionDistrict,
// contributionOtherElectionType,

ExpenditureForm.propTypes = {
  allocatedActivityOrEventType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activityOrEventIdentifier: PropTypes.string,
  transactionIdNumber: PropTypes.string,
  electionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contributionElectionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  election: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  contributionElection: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  contributionOfficeState: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contributionDistrict: PropTypes.string,
  contributionOtherElectionType: PropTypes.string,
  otherElection: PropTypes.string,
  otherElectionType: PropTypes.string,
  expenditureType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disbursementCategory: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  budgetCategoryId: PropTypes.string,
  budgetCategories: PropTypes.array,
  contactType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sourceCode: PropTypes.string,
  selectedContact: PropTypes.object,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  datePaid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  referenceId: PropTypes.string,
  purpose: PropTypes.string,
  checkNumber: PropTypes.string,
  cardLast4: PropTypes.string,
  cardType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  forceItemization: PropTypes.bool,
  isRedesignation: PropTypes.bool,
  tags: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  actions: PropTypes.object.isRequired,
  endRecipients: PropTypes.array,
  isCandidate: PropTypes.bool,
  totalRecipientAmount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  validations: PropTypes.object,
  session: PropTypes.object.isRequired,
  errors: PropTypes.object,
  salutation: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  suffix: PropTypes.string,
  employer: PropTypes.string,
  occupation: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  memoCode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  memoText: PropTypes.string,
  zipCode: PropTypes.string,
  county: PropTypes.string,
  phone1: PropTypes.string,
  phone2: PropTypes.string,
  email: PropTypes.string,
  businessName: PropTypes.string,
  businessType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contactName: PropTypes.string,
  committeeAffiliation: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  cardholderName: PropTypes.string,
  cardholderEmployer: PropTypes.string,
  cardholderOccupation: PropTypes.string,
  committeeFecId: PropTypes.string,
  isContributionProcessingFee: PropTypes.bool,
  selectedConduit: PropTypes.object,
  candidateOffice: PropTypes.string,
  candidateState: PropTypes.number,
  candidateDistrict: PropTypes.string,
  candidateFecId: PropTypes.string,
  candidateFirstName: PropTypes.string,
  candidateMiddleName: PropTypes.string,
  candidateLastName: PropTypes.string,
  refundContributionId: PropTypes.string,
};

ExpenditureForm.defaultProps = {
  activityOrEventIdentifier: '',
  campaign: {},
  transactionIdNumber: '',
  electionYear: 0,
  election: 0,
  contributionElectionYear: 0,
  contributionElection: 0,
  contributionOfficeState: '',
  contributionDistrict: '',
  contributionOtherElectionType: '',
  otherElectionType: '',
  expenditureType: 0,
  disbursementCategory: '0',
  budgetCategoryId: '',
  budgetCategories: [],
  contactType: 0,
  paymentType: 0,
  selectedContact: {},
  amount: '',
  datePaid: '',
  referenceId: '',
  purpose: '',
  checkNumber: '',
  cardLast4: '',
  cardType: 0,
  forceItemization: false,
  isRedesignation: false,
  isReportable: false,
  tags: '',
  endRecipients: [],
  totalRecipientAmount: 0,
  validations: {},
  isCandidate: false,
  committeeFecId: '',
  isContributionProcessingFee: false,
  candidateOffice: '',
  candidateState: 0,
  candidateDistrict: '',
  candidateFecId: '',
  candidateFirstName: '',
  candidateMiddleName: '',
  candidateLastName: '',
  refundContributionId: '0',
};

export default ExpenditureForm;
