import React, { useEffect, useState, useRef } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import {
  Dialog,
  DialogType,
  DialogFooter,
  Checkbox,
  DefaultButton,
  PrimaryButton,
  TextField,
  Stack, Dropdown,
} from 'office-ui-fabric-react';
import { validate } from './RecipientDialogValidations';
import {
  Grid, GridRow, Column, MoneyField,
} from '../common';
import { useContactSearch } from '../../hooks/useContactSearch';
import {
  formatCurrency,
  formatDateOnly,
  deserializeDate,
} from '../../helpers/util';
import { parseAmex, parseServisFirst } from '../../helpers/bulkEndRecipientHelper';
import { businessTypes } from '../../helpers/constants';
import { getLabel } from '../../helpers/labelHelper';
import {
  BudgetCategoryPicker,
  ContactPicker,
  DatePicker,
  DisbursementCategoryPicker,
} from '../Pickers';
import { createContactState } from '../../helpers/endRecipientHelper';
import { CaretDown } from '../icons';

const getBusinessType = (item) => {
  if (typeof item.businessType === 'string' && item.businessType.length > 0) {
    return item.businessType;
  }

  if (typeof item.businessType === 'object' && item.businessType !== 0) {
    return item.businessType;
  }

  return 0;
};

function populateContact(recipient) {
  recipient.address = {
    ...{
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      county: '',
    },
    ...recipient.address,
  };

  return {
    committeeFecId: recipient.committeeFecId || '',
    salutation: recipient.salutation,
    firstName: recipient.firstName,
    middleName: recipient.middleName,
    lastName: recipient.lastName,
    suffix: recipient.suffix,
    employer: recipient.employer,
    occupation: recipient.occupation,
    address1: recipient.address.addressLine1,
    address2: recipient.address.addressLine2,
    city: recipient.address.city,
    state: recipient.address.state,
    zipCode: recipient.address.zipCode,
    county: recipient.address.county,
    disbursementCategory: recipient.disbursementCategory,
    businessName: recipient.businessName,
    businessType: getBusinessType(recipient),
    contactName: recipient.contactName,
    committeeAffiliation: recipient.committeeAffiliation || 0,
    phone1: recipient.phone1 || '',
    phone2: recipient.phone2 || '',
    email: recipient.email || '',
    contactId: recipient.contactId || undefined,
    forceItemize: recipient.forceItemize || false,
    memoText: recipient.memoText || '',
    _id: recipient._id,
  };
}

const initialErrorState = {
  firstNameErrorMessage: '',
  lastNameErrorMessage: '',
  businessNameError: '',
  datePaidError: '',
  recipientTypeError: '',
  amountError: '',
  disbursementCategory: '',
};

const initialFormState = {
  contactId: undefined,
  contactType: 0,
  businessName: '',
  businessType: 0,
  contactName: '',
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  employer: '',
  occupation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  phone1: '',
  phone2: '',
  email: '',
  amount: '',
  datePaid: new Date(),
  purpose: '',
  disbursementCategory: 0,
  forceItemize: false,
  committeeFecId: '',
  committeeAffiliation: 0,
  memoText: '',
  endRecipientBulkList: [],
  bulkFileType: 'ServisFirst',
};

const EndRecipientBulkLoadDialog = ({
  budgetCategories,
  recipient,
  election,
  electionYear,
  dialogHidden,
  totalAmount,
  actions,
  session,
  sourceTypes,
  recipientAmountTotal,
}) => {
  const amountRef = useRef(null);

  const [resetContactPicker, setResetContactPicker] = useState(0);
  const [formState, setFormState] = useState({ ...initialFormState });
  const [totalAmountRemaining, setTotalAmountRemaining] = useState(
    formatCurrency(totalAmount - recipientAmountTotal),
  );
  const [selectedContact, setSelectedContact] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [errors, setErrors] = useState({ ...initialErrorState });

  const onResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (!dialogHidden && amountRef && amountRef.current) {
      amountRef.current.focus();
    }
  }, [dialogHidden]);

  const getTotalAmountRemaining = (value) => {
    const total = parseFloat(totalAmount) || 0;
    const recipTotal = parseFloat(recipientAmountTotal);
    const recipientAmount = parseFloat((recipient && recipient.amount) || 0);
    const amountRemaining = formatCurrency(
      parseFloat(total - (recipTotal - recipientAmount) - value).toFixed(2),
    );
    return amountRemaining;
  };

  useEffect(() => {
    const amountRemaining = getTotalAmountRemaining(formState.amount || 0);
    setTotalAmountRemaining(amountRemaining);
  }, [totalAmount, recipientAmountTotal]);

  useEffect(() => {
    if (recipient !== null) {
      const populatedRecip = populateContact(recipient);
      setFormState({
        ...formState,
        contactType: recipient.contactType || 0,
        amount: recipient.amount,
        purpose: recipient.purpose,
        datePaid: deserializeDate(recipient.datePaid),
        ...(selectedContact || {}),
        addressLine1: populatedRecip.address1,
        addressLine2: populatedRecip.address2,
        ...populatedRecip,
        budgetCategoryId: recipient.budgetCategoryId,
      });
      setSelectedContact(recipient);
    }
  }, [recipient]);

  const handleSetBulkFormData = (datePaid, amount, purpose, searchString, bulkRowId) => {
    setFormState({
      ...formState,
      datePaid,
      amount,
      purpose,
      searchString,
      bulkRowId,
    });
  };
  const goToNextBulkRecipient = () => {
    const endRecipientBulkList = formState.endRecipientBulkList.filter((er) => er.id !== formState.bulkRowId);
    setErrors({ ...initialErrorState });
    setFormState({
      ...initialFormState,
      endRecipientBulkList,
    });
    setResetContactPicker((count) => count + 1);
    setSelectedContact(null);
    amountRef.current.focus();
  };

  const clearRecipientInfo = () => {
    setSelectedContact(null);
  };

  const handleChange = (fieldName) => (e, value) => {
    setFormState({
      ...formState,
      [fieldName]: value.key !== undefined ? value.key : value,
    });

    if (fieldName === 'contactType') {
      clearRecipientInfo();
    }

    if (fieldName === 'amount') {
      const amountRemaining = getTotalAmountRemaining(value);

      setTotalAmountRemaining(amountRemaining);
    }
  };

  const handleChangeDatePaid = (datePaid) => {
    setFormState({
      ...formState,
      datePaid,
    });
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    election,
    electionYear,
    formState.contactType,
    'Expenditure',
  );

  const onItemSelected = (contact) => {
    setSelectedContact(contact);
    setFormState(createContactState(contact, formState));
    return null;
  };

  const clearSelectedContact = () => {
    setSelectedContact(null);
    setFormState({
      ...formState,
      contactId: undefined,
      contactType: 0,
      businessName: '',
      businessType: 0,
      contactName: '',
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      employer: '',
      occupation: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      committeeAffiliation: 0,
    });
  };

  const closeDialog = () => {
    actions.closeBulkDialog();
    setFormState({ ...initialFormState });
    setErrors({ ...initialErrorState });
    setSelectedContact(null);
  };

  const removeBulkLines = () => {
    setFormState({
      ...initialFormState,
      endRecipientBulkList: [],
    });
    setErrors({ ...initialErrorState });
    setSelectedContact(null);
  };

  const addRecipient = () => {
    const errors = validate(formState, session.isFederal());
    setErrors({ ...errors });
    if (Object.keys(errors).length) {
      return;
    }

    if (recipient === null) {
      const isNew = !selectedContact
          || selectedContact._id === ''
          || selectedContact._id === null
          || selectedContact._id === undefined
        ? true
        : undefined;

      const payload = {
        _id: isNew ? uuid() : undefined,
        isNew,
        amount: formState.amount,
        purpose: formState.purpose,
        contactId: isNew ? undefined : selectedContact._id,
        datePaid: formatDateOnly(formState.datePaid),
        sortName: formState.lastName || formState.businessName || undefined,
        contactType: formState.contactType,
        disbursementCategory: formState.disbursementCategory || undefined,
        budgetCategoryId: formState.budgetCategoryId || undefined,
        salutation: formState.salutation || undefined,
        firstName: formState.firstName || undefined,
        middleName: formState.middleName || undefined,
        lastName: formState.lastName || undefined,
        suffix: formState.suffix || undefined,
        occupation: formState.occupation || undefined,
        employer: formState.employer || undefined,
        forceItemize: formState.forceItemize || false,
        address: {
          addressLine1: formState.addressLine1 || undefined,
          addressLine2: formState.addressLine2 || undefined,
          city: formState.city || undefined,
          state: formState.state || undefined,
          zipCode: formState.zipCode || undefined,
          county: formState.county || undefined,
        },
        businessName: formState.businessName || undefined,
        businessType: formState.businessType || undefined,
        contactName: formState.contactName || undefined,
        committeeAffiliation: formState.committeeAffiliation || undefined,
        memoText: session.isFederal() ? formState.memoText : undefined,
      };
      actions.addRecipient(payload);
    } else {
      const payload = {
        ...recipient,
        contactId: selectedContact?.contactId || selectedContact?._id,
        undefined,
        amount: formState.amount,
        datePaid: formatDateOnly(formState.datePaid),
        purpose: formState.purpose,
        sortName:
          selectedContact?.lastName
          || selectedContact?.businessName
          || formState.lastName
          || formState.businessName
          || undefined,
        forceItemize: formState.forceItemize || false,
        contactType: formState.contactType,
        disbursementCategory: formState.disbursementCategory || undefined,
        budgetCategoryId: formState.budgetCategoryId || undefined,
        salutation: formState.salutation || undefined,
        firstName: formState.firstName || undefined,
        middleName: formState.middleName || undefined,
        lastName: formState.lastName || undefined,
        suffix: formState.suffix || undefined,
        occupation: formState.occupation || undefined,
        employer: formState.employer || undefined,
        address: {
          addressLine1: formState.addressLine1 || undefined,
          addressLine2: formState.addressLine2 || undefined,
          city: formState.city || undefined,
          state: formState.state || undefined,
          zipCode: formState.zipCode || undefined,
          county: formState.county || undefined,
        },
        businessName: formState.businessName || undefined,
        businessType: formState.businessType || undefined,
        contactName: formState.contactName || undefined,
        committeeAffiliation: formState.committeeAffiliation || undefined,
        memoText: session.isFederal() ? formState.memoText : undefined,
      };
      actions.editRecipient(payload);
    }

    goToNextBulkRecipient();
  };

  // const textStatementChange = handleChange('textStatementText');
  const parsePastedBulkContent = (e, content) => {
    let data = [];
    if (formState.bulkFileType === 'ServisFirst') {
      data = parseServisFirst(content);
    } else {
      data = parseAmex(content);
    }
    handleChange('endRecipientBulkList')(null, data);
  };

  const bulkRecipientRowClick = (row) => {
    handleSetBulkFormData(
      Moment(row.expenditureDate).toDate(),
      row.amount,
      row.purpose,
      row.searchString,
      row.id,
    );
  };

  const handleRenderCaretDown = () => <CaretDown />;

  const handleBulkFileTypeSelection = (e, v) => {
    setFormState({
      ...initialFormState,
      bulkFileType: v.key,
    });
  };

  return (
    <Dialog
      minWidth={screenWidth < 910 ? '95%' : '50%'}
      maxMidth={screenWidth < 910 ? '95%' : 775}
      maxHeight="500px"
      hidden={dialogHidden}
      onDismiss={closeDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: recipient === null ? 'Add Bulk End Recipient' : 'Edit Bulk End Recipient',
      }}
      modalProps={{
        isBlocking: true,
        containerClassName: 'RecipientDialog',
      }}
    >
      <Grid>
        {budgetCategories.length > 0
        && (
        <GridRow>
          <Column lg={4}>
            <BudgetCategoryPicker
              label="Budget Category"
              selectedKey={formState.budgetCategoryId}
              onChange={handleChange('budgetCategoryId')}
              errorMessage={errors.budgetCategoryId}
              options={budgetCategories}
            />
          </Column>
        </GridRow>
        )}
        {formState.endRecipientBulkList.length === 0
          && (
          <GridRow>
            <Column md={2}>
              <Dropdown
                label="Select spreadsheet type"
                onRenderCaretDown={handleRenderCaretDown}
                selectedKey={formState.bulkFileType}
                options={[
                  { key: 'ServisFirst', text: 'ServiceFirst' },
                  { key: 'Amex', text: 'Amex' },
                ]}
                onChange={handleBulkFileTypeSelection}
              />
            </Column>

            <Column md={10}>
              <TextField
                label="Paste copied spreadsheet text"
                multiline
                rows={3}
                value={formState.textStatementText}
                onChange={parsePastedBulkContent}
              />
            </Column>
          </GridRow>
          )}
        {formState.endRecipientBulkList.length > 0
          && (
          <GridRow>
            <Column md={1}>
              <DefaultButton
                text="Clear"
                onClick={removeBulkLines}
                styles={{ root: { marginRight: 8 } }}
              />
            </Column>
            <Column md={11}>
              <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                {formState.endRecipientBulkList.map((er) => {
                  const address = `${er.address1} ${er.cityState} ${er.zip}`;
                  const amount = `${er.amount.toFixed(2)}`;
                  return (
                    <Stack style={{ backgroundColor: formState.bulkRowId === er.id ? '#DDDDDD' : '' }} onClick={() => bulkRecipientRowClick(er)} className="bulk-recipient-row" horizontal childrenGap={8}>
                      <div style={{ width: '86px' }}>{er.expenditureDate}</div>
                      <div style={{ width: '286px' }}>{er.searchString}</div>
                      <div style={{ width: '56px' }}>{amount}</div>
                      <div style={{ width: '500px' }}>{address}</div>
                      <div style={{ width: '186px' }}>{er.reference}</div>
                      <div>{er.purpose}</div>
                    </Stack>
                  );
                })}
              </div>
            </Column>
          </GridRow>
          )}
        <GridRow>
          <Column md={4}>
            <MoneyField
              label="Amount"
              value={formState.amount}
              errorMessage={errors.amountError}
              required
              compRef={amountRef}
              onChange={handleChange('amount')}
              description={`Remaining to match total amount of expenditure: ${totalAmountRemaining}`}
            />
          </Column>
          <Column md={4}>
            <DatePicker
              label="Date Paid"
              value={formState.datePaid}
              onChange={handleChangeDatePaid}
              required
              errorMessage={errors.datePaidError}
            />
          </Column>
          <Column md={4}>
            <TextField
              label="Purpose"
              value={formState.purpose}
              onChange={handleChange('purpose')}
            />
          </Column>
        </GridRow>
        {session.isFederal() && (
          <>
            <GridRow style={{ marginBottom: 16 }}>
              <Column md={6}>
                <DisbursementCategoryPicker
                  handleChange={handleChange('disbursementCategory')}
                  errorMessage={errors.disbursementCategoryError}
                  disbursementCategory={formState.disbursementCategory}
                />
              </Column>
              <Column md={6}>
                <TextField
                  label="MemoText"
                  value={formState.memoText}
                  onChange={handleChange('memoText')}
                />
              </Column>
            </GridRow>
            <GridRow style={{ marginBottom: 16 }}>
              <Column>
                <Checkbox
                  checked={formState.forceItemize}
                  label="Force Itemization"
                  onChange={handleChange('forceItemize')}
                  checkmarkIconProps={{
                    iconName: 'Check',
                  }}
                />
              </Column>
            </GridRow>
          </>
        )}
        <GridRow>
          <Column>
            <ContactPicker
              session={session}
              selectedContact={selectedContact}
              clearSelectedContact={clearSelectedContact}
              onResolveSuggestions={onResolveSuggestions}
              onRenderSuggestionsItem={onRenderSuggestionsItem}
              contactSearchString={formState.searchString}
              onItemSelected={onItemSelected}
              onChangeContactField={handleChange}
              resetForm={resetContactPicker}
              contactType={{
                label: 'Recipient Type',
                field: 'contactType',
                required: true,
                errorMessage: errors.recipientTypeError,
                options: sourceTypes,
                value: formState.contactType,
                show: true,
              }}
              businessName={{
                label: 'Business Name',
                field: 'businessName',
                required: true,
                errorMessage: errors.businessNameError,
                value: formState.businessName,
                show: true,
              }}
              businessType={{
                label: 'Business Type',
                field: 'businessType',
                required: false,
                show: true,
                value: formState.businessType,
                options: businessTypes,
              }}
              contactName={{
                label: 'Contact Name',
                field: 'contactName',
                required: false,
                show: true,
                value: formState.contactName,
              }}
              salutation={{
                label: 'Salutation',
                field: 'salutation',
                required: false,
                value: formState.salutation,
                show: true,
              }}
              firstName={{
                label: 'First Name',
                field: 'firstName',
                required: true,
                value: formState.firstName,
                errorMessage: errors.firstNameErrorMessage,
                show: true,
              }}
              middleName={{
                label: 'Middle Name',
                field: 'middleName',
                required: false,
                value: formState.middleName,
                show: true,
              }}
              lastName={{
                label: 'Last Name',
                field: 'lastName',
                required: true,
                value: formState.lastName,
                errorMessage: errors.lastNameErrorMessage,
                show: true,
              }}
              suffix={{
                label: 'Suffix',
                field: 'suffix',
                required: false,
                value: formState.suffix,
                show: true,
              }}
              employer={{
                label: 'Employer',
                field: 'employer',
                required: false,
                value: formState.employer,
                show: true,
              }}
              occupation={{
                label: 'Occupation',
                field: 'occupation',
                required: false,
                value: formState.occupation,
                show: true,
              }}
              address1={{
                label: getLabel('Address 1', session),
                field: 'addressLine1',
                value: formState.addressLine1,
                show: true,
              }}
              address2={{
                label: getLabel('Address 2', session),
                field: 'addressLine2',
                value: formState.addressLine2,
                show: true,
              }}
              city={{
                label: 'City',
                field: 'city',
                value: formState.city,
                show: true,
              }}
              state={{
                label: 'State',
                value: formState.state,
                field: 'state',
                required: false,
                show: true,
              }}
              zipCode={{
                label: 'Zip Code',
                field: 'zipCode',
                value: formState.zipCode,
                required: false,
                show: true,
              }}
              county={{
                label: 'County',
                field: 'county',
                value: formState.county,
                required: false,
                show: true,
              }}
              phone1={{
                label: 'Phone 1',
                field: 'phone1',
                value: formState.phone1,
                required: false,
                show: true,
              }}
              phone2={{
                label: 'Phone 2',
                field: 'phone2',
                value: formState.phone2,
                required: false,
                show: true,
              }}
              email={{
                label: 'Email',
                field: 'email',
                value: formState.email,
                required: false,
                show: true,
                errorMessage: errors.emailErrorMessage,
              }}
              committeeFecId={{
                label: 'FEC ID',
                field: 'committeeFecId',
                value: formState.committeeFecId,
                required: false,
                show: true,
              }}
              committeeAffiliation={{
                label: 'Committee Affiliation',
                field: 'committeeAffiliation',
                value: formState.committeeAffiliation,
                required: false,
                show: true,
              }}
            />
          </Column>
        </GridRow>
      </Grid>
      <DialogFooter>
        <DefaultButton
          text="Cancel"
          onClick={closeDialog}
          styles={{ root: { marginRight: 8 } }}
        />
        <PrimaryButton
          text={recipient === null ? 'Add' : 'Save'}
          onClick={() => addRecipient(false)}
          styles={{ root: { marginRight: 8 } }}
        />
      </DialogFooter>
    </Dialog>
  );
};

EndRecipientBulkLoadDialog.propTypes = {
  budgetCategories: PropTypes.array,
  dialogHidden: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  recipient: PropTypes.object,
  electionYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  election: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  session: PropTypes.object.isRequired,
  sourceTypes: PropTypes.array,
  recipientAmountTotal: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

EndRecipientBulkLoadDialog.defaultProps = {
  recipient: null,
  sourceTypes: [],
  budgetCategories: [],
};

export default EndRecipientBulkLoadDialog;
