import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { actions as budgetCategoryActions } from '../../actions/budgetCategoryActions';
import { actions as expenditureActions } from '../../actions/expenditureActions';
import { actions as messagingActions } from '../../actions/messagingActions';
import { actions as validationActions } from '../../actions/validationActions';
import ExpenditureForm from '../../components/ExpenditureForm';
import Loading from '../../components/Loading';
import {
  ConfirmContinueUpdateDialog,
  ConfirmDeleteFromReportDialog,
  EndRecipientDialog,
  EndRecipientBulkLoadDialog,
} from '../../components/Dialogs';
import { getFRCampaignType } from '../../helpers/campaignHelper';
import {
  scrollToTop,
  getServerSideErrorMessage,
  calculateEndRecipientTotals,
  itemDateBeforeLastFiling,
} from '../../helpers/util';
import {
  statuses,
  endRecipientTypes,
  FECSourceTypes,
} from '../../helpers/constants';
import {
  validate,
  createPayload,
} from '../../components/ExpenditureForm/ExpenditureFormValidations';
import { useContactSearch } from '../../hooks/useContactSearch';
import { getExpenditure, recipientUpdate } from '../../services/apiCalls';
import { getQueryParams } from '../../helpers/urlHelper';
import { serializeRecipient } from '../../helpers/endRecipientHelper';
import {
  getLabel,
  convertItemUpdateErrorsToMessageList,
  itemUpdateWarning,
} from '../../helpers/labelHelper';
import {
  editExpenditureReducer,
  initialState,
  actions,
} from './editExpenditureReducer';
import { getCampaign } from '../../selectors';

export const EditExpenditure = ({
  budgetCategory,
  budgetCategoryActions,
  expenditureActions,
  history,
  location,
  match,
  messagingActions,
  session,
  validationActions,
  validations,
}) => {
  const [state, dispatch] = useReducer(editExpenditureReducer, initialState);
  const campaign = useSelector(getCampaign);
  const { lastFiledReportEndDate } = campaign;

  const retrieveExpenditure = async () => {
    const { id } = match.params;
    const params = getQueryParams(location.search);
    if (id) {
      try {
        const { data: expenditure } = await getExpenditure(id);
        if (expenditure) {
          dispatch({
            type: actions.GET_EXPENDITURE_FOR_EDIT_SUCCESS,
            data: {
              expenditure,
              session,
              params,
              frCampaignType: getFRCampaignType(campaign),
            },
          });
        } else {
          dispatch({ type: actions.GET_EXPENDITURE_FOR_EDIT_FAILURE });
          messagingActions.setErrorToast('An error occurred, please try again');
        }
      } catch (e) {
        dispatch({ type: actions.GET_EXPENDITURE_FOR_EDIT_FAILURE });
        const error = getServerSideErrorMessage(e);
        messagingActions.setErrorToast(error);
      }
    }
  };

  useEffect(() => {
    if (budgetCategory.getBudgetCategoryStatus === statuses.SUCCESS) {
      dispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName: 'budgetCategories',
          value: budgetCategory.budgetCategoryOptions,
        },
      });
    }
  }, [budgetCategory.getBudgetCategoryStatus]);

  useEffect(() => {
    scrollToTop();
    dispatch({ type: actions.GET_EXPENDITURE_FOR_EDIT });
    retrieveExpenditure();
    budgetCategoryActions.setBudgetCategory();
    return () => {
      validationActions.clearCheckNumberValidations();
      expenditureActions.resetSave();
      dispatch({ type: 'RESET_STATE' });
    };
  }, []);

  const clearPayeeInfo = () => {
    dispatch({ type: actions.CLEAR_SELECTED_CONTACT });
  };

  const onItemSelected = contact => {
    dispatch({
      type: actions.ON_CONTACT_SELECTED,
      data: { selectedContact: contact },
    });
    if (state.checkNumber) {
      validationActions.validateExpenditureCheckNumber(
        contact._id,
        state.checkNumber,
      );
    }
    return null;
  };

  const resetContactFields = () => {
    dispatch({
      type: actions.RESET_SELECTED_CONTACT_INFO,
    });
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    state.election,
    state.electionYear,
    state.contactType,
    'Expenditure',
  );

  const clearSelectedContact = () => {
    dispatch({
      type: actions.CLEAR_RECIPIENT_INFO,
    });
  };

  const handleChangeDatePaid = datePaid => {
    dispatch({ type: actions.HANDLE_CHANGE_DATE_PAID, data: { datePaid } });
  };

  const isOtherElection = election => {
    const result =
      Boolean(election) &&
      session.isFederal() &&
      !['Primary', 'General', 'Convention', 'Runoff', 'Recount'].includes(
        election,
      );
    return result;
  };

  const handleFederalElectionChange = (e, value) => {
    dispatch({
      type: actions.HANDLE_FEDERAL_ELECTION_CHANGE,
      data: {
        election: value.key !== undefined ? value.key : value,
        otherElectionType:
          value.key && isOtherElection(value.key)
            ? state.otherElectionType
            : '',
      },
    });
  };

  const handleContributionFederalElectionChange = (e, value) => {
    dispatch({
      type: actions.HANDLE_CONTRIBUTION_FEDERAL_ELECTION_CHANGE,
      data: {
        contributionElection: value.key !== undefined ? value.key : value,
        contributionOtherElectionType:
          value.key && isOtherElection(value.key)
            ? state.otherElectionType
            : '',
      },
    });
  };

  const handleContributionRefundChange = (e, value) => {
    dispatch({
      type: actions.HANDLE_REFUND_CONTRIBUTION_CHANGE,
      data: {
        refundContributionId: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleLoanChange = (e, value) => {
    dispatch({
      type: actions.HANDLE_LOAN_CHANGE,
      data: {
        loanId: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleCoupledChange = (fieldName1, fieldName2) => (e, value) => {
    dispatch({
      type: actions.HANDLE_COUPLED_CHANGE,
      data: {
        fieldName1,
        fieldName2,
        value,
      },
    });
  };

  const handleChange = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: { fieldName, value: value.key !== undefined ? value.key : value },
    });
    if (fieldName === 'contactType') {
      clearPayeeInfo();
    }
  };

  const handleChangeDate = fieldName => date => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: date,
      },
    });
  };

  const validateCheckNumber = () => {
    const { checkNumber, selectedContact } = state;
    if (checkNumber && selectedContact?._id) {
      validationActions.validateExpenditureCheckNumber(
        selectedContact._id,
        checkNumber,
      );
    }
  };

  const cancel = () => {
    if (state.reportIdRedirect) {
      if (state.fec) {
        history.push(
          `/filer/editFECReport/${state.reportIdRedirect}?section=itemizedDisbursements`,
        );
      } else {
        history.push(
          `/filer/editReport/${state.reportIdRedirect}?section=expenditures`,
        );
      }
    } else if (state.redirect === 'ledger') {
      history.push('/filer/ledger');
    } else {
      history.push('/filer/expenditures');
    }
  };

  const cancelDelete = () => {
    dispatch({ type: actions.CLOSE_CONFIRM_DELETE_MODAL });
  };

  const cancelContinueUpdate = () => {
    dispatch({ type: actions.HIDE_CONTINUE_UPDATE });
  };

  const deleteExpenditure = id => {
    if (id) {
      if (state.fec) {
        expenditureActions.deleteFec(
          id,
          state.reportIdRedirect,
          state.redirect,
        );
      } else {
        expenditureActions.delete(id, state.reportIdRedirect, state.redirect);
      }
    }
  };

  const confirmDeleteExpenditure = () => {
    const { isItemFiled, reconciliationId, depositId } = state;

    if (isItemFiled || reconciliationId || depositId) {
      dispatch({ type: actions.OPEN_CONFIRM_DELETE_MODAL });
    } else {
      deleteExpenditure(match.params.id);
    }
  };

  const closeAddRecipientDialog = () => {
    dispatch({ type: actions.CLOSE_END_RECIPIENT_DIALOG });
  };

  const closeBulkAddRecipientDialog = () => {
    dispatch({ type: actions.CLOSE_BULK_END_RECIPIENT_DIALOG });
  };

  const selectRecipient = recipientId => {
    dispatch({ type: actions.SELECT_RECIPIENT, data: { recipientId } });
  };

  const addRecipient = async newRecipient => {
    const errors = validate(state, session);
    dispatch({ type: actions.FORM_ERRORS, data: { errors } });
    if (Object.values(errors).some(e => e.length > 0)) {
      messagingActions.setErrorToast(
        'There are form errors, please correct any errors to continue',
      );
    } else {
      const payload = createPayload(state, match);
      if (!payload.endRecipients) {
        payload.endRecipients = [];
      }
      payload.endRecipients = [
        ...payload.endRecipients,
        serializeRecipient({ ...newRecipient, isDirty: true }),
      ];
      const {
        data: { data: expenditure },
      } = await recipientUpdate(payload);

      dispatch({
        type: actions.RECIPIENT_ADDED,
        data: {
          expenditure,
        },
      });
    }
  };

  const editRecipient = updatedRecipient => {
    dispatch({
      type: actions.EDIT_RECIPIENT,
      data: {
        updatedRecipient,
      },
    });
  };

  const deleteRecipient = recipientId => {
    dispatch({
      type: actions.DELETE_RECIPIENT,
      data: {
        recipientId,
      },
    });
  };

  const save = addNew => {
    const errors = validate(state, session);
    dispatch({ type: actions.FORM_ERRORS, data: { errors } });

    const payload = createPayload(state, match);
    if (state.fec) {
      expenditureActions.updateFec(
        payload,
        addNew,
        state.reportIdRedirect,
        state.redirect,
        state.verifyReport,
      );
    } else {
      expenditureActions.update(
        payload,
        addNew,
        state.reportIdRedirect,
        state.redirect,
        state.verifyReport,
      );
    }
  };

  const onSaveClick = addNew => {
    const errors = validate(state, session);
    const { isItemFiled, reconciliationId, depositId } = state;
    const isBadItemDate = itemDateBeforeLastFiling(state.datePaid, lastFiledReportEndDate);

    dispatch({ type: actions.FORM_ERRORS, data: { errors } });
    if (Object.values(errors).some(e => e.length > 0)) {
      return messagingActions.setErrorToast(
        'There are form errors, please correct any errors to continue',
      );
    }

    const expenditureLabel = session.isFederal() ? 'disbursement' : 'expenditure';
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: expenditureLabel,
      isItemFiled,
      reconciliationId,
      depositId,
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return dispatch({
        type: actions.SHOW_CONTINUE_UPDATE,
        data: {
          addNew,
          continueEditMessageList,
        },
      });
    }

    save(addNew);
  };

  const onRecipientSave = async () => {
    const errors = validate(state, session);
    dispatch({ type: actions.FORM_ERRORS, data: { errors } });
    if (Object.values(errors).some(e => e.length > 0)) {
      messagingActions.setErrorToast(
        'There are form errors, please correct any errors to continue',
      );
    } else {
      const payload = createPayload(state, match);
      await recipientUpdate(payload);

      dispatch({
        type: actions.ON_RECIPIENT_SAVE,
      });
    }
  };

  const onRecipientSaveBulk = async () => {
    const errors = validate(state, session);
    dispatch({ type: actions.FORM_ERRORS, data: { errors } });
    if (Object.values(errors).some(e => e.length > 0)) {
      messagingActions.setErrorToast(
        'There are form errors, please correct any errors to continue',
      );
    } else {
      const payload = createPayload(state, match);
      await recipientUpdate(payload);

      dispatch({
        type: actions.ON_BULK_RECIPIENT_SAVE,
      });
    }
  };

  const onConduitSelected = selectedConduitContact => {
    dispatch({ type: actions.ON_CONDUIT_SELECTED, data: { selectedConduitContact } });
    return selectedConduitContact;
  };

  const onRemoveConduit = removeFn => {
    removeFn();
    dispatch({ type: actions.RESET_SELECTED_CONDUIT_INFO });
  };

  const onSupportOpposeSelected = selectedSupportOpposeContact => {
    dispatch({ type: actions.ON_SUPPORT_OPPOSE_SELECTED, data: { selectedSupportOpposeContact } });
    return selectedSupportOpposeContact;
  };

  const onRemoveSupportOppose = removeFn => {
    dispatch({ type: actions.RESET_SELECTED_SUPPORT_OPPOSE_INFO });
    removeFn();
  };

  const { status } = state;

  if (status === statuses.PROCESSING) {
    return <Loading />;
  }

  if (status === statuses.SUCCESS) {
    const formActions = {
      handleChange,
      handleChangeDate,
      handleCoupledChange,
      handleFederalElectionChange,
      handleContributionFederalElectionChange,
      handleChangeDisbursementCategory: handleChange,
      handleContributionRefundChange,
      handleLoanChange,
      editRecipient: selectRecipient,
      deleteRecipient,
      openAddRecipientDialog: onRecipientSave,
      openBulkAddRecipientDialog: onRecipientSaveBulk,
      onBlurCheckNumber: validateCheckNumber,
      onResolveSuggestions,
      onRenderSuggestionsItem,
      paymentInfoActions: {
        handlePaymentInfoChange: handleChange,
        handleChangeDate: handleChangeDatePaid,
      },
      contactActions: {
        clearSelectedContact,
        onItemSelected,
        resetContactFields,
      },
      supportOpposeActions: {
        onSupportOpposeSelected,
        onRemoveSupportOppose,
      },
      conduitActions: {
        onConduitSelected,
        onRemoveConduit,
      },
    };

    const totalRecipientAmount = calculateEndRecipientTotals(
      state.endRecipients,
    );

    const addRecipientActions = {
      closeDialog: closeAddRecipientDialog,
      closeBulkDialog: closeBulkAddRecipientDialog,
      addRecipient,
      editRecipient,
    };

    return (
      <Fragment>
        <div className="ExpenditureForm depth-1">
          <h3>{`Edit ${getLabel('Expenditure', session)}`}</h3>
          <ExpenditureForm
            {...state}
            selectedConduit={state.conduit}
            selectedSupportOppose={state.supportOppose}
            actions={formActions}
            totalRecipientAmount={totalRecipientAmount}
            validations={validations}
            session={session}
            messagingActions={messagingActions}
          />
          <div className="expenditure-actions-wrapper">
            <div className="actions">
              <DefaultButton
                text="Cancel"
                onClick={cancel}
                className="cancel-btn expenditure-btn"
              />
              <PrimaryButton
                text="Save"
                onClick={() => onSaveClick(false)}
                className="save-btn expenditure-btn"
              />
              {!state.verifyReport && (
                <Fragment>
                  <PrimaryButton
                    text="Save, Add New"
                    onClick={() => onSaveClick(true)}
                    className="save-add-new-btn expenditure-btn"
                    iconProps={{
                      iconName: 'Plus',
                    }}
                  />
                  <DefaultButton
                    style={{
                      backgroundColor: '#a80000',
                      color: '#fff',
                    }}
                    className="expenditure-btn"
                    text="Delete"
                    onClick={confirmDeleteExpenditure}
                  />
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <EndRecipientDialog
          election={state.election}
          electionYear={state.electionYear}
          recipient={state.selectedRecipient}
          totalAmount={state.amount}
          budgetCategories={state.budgetCategories}
          actions={addRecipientActions}
          dialogHidden={state.editRecipientDialogHidden}
          sourceTypes={session.isFederal() ? FECSourceTypes : endRecipientTypes}
          session={session}
          recipientAmountTotal={totalRecipientAmount}
        />
        <EndRecipientBulkLoadDialog
          election={state.election}
          electionYear={state.electionYear}
          recipient={state.selectedRecipient}
          totalAmount={state.amount}
          budgetCategories={state.budgetCategories}
          actions={addRecipientActions}
          dialogHidden={state.bulkRecipientDialogHidden}
          sourceTypes={session.isFederal() ? FECSourceTypes : endRecipientTypes}
          session={session}
          recipientAmountTotal={totalRecipientAmount}
        />
        <ConfirmDeleteFromReportDialog
          dialogHidden={state.confirmDeleteHidden}
          cancel={cancelDelete}
          confirm={() => deleteExpenditure(match.params.id)}
          itemType={getLabel('Expenditure', session)}
          message="Deleting an item associated to a report or deposited/reconciled can make accurate reporting and balancing difficult or impossible."
        />
        <ConfirmContinueUpdateDialog
          dialogHidden={state.confirmContinueUpdateHidden}
          cancel={cancelContinueUpdate}
          confirm={() => save(state.addNew)}
          messageList={state.continueEditMessageList}
          instruction={itemUpdateWarning}
        />
      </Fragment>
    );
  }

  return null;
};

EditExpenditure.propTypes = {
  budgetCategory: PropTypes.object.isRequired,
  budgetCategoryActions: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  expenditureActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  messagingActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  validationActions: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    budgetCategory: state.budgetCategory,
    campaign: state.currentCampaign.campaign,
    validations: state.validations,
    session: state.user.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    budgetCategoryActions: bindActionCreators(budgetCategoryActions, dispatch),
    messagingActions: bindActionCreators(messagingActions, dispatch),
    expenditureActions: bindActionCreators(expenditureActions, dispatch),
    validationActions: bindActionCreators(validationActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditExpenditure),
);
