import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Column, Grid, GridRow } from '../../components/common';
import { getFRCampaignType } from '../../helpers/campaignHelper';
import {
  CampaignUserRoles,
  frCampaignTypes,
} from '../../helpers/constants';
import { getLabel } from '../../helpers/labelHelper';
import { getCampaign } from '../../selectors';
import { isBetaUser } from '../../helpers/sessionHelper';

const DashboardActions = ({ user, history, session }) => {
  const campaign = useSelector(getCampaign);
  const frCampaignType = getFRCampaignType(campaign);
  const ReportLabelFor48Hr =
    frCampaignType === frCampaignTypes.FederalNonCandidate
      ? '24/48 Hour Notice Reports'
      : '48 Hour Notice Reports';

  return (
    <Grid className="dashboard-actions depth-1">
      <GridRow>
        <Column classNames="site-actions">
          <div className="sub-col">
            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                  CampaignUserRoles.DataEntry,
                  CampaignUserRoles.ContributionEntry,
                ].includes(session.role)
              }
              text={`Add Monetary ${getLabel('Contribution', user.session)}`}
              iconProps={{
                iconName: 'WavyBill',
                styles: {
                  root: {
                    color: 'var(--success-color)',
                  },
                },
              }}
              onClick={() =>
                history.push('/filer/addContribution?type=Monetary')
              }
            />
            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                  CampaignUserRoles.DataEntry,
                  CampaignUserRoles.ContributionEntry,
                ].includes(session.role)
              }
              onClick={() =>
                history.push('/filer/addContribution?type=In Kind')
              }
              text={`Add a In-Kind/Other ${getLabel(
                'Contribution',
                user.session,
              )}`}
              iconProps={{
                iconName: 'WavyBill',
                styles: {
                  root: {
                    color: 'var(--success-color)',
                  },
                },
              }}
            />

            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                  CampaignUserRoles.DataEntry,
                  CampaignUserRoles.ExpenditureEntry,
                ].includes(session.role)
              }
              onClick={() =>
                history.push('/filer/addExpenditure?type=Monetary')
              }
              text={`Add a Monetary ${getLabel('Expenditure', user.session)}`}
              iconProps={{
                iconName: 'WavyBill',
                styles: {
                  root: {
                    color: 'var(--error-icon-color)',
                  },
                },
              }}
            />
            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                  CampaignUserRoles.DataEntry,
                  CampaignUserRoles.ExpenditureEntry,
                ].includes(session.role)
              }
              onClick={() => history.push('/filer/addExpenditure?type=In Kind')}
              text={`Add a In-Kind/Other ${getLabel(
                'Expenditure',
                user.session,
              )}`}
              iconProps={{
                iconName: 'WavyBill',
                styles: {
                  root: {
                    color: 'var(--error-icon-color)',
                  },
                },
              }}
            />
            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                  CampaignUserRoles.DataEntry,
                ].includes(session.role)
              }
              onClick={() => history.push('/filer/addLoan')}
              text="Add Loan"
              iconProps={{
                iconName: 'WavyBill',
                styles: {
                  root: {
                    color: 'var(--warning-color)',
                  },
                },
              }}
            />
          </div>
          <div className="sub-col">
            <DefaultButton
              className="action-btn"
              onClick={() => history.push('/filer/contacts')}
              text="Contacts"
              iconProps={{
                iconName: 'UserFriends',
              }}
            />
            <DefaultButton
              className="action-btn"
              onClick={() => history.push('/filer/contactMerge')}
              text="Merge Contacts"
              iconProps={{
                iconName: 'UserFriends',
              }}
            />
            <DefaultButton
              className="action-btn"
              disabled={
                ![CampaignUserRoles.SysAdmin, CampaignUserRoles.Admin].includes(
                  session.role,
                )
              }
              onClick={() => history.push('/filer/listsIndex')}
              text="Create Ad Hoc Lists and Reports"
              iconProps={{
                iconName: 'List',
              }}
            />
            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                ].includes(session.role)
              }
              onClick={() => history.push('/filer/ledger')}
              text="Manage Bank Ledger"
              iconProps={{
                iconName: 'University',
              }}
            />
            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                ].includes(session.role)
              }
              onClick={() => history.push('/filer/deposits')}
              text="Manage Deposits"
              iconProps={{
                iconName: 'FileInvoiceDollar',
              }}
            />
            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                ].includes(session.role)
              }
              onClick={() => history.push('/filer/reconciliations')}
              text="Reconciliations"
              iconProps={{
                iconName: 'BalanceScale',
              }}
            />
          </div>
          <div className="sub-col">
            {user.session && (
              <Fragment>
                <DefaultButton
                  className="action-btn"
                  disabled={
                    ![
                      CampaignUserRoles.SysAdmin,
                      CampaignUserRoles.Admin,
                      CampaignUserRoles.Accounting,
                    ].includes(session.role)
                  }
                  onClick={() => history.push('/filer/reports')}
                  text="Manage Disclosure Reports"
                  iconProps={{
                    iconName: 'ListAlt',
                  }}
                />
                {session.isFederal() && (
                  <DefaultButton
                    className="action-btn"
                    disabled={
                      ![
                        CampaignUserRoles.SysAdmin,
                        CampaignUserRoles.Admin,
                        CampaignUserRoles.Accounting,
                      ].includes(session.role)
                    }
                    onClick={() => history.push('/filer/48hourReports')}
                    text={ReportLabelFor48Hr}
                    iconProps={{
                      iconName: 'File',
                    }}
                  />
                )}
                {!session.isFederal() && (
                  <DefaultButton
                    className="action-btn"
                    disabled={
                      ![
                        CampaignUserRoles.SysAdmin,
                        CampaignUserRoles.Admin,
                        CampaignUserRoles.Accounting,
                      ].includes(session.role)
                    }
                    onClick={() => history.push('/filer/tbdReports')}
                    text="Manage TBD Reports"
                    iconProps={{
                      iconName: 'File',
                    }}
                  />
                )}
              </Fragment>
            )}
            <DefaultButton
              className="action-btn"
              disabled={
                ![CampaignUserRoles.SysAdmin, CampaignUserRoles.Admin].includes(
                  session.role,
                )
              }
              onClick={() => history.push('/filer/editCampaign')}
              text="Manage Campaign Details"
              iconProps={{
                iconName: 'PencilAlt',
              }}
            />
            <DefaultButton
              className="action-btn"
              disabled={
                ![CampaignUserRoles.SysAdmin, CampaignUserRoles.Admin].includes(
                  session.role,
                )
              }
              onClick={() => history.push('/filer/manageUsers')}
              text="Manage Users"
              iconProps={{
                iconName: 'UserEdit',
              }}
            />
            <DefaultButton
              className="action-btn"
              disabled={
                ![CampaignUserRoles.SysAdmin, CampaignUserRoles.Admin].includes(
                  session.role,
                )
              }
              onClick={() => {
                history.push('/filer/editBillingInfo');
              }}
              text="Update Billing Information"
              iconProps={{
                iconName: 'CreditCard',
              }}
            />
          </div>
          <div className="sub-col">
            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                  CampaignUserRoles.DataEntry,
                  CampaignUserRoles.ContributionEntry,
                ].includes(session.role)
              }
              onClick={() => history.push('/filer/creditCardProcessing')}
              text="Import Credit Card Transactions"
              iconProps={{
                iconName: 'CheckSquare',
              }}
            />
            {session.isFederal() && (
              <DefaultButton
                className="action-btn"
                onClick={() => history.push('/filer/import/aristotle/earmarks')}
                text="Import Earmarked Contributions"
                iconProps={{
                  iconName: 'CheckSquare',
                }}
                disabled={!session.isBetaUser}
              />
            )}
            <DefaultButton
              className="action-btn"
              disabled={
                ![
                  CampaignUserRoles.SysAdmin,
                  CampaignUserRoles.Admin,
                  CampaignUserRoles.Accounting,
                ].includes(session.role)
              }
              onClick={() => history.push('/filer/audit/cashonhand')}
              text="Cash On Hand Compare to Reporting"
              iconProps={{
                iconName: 'CheckSquare',
              }}
            />
            { isBetaUser(session) && (
              <DefaultButton
                className="action-btn"
                onClick={() => history.push('/filer/apiIntegration')}
                text="FR API Integration (Beta)"
                iconProps={{
                  iconName: 'CheckSquare',
                }}
              />
            )}
          </div>
        </Column>
        {session.isBetaUser && (
          <Column classNames="site-actions">
            <div className="sub-col" style={{ marginTop: '-32px' }}>
              <DefaultButton
                className="action-btn"
                text="Global Contact Search (beta)"
                iconProps={{
                  iconName: 'ListAlt',
                  styles: {
                    root: {
                      color: 'var(--success-color)',
                    },
                  },
                }}
                onClick={() => window.open('/api/admin/contacts')}
              />
              <DefaultButton
                className="action-btn"
                text="Import Data (beta)"
                iconProps={{
                  iconName: 'FileImport (beta)',
                  styles: {
                    root: {
                      color: 'var(--primary-color)',
                    },
                  },
                }}
                onClick={() => history.push('/filer/dev/importList')}
              />
            </div>
            <div className="sub-col" />
            <div className="sub-col" />
            <div className="sub-col" />
          </Column>
        )}
        {session.isSysAdmin && (
          <Column classNames="site-actions">
            <div className="sub-col" style={{ marginTop: '-32px' }}>
              <DefaultButton
                className="action-btn"
                text="GA Civix API"
                iconProps={{
                  iconName: 'ListAlt',
                  styles: {
                    root: {
                      color: 'var(--primary-color)',
                    },
                  },
                }}
                onClick={() => history.push('/filer/dev/gaCivixAPI')}
              />
              <DefaultButton
                className="action-btn"
                text="Contribution Upload"
                iconProps={{
                  iconName: 'FileImport',
                  styles: {
                    root: {
                      color: 'var(--primary-color)',
                    },
                  },
                }}
                onClick={() => history.push('/filer/dev/contributionUpload')}
              />
              <DefaultButton
                className="action-btn"
                text="Contribution Apply"
                iconProps={{
                  iconName: 'FileImport',
                  styles: {
                    root: {
                      color: 'var(--success-color)',
                    },
                  },
                }}
                onClick={() => history.push('/filer/dev/contributionApply')}
              />
            </div>
            <div className="sub-col" />
            <div className="sub-col" />
            <div className="sub-col" />
          </Column>
        )}
      </GridRow>
    </Grid>
  );
};

DashboardActions.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

DashboardActions.defaultProps = {
  user: {},
};

function mapStateToProps(state) {
  return {
    session: state.user.session,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardActions);
