import React, { Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogFooter,
  DialogType,
} from 'office-ui-fabric-react/lib/Dialog';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { formatPhone } from '../../helpers/util';
import {
  Grid,
  GridRow,
  Column,
  PhoneNumberField,
  ZipCodeField,
} from '../../components/common';
import {
  StatePicker,
  SalutationPicker,
  SourceTypePicker,
} from '../../components/Pickers';
import {
  contributionSourceTypes as contactTypes,
  FECSourceTypes,
} from '../../helpers/constants';
import {
  initialState,
  actions,
  createContactReducer,
} from './createContactReducer';
import { validate } from './createContactValidations';

const CreateContactModal = ({
  isHidden,
  onDismiss,
  contributionInfo = {},
  onCreateContact,
  session,
  creatingNew,
  close,
  occupationAndEmployerSwapped,
}) => {
  const [state, dispatch] = useReducer(createContactReducer, initialState);

  useEffect(() => {
    if (contributionInfo !== null) {
      dispatch({
        type: actions.SET_CONTRIBUTION_INFO,
        data: {
          contributionInfo,
          session,
        },
      });
    }
  }, [contributionInfo, creatingNew]);

  const onClose = () => {
    dispatch({
      type: actions.CLEAR_STATE,
    });
    close();
  };

  const onCancel = () => {
    onDismiss();
  };

  const updateField = fieldName => (e, value) => {
    dispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const createContact = () => {
    const errors = validate(state);

    dispatch({
      type: actions.SET_FORM_ERRORS,
      data: {
        errors,
      },
    });

    if (Object.values(errors).every(e => e.length === 0)) {
      const {
        addressLine1 = '',
        addressLine2 = '',
        city = '',
        state: addressState = '',
        zipCode = '',
        county = '',
        emailAddress = '',
        firstName = '',
        middleName = '',
        lastName = '',
        salutation = '',
        businessName = '',
        type = '',
        employer = '',
        occupation = '',
        phone = '',
      } = { ...state };
      const contact = {
        address: {
          addressLine1,
          addressLine2,
          city,
          state: addressState,
          zipCode,
          county,
        },
        emailAddress,
        contactType: type,
        phone,
      };
      if (['IND', 'CAN', 'Individual'].includes(type)) {
        contact.firstName = firstName;
        contact.middleName = middleName;
        contact.lastName = lastName;
        contact.salutation = salutation;
        contact.occupation = occupation;
        contact.employer = employer;
        contact.email = emailAddress;
      } else {
        contact.businessName = businessName;
      }
      onCreateContact(contact);
      onClose();
    }
  };

  return (
    <Dialog
      hidden={isHidden}
      onDismiss={onCancel}
      dialogContentProps={{
        dialogType: DialogType.normal,
        title: 'Create New Contact',
      }}
      minWidth={'50%'}
    >
      {contributionInfo && (
        <Grid>
          <GridRow>
            <Column md={session.isFederal() ? 8 : 4}>
              <SourceTypePicker
                selectedKey={state.type}
                label="Contact Type"
                onChange={updateField('type')}
                errorMessage={state.typeError}
                required
                sourceTypes={[
                  ...(session.isFederal()
                    ? FECSourceTypes
                    : contactTypes.map(ct => ({
                        key: ct,
                        text: ct,
                      }))),
                ]}
              />
            </Column>
          </GridRow>
          {state.type !== 0 &&
            ['IND', 'Individual', 'CAN'].includes(state.type) && (
              <Fragment>
                <GridRow>
                  <Column md={3}>
                    <SalutationPicker
                      selectedKey={state.salutation}
                      onChange={updateField('salutation')}
                    />
                  </Column>
                </GridRow>
                <GridRow>
                  <Column md={5}>
                    <TextField
                      label="First Name"
                      value={state.firstName}
                      onChange={updateField('firstName')}
                      errorMessage={state.firstNameError}
                      required
                    />
                  </Column>
                  <Column md={2}>
                    <TextField
                      label="Middle Name"
                      value={state.middleName}
                      onChange={updateField('middleName')}
                    />
                  </Column>
                  <Column md={5}>
                    <TextField
                      label="Last Name"
                      value={state.lastName}
                      onChange={updateField('lastName')}
                      errorMessage={state.lastNameError}
                      required
                    />
                  </Column>
                </GridRow>
                <GridRow>
                  <Column md={6}>
                    <TextField
                      label="Occupation"
                      value={
                        occupationAndEmployerSwapped
                          ? state.employer
                          : state.occupation
                      }
                      onChange={updateField(
                        occupationAndEmployerSwapped
                          ? 'employer'
                          : 'occupation',
                      )}
                    />
                  </Column>
                  <Column md={6}>
                    <TextField
                      label="Employer"
                      value={
                        occupationAndEmployerSwapped
                          ? state.occupation
                          : state.employer
                      }
                      onChange={updateField(
                        occupationAndEmployerSwapped
                          ? 'occupation'
                          : 'employer',
                      )}
                    />
                  </Column>
                </GridRow>
              </Fragment>
            )}
          {state.type !== 0 &&
            !['IND', 'Individual', 'CAN'].includes(state.type) && (
              <GridRow>
                <Column md={4}>
                  <TextField
                    label="Business Name"
                    value={state.businessName}
                    onChange={updateField('businessName')}
                    errorMessage={state.businessNameError}
                    required
                  />
                </Column>
              </GridRow>
            )}
          <GridRow>
            <Column md={4}>
              <TextField
                label="Address Line 1"
                value={state.addressLine1}
                onChange={updateField('addressLine1')}
              />
            </Column>
            <Column md={4}>
              <TextField
                label="Address Line 2"
                value={state.addressLine2}
                onChange={updateField('addressLine2')}
              />
            </Column>
            <Column md={4}>
              <TextField
                label="City"
                value={state.city}
                onChange={updateField('city')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={3}>
              <StatePicker
                selectedKey={state.state}
                onChange={updateField('state')}
                session={session}
              />
            </Column>
            <Column md={2}>
              <ZipCodeField
                value={state.zipCode}
                onChage={updateField('zipCode')}
              />
            </Column>
            <Column md={7}>
              <TextField
                label="Email"
                value={state.emailAddress}
                onChange={updateField('emailAddress')}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column md={4}>
              <PhoneNumberField
                label="Phone 1"
                value={formatPhone(state.phone)}
                onChange={updateField('phone')}
              />
            </Column>
          </GridRow>
        </Grid>
      )}
      <DialogFooter>
        <DefaultButton text="Cancel" onClick={onCancel} />
        <PrimaryButton text="Create" onClick={createContact} />
      </DialogFooter>
    </Dialog>
  );
};

CreateContactModal.propTypes = {
  isHidden: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  contributionInfo: PropTypes.object,
  onCreateContact: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  creatingNew: PropTypes.bool,
  close: PropTypes.func,
  occupationAndEmployerSwapped: PropTypes.bool,
};

CreateContactModal.defaultProps = {
  isHidden: true,
  contributionInfo: {},
  creatingNew: false,
  occupationAndEmployerSwapped: false,
};

export default CreateContactModal;
