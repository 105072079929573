import { deserializeDate } from './util';

const getState = (address = {}) => {
  if (address.state) {
    return address.state !== 0 ? address.state : undefined;
  }
  return undefined;
};

const getBusinessType = item => {
  if (typeof item.businessType === 'string' && item.businessType.length > 0) {
    return item.businessType;
  }

  if (typeof item.businessType === 'object' && item.businessType !== 0) {
    return item.businessType;
  }

  return undefined;
};

export const serializeRecipient = er => {
  if (er.isNew) {
    delete er._id;
  }

  const endRecip = {
    ...er,
    address: {
      ...(er.address || {}),
      state: getState(er.address),
      county: er.address.county || undefined,
    },
    contactId: er.contactId,
    expenditureDate: er.datePaid,
    salutation:
      er.salutation && er.salutation !== 'none' ? er.salutation : undefined,
    businessType: getBusinessType(er),
    businessName: er.businessName || undefined,
    occupation: er.occupation || undefined,
    employer: er.employer || undefined,
    committeeAffiliation: er.committeeAffiliation || undefined,
  };
  return endRecip;
};

export const deserializeRecipient = er => {
  const endRecip = {
    ...er,
    businessName: er.businessName || '',
    businessType: er.businessType || 0,
    address: {
      ...(er.address || {}),
      state: (er.address && er.address.state) || 0,
    },
    employer: er.employer || '',
    occupation: er.occupation || '',
    datePaid: deserializeDate(er.expenditureDate),
    salutation: er.salutation || 'none',
    committeeAffiliation: er.committeeAffiliation || 0,
  };

  return endRecip;
};

export const createContactState = (contact, formState) => ({
  ...formState,
  committeeFecId: contact.committeeFecId || '',
  contactType: contact.contactType,
  purpose: formState.purpose || contact.purpose,
  contactId: contact.contactId || undefined,
  committeeAffiliation: contact.committeeAffiliation || 0,
  businessName: contact.businessName || '',
  businessType: contact.businessType || 0,
  contactName: contact.contactName || '',
  salutation: contact.salutation || 'none',
  firstName: contact.firstName || '',
  middleName: contact.middleName || '',
  lastName: contact.lastName || '',
  suffix: contact.suffix || '',
  occupation: contact.occupation || '',
  employer: contact.employer || '',
  addressLine1: contact.address ? contact.address.addressLine1 : '',
  addressLine2: contact.address ? contact.address.addressLine2 : '',
  city: contact.address ? contact.address.city : '',
  state: contact.address ? contact.address.state : '',
  zipCode: contact.address ? contact.address.zipCode : '',
  phone1: contact.phone1 || '',
  phone2: contact.phone2 || '',
  email: contact.email || '',
});
