import { getLabel } from '../../helpers/labelHelper';
import {
  cleansObject,
  zeroToUndefined,
  getCandidateNameEntry,
} from '../../helpers/payloadHelper';
import {
  isInvalidDate,
  isInvalidNumber,
  formatDateOnly,
  filterOutSystemTags,
} from '../../helpers/util';
import { errors as initialErrors } from '../../pages/Contributions/contributionBaseInitialState';
import {
  nonIndividualErrorTypeMap,
  FECSourceTypeCodes,
  emailRegex,
  frCampaignTypes,
} from '../../helpers/constants';

export const validate = (state, session) => {
  const errors = {
    ...initialErrors,
  };

  if (state.electionYear === 0 && (state.frCampaignType !== frCampaignTypes.FederalNonCandidate || state.contactType === FECSourceTypeCodes.PAC)) {
    errors.electionYearError = 'Election year is required';
  }

  if (state.election === 0 && (state.frCampaignType !== frCampaignTypes.FederalNonCandidate || state.contactType === FECSourceTypeCodes.PAC)) {
    errors.electionError = 'Election cycle is required';
  } else if (
    ['Other'].includes(state.election) &&
    state.otherElectionType.length === 0 &&
    session.isFederal()
  ) {
    errors.otherElectionTypeError = 'Receipt for other is required';
  }

  if (state.contributionType === 0) {
    errors.contributionTypeError = `${getLabel(
      'Contribution',
      session,
    )} type is required`;
  }

  if (state.selectedContact === null) {
    if (state.contactType === 0) {
      errors.selectedContactError =
        'Please choose a contact or create a new one';
    }
  }

  if (state.paymentType === 0 && state.contributionType !== 'In Kind') {
    errors.paymentTypeError = 'Payment type is required';
  }

  if (state.email && !emailRegex.test(state.email)) {
    errors.emailErrorMessage = 'Please use a valid email address';
  }

  if (state.amount && isInvalidNumber(state.amount)) {
    errors.amountErrorMessage =
      'Invalid value, include only digits and decimal point';
  }

  if (
    (state.contributionType !== 'In Kind' && !state.amount) ||
    state.amount === 0 ||
    state.amount === '0'
  ) {
    errors.amountErrorMessage = 'Amount is required';
  }

  if (!state.dateReceived) {
    errors.dateReceivedErrorMessage = 'Date received is required';
  } else if (isInvalidDate(state.dateReceived)) {
    errors.dateReceivedErrorMessage = 'Invalid date';
  }

  if (
    ['Individual', FECSourceTypeCodes.IND, FECSourceTypeCodes.CAN].includes(
      state.contactType,
    )
  ) {
    if (state.firstName.length === 0) {
      errors.firstNameErrorMessage = 'First name is required';
    }

    if (state.lastName.length === 0) {
      errors.lastNameErrorMessage = 'Last name is required';
    }
  } else if (
    Object.keys(nonIndividualErrorTypeMap).includes(state.contactType)
  ) {
    if (state.businessName.length === 0) {
      errors.businessNameError = `${
        nonIndividualErrorTypeMap[state.contactType]
      } is required`;
    }
  }

  if (session.isFederal() && state.includeConduitContact) {
    if (state.conduitContactType === 0) {
      errors.conduitContactType =
        'Please select a conduit contact or create one';
    }

    if (
      [FECSourceTypeCodes.IND, FECSourceTypeCodes.CAN].includes(
        state.conduitContactType,
      )
    ) {
      if (state.conduitFirstName.length === 0) {
        errors.conduitFirstName = 'Conduit first name is required';
      }

      if (state.conduitLastName.length === 0) {
        errors.conduitLastName = 'Conduit last name is required';
      }
    } else if (
      [
        FECSourceTypeCodes.ORG,
        FECSourceTypeCodes.CCM,
        FECSourceTypeCodes.COM,
        FECSourceTypeCodes.PTY,
        FECSourceTypeCodes.PAC,
      ].includes(state.conduitContactType)
    ) {
      if (state.conduitBusinessName.length === 0) {
        errors.conduitBusinessName = 'Conduit organization name is required';
      }
    }
  }

  return errors;
};

export const createPayload = (state, id) => {
  const isFederalNonCandidate = state.frCampaignType === frCampaignTypes.FederalNonCandidate;
  const payload = {
    _id: id,
    electionCycle: isFederalNonCandidate ? null : state.election,
    electionYear: isFederalNonCandidate ? null : state.electionYear,
    transactionIdNumber: state.transactionIdNumber,
    electionCycleOtherDescription: state.otherElectionType,
    contributionType:
      state.contributionType === 'In Kind' ? 'In-Kind' : state.contributionType,
    contributionCategory: zeroToUndefined(state.contributionCategory),
    contactType: state.contactType,
    paymentType: state.paymentType === 0 ? null : state.paymentType,
    budgetCategoryId: state.budgetCategoryId,
    // Individual itemized is only option with checkbox that controls 'isFrozenFecFormState'
    // frozenFecFormType sent to controller to define fecSourceType
    isFrozenFecFormType: state.contactType === 'ORG' ? state.isFrozenFecFormType : false,
    frozenFecFormType: state.contactType === 'ORG' && state.isFrozenFecFormType ? 'INDIVIDUAL' : null,
    checkNumber: state.checkNumber || undefined,
    creditCardType: state.cardType || undefined,
    creditCardLast4: state.cardLast4 || undefined,
    paymentReferenceId: state.referenceId || undefined,
    sourceCode: state.sourceCode || undefined,
    forceItemize: state.forceItemization,
    isNotReportable: state.isNotReportable,
    contactId: state.selectedContact?._id,
    commonSource: state.commonSource,
    specific: state.specific,
    tags: filterOutSystemTags(state.tags).filter(Boolean),
    cardholderName: state.cardholderName,
    committeeAffiliation: zeroToUndefined(state.committeeAffiliation),
    isLoanRepayment: state.isLoanRepayment,
    loanId: (state.isLoanRepayment || state.contributionType === 'Credit Received on Loan') ? state.loanId : undefined,
    salutation:
      state.salutation !== 'none' ? zeroToUndefined(state.salutation) : undefined,
    firstName: state.firstName,
    middleName: state.middleName,
    lastName: state.lastName,
    suffix: state.suffix,
    employer: state.employer,
    occupation: state.occupation,
    address: {
      addressLine1: state.addressLine1,
      addressLine2: state.addressLine2,
      city: state.city,
      state: zeroToUndefined(state.state),
      zipCode: state.zipCode,
      county: state.county,
    },
    email: state.email,
    phone1: state.phone1,
    phone2: state.phone2,
    receivedDate: formatDateOnly(state.dateReceived),
    amount: state.amount || 0,
    description: state.description,
    businessName: state.businessName,
    businessType: zeroToUndefined(state.businessType),
    contactName: state.contactName,
    reportId: state.reportIdRedirect,
    isCandidate: state.isCandidate,
    isTransfer: (state.contactType === 'PAC' || state.contactType === 'CCM') ? state.isTransfer : undefined,
    committeeFecId: state.committeeFecId,
    memoCode: state.memoCode ? 'X' : undefined,
    memoText: state.memoText,
    isRedesignation: state.isRedesignation,
    candidateOffice: state.candidateOffice,
    candidateState: zeroToUndefined(state.candidateState),
    candidateDistrict: state.candidateDistrict,
    candidateFirstName: getCandidateNameEntry(state.contactType, state.candidateFirstName, state.firstName),
    candidateMiddleName: getCandidateNameEntry(state.contactType, state.candidateMiddleName, state.middleName),
    candidateLastName: getCandidateNameEntry(state.contactType, state.candidateLastName, state.lastName),
    candidateFecId: state.candidateFecId,
    linkedTransactionId: state.linkedContribution?._id,
    presentationOnlyMemoContributionId: state.presentationOnlyMemoContribution?._id,
    mailCode: state.mailCode,
    envelopeCode: state.envelopeCode,
    externalDonorId: state.externalDonorId,
    conduit: state.includeConduitContact
      ? {
          contactId: state.conduitContactId,
          contactType: state.conduitContactType,
          salutation:
            state.conduitSalutation === 'none'
              ? undefined
              : state.conduitSalutation,
          firstName: state.conduitFirstName,
          middleName: state.conduitMiddleName,
          lastName: state.conduitLastName,
          suffix: state.conduitSuffix,
          businessName: state.conduitBusinessName,
          businessType: zeroToUndefined(state.conduitBusinessType),
          contactName: state.conduitContactName,
          committeeAffiliation: zeroToUndefined(state.conduitCommitteeAffiliation),
          email: state.conduitEmail,
          phone1: state.conduitPhone1,
          phone2: state.conduitPhone2,
          occupation: state.conduitOccupation,
          employer: state.conduitEmployer,
          address: {
            addressLine1: state.conduitAddressLine1,
            addressLine2: state.conduitAddressLine2,
            city: state.conduitCity,
            state: zeroToUndefined(state.conduitState),
            zipCode: state.conduitZipCode,
            county: state.conduitCounty,
          },
          committeeFecId: state.conduitFecId,
        }
      : undefined,
  };
  return cleansObject(payload);
};
