import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { types as expenditureActions } from '../../actions/expenditureActions';
import { types as searchValueActions } from '../../reducers/searchValueReducer';
import ObservableExpendituresList from '../../components/ObservableExpendituresList';
import { scrollToTop, itemDateBeforeLastFiling } from '../../helpers/util';
import { sortDirections } from '../../helpers/constants';
import {
  getLabel,
  convertItemUpdateErrorsToMessageList,
  itemUpdateWarning,
} from '../../helpers/labelHelper';
import { useIntersect } from '../../hooks/useIntersect';
import {
  getCampaign,
  getCheckForNoOccupationOrEmployer,
  getEndDate,
  getExpendituresList,
  getExpendituresListStatus,
  getNameSearchValue,
  getStartDate,
  getUserSession,
} from '../../selectors';
import { buildParams } from './viewAllHelper';

import { BackButton } from '../../components/common';
import './ViewAll.css';
import { ConfirmContinueUpdateDialog } from '../../components/Dialogs';

export const ViewAllExpenditures = ({ history }) => {
  const reduxDispatch = useDispatch();
  const expenditures = useSelector(getExpendituresList);
  const getExpendituresStatus = useSelector(getExpendituresListStatus);
  const session = useSelector(getUserSession);
  const endDate = useSelector(getEndDate);
  const startDate = useSelector(getStartDate);
  const filterText = useSelector(getNameSearchValue);
  const campaign = useSelector(getCampaign);
  const checkForNoEmployeeOrOccupation = useSelector(getCheckForNoOccupationOrEmployer);
  const { lastFiledReportEndDate } = campaign;

  const [state, setState] = useState({
    skip: 0,
    limit: 100,
    sortBy: '',
    sortDir: sortDirections.NONE,
    confirmContinueUpdateHidden: true,
    continueEditMessageList: [],
    editItem: {},
    startDate: null,
    endDate: null,
  });
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const [backToTopButtonRef, scrollContainer] = useIntersect({});

  useEffect(() => {
    scrollToTop();
    reduxDispatch({
      type: expenditureActions.GET_EXPENDITURES,
    });
  }, []);

  useEffect(() => {
    if (scrollContainer?.isIntersecting === true && showBackToTopButton) {
      setShowBackToTopButton(false);
    } else if (
      scrollContainer?.isIntersecting === false &&
      !showBackToTopButton &&
      expenditures?.length > 100
    ) {
      setShowBackToTopButton(true);
    }
  }, [scrollContainer, expenditures]);

  useEffect(() => {
    const params = buildParams(state, filterText, startDate, endDate, checkForNoEmployeeOrOccupation);

    reduxDispatch({
      type: expenditureActions.GET_EXPENDITURES,
      data: { params },
    });
  }, [filterText, startDate, endDate, checkForNoEmployeeOrOccupation, state.sortBy, state.sortDir]);

  useEffect(() => {
    if (state.skip > 0) {
      const params = buildParams(state, filterText, startDate, endDate, checkForNoEmployeeOrOccupation);

      reduxDispatch({
        type: expenditureActions.GET_EXPENDITURES_NEXT_PAGE,
        data: { params },
      });
    }
  }, [state.skip]);

  const nextPage = () => {
    setState(state => ({
      ...state,
      skip: state.skip + 1,
    }));
  };

  const handleChange = fieldName => (e, val) => {
    const value = val.key !== undefined ? val.key : val;
    setState({
      ...state,
      [fieldName]: value,
      skip: 0,
    });
  };

  const handleFilterChange = (e, entry) => {
    reduxDispatch({
      type: searchValueActions.SET_NAME_SEARCH,
      data: { nameSearchValue: entry },
    });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const setSort = ({ sortField, sortDirection }) => {
    setState({
      ...state,
      sortBy: sortField,
      sortDir: sortDirection,
      skip: 0,
    });
  };

  const edit = id => {
    history.push(`/filer/editExpenditure/${id}`);
  };

  const editClick = id => {
    const item = expenditures.find(e => e._id === id);
    const { isItemFiled, reconciliationId, depositId, expenditureDate } = (item || {});
    const isBadItemDate = itemDateBeforeLastFiling(expenditureDate, lastFiledReportEndDate);
    const expenditureLabel = session.isFederal() ? 'disbursement' : 'expenditure';
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: expenditureLabel,
      isItemFiled,
      reconciliationId,
      depositId,
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return setState(state => ({
        ...state,
        confirmContinueUpdateHidden: false,
        continueEditMessageList,
        editItemId: id,
      }));
    }

    edit(id);
  };

  const cancelContinueUpdate = () => {
    return setState(state => ({
      ...state,
      confirmContinueUpdateHidden: true,
      continueEditMessageList: [],
      editItemId: null,
    }));
  };

  const menuProps = useMemo(() => ({
    items: [
      {
        key: 'Monetary',
        text: `Monetary ${getLabel('Disbursement', session)}`,
        iconProps: {
          iconName: 'WavyBill',
          className: 'ms-fontColor-themePrimary',
        },
        onClick: () => history.push('/filer/addExpenditure?type=Monetary'),
      },
      {
        key: 'In Kind',
        text: `In Kind/Other ${getLabel('Disbursement', session)}`,
        iconProps: {
          iconName: 'WavyBill',
          className: 'ms-fontColor-themePrimary',
        },
        onClick: () => history.push('/filer/addExpenditure?type=In Kind'),
      },
    ],
  }));

  const changeDate = fieldName => value => {
    const payload = { fieldName, value };
    reduxDispatch({
      type: searchValueActions.HANDLE_CHANGE,
      data: payload,
    });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const clearDates = () => {
    reduxDispatch({ type: searchValueActions.CLEAR_DATES });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const setCheckForNoOccupationOrEmployer = () => {
    reduxDispatch({
      type: searchValueActions.SET_NO_EMPLOYEE_OR_OCCUPUPATION_VALIDATION,
      data: { checkForNoEmployeeOrOccupation: !checkForNoEmployeeOrOccupation },
    });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const actions = {
    handleFilterChange,
    handleChange,
    nextPage,
    setSort,
    changeDate,
    clearDates,
    setCheckForNoOccupationOrEmployer,
  };
  return (
    <>
      <BackButton
        history={history}
        pageTitle={getLabel('Expenditures', session)}
      />
      <div className="ViewAllExpenditures depth-1">
        <header className="expenditures-header" ref={backToTopButtonRef}>
          <PrimaryButton
            menuIconProps={{
              iconName: 'CaretDown',
            }}
            menuProps={menuProps}
            text="Add"
            iconProps={{
              iconName: 'PlusCircle',
            }}
          />
        </header>
        <div className="view-all-expenditures-content">
          <ObservableExpendituresList
            editItem={editClick}
            expenditures={expenditures}
            getExpendituresStatus={getExpendituresStatus}
            session={session}
            filterText={filterText}
            startDate={startDate}
            endDate={endDate}
            checkForNoOccupationOrEmployer={checkForNoEmployeeOrOccupation}
            actions={actions}
            sortField={state.sortBy || 'updatedAt'}
            sortDirection={state.sortDir || sortDirections.DESC}
          />
        </div>
        <ConfirmContinueUpdateDialog
          dialogHidden={state.confirmContinueUpdateHidden}
          cancel={cancelContinueUpdate}
          confirm={() => edit(state.editItemId)}
          messageList={state.continueEditMessageList}
          instruction={itemUpdateWarning}
        />
        {showBackToTopButton && (
          <div className="back-to-top">
            <IconButton
              iconProps={{ iconName: 'ArrowUp' }}
              onClick={scrollToTop}
              title="Back to Top"
            />
          </div>
        )}
      </div>
    </>
  );
};

ViewAllExpenditures.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ViewAllExpenditures);
