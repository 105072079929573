import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridRow, Column } from '../common';
import SourceTypePicker from './SourceTypePicker';
import { FECSourceTypes, FECSourceTypeCodes } from '../../helpers/constants';
import {
  BusinessEntity,
  CancelContactPickerActionButton,
  CandidateCommitteeEntity,
  CandidateEntity,
  CommitteeEntity,
  CreditCardEntity,
  EntityAddress,
  ExistingContactSelector,
  IndividualEntity,
  SelectedContactCard,
  UseNewContactButton,
} from './ContactPickerSections';
import './ContactPicker.scss';

const ContactPicker = ({
  contactPickerLabel,
  session,
  selectedContact,
  clearSelectedContact,
  onResolveSuggestions,
  onRenderSuggestionsItem,
  onItemSelected,
  contactType,
  contactSearchString,
  onChangeContactField,
  businessName,
  businessType,
  contactName,
  salutation,
  firstName,
  middleName,
  lastName,
  employer,
  occupation,
  suffix,
  address1,
  address2,
  city,
  state,
  zipCode,
  county,
  phone1,
  phone2,
  email,
  committeeAffiliation,
  committeeFecId,
  resetForm,
  resetContactFields,
  cardholderName,
  cardholderEmployer,
  cardholderOccupation,
  isSpecialCCExpenditure,
  cancelAddNew,
  onCreateNewContact,
  candidateOffice,
  candidateState,
  candidateDistrict,
  candidateFecId,
  candidateFirstName,
  candidateMiddleName,
  candidateLastName,
  disallowEdit,
  disallowCreate,
}) => {
  const [addNew, setAddNew] = useState(false);
  const [editSelectedContact, setEditSelectedContact] = useState(false);

  useEffect(() => {
    setAddNew(false);
    setEditSelectedContact(false);
  }, []);

  useEffect(() => {
    setAddNew(false);
    setEditSelectedContact(false);
  }, [resetForm]);

  const useNewContact = () => {
    setAddNew(true);
    clearSelectedContact();
    if (onCreateNewContact) {
      onCreateNewContact();
    }
  };

  const cancelEditSelectedContact = () => {
    setEditSelectedContact(false);
    resetContactFields();
  };

  const onCancelAddNew = () => {
    setAddNew(false);
    if (cancelAddNew) {
      cancelAddNew();
    }
  };

  return (
    <Grid className="ContactPicker">
      {selectedContact === null && !addNew && (
        <ExistingContactSelector
          onResolveSuggestions={onResolveSuggestions}
          onRenderSuggestionsItem={onRenderSuggestionsItem}
          onItemSelected={onItemSelected}
          cancelEditSelectedContact={cancelEditSelectedContact}
          label={contactPickerLabel}
          contactSearchString={contactSearchString}
        />
      )}
      {(!addNew && !selectedContact && !disallowCreate) && (
        <UseNewContactButton useNewContact={useNewContact} />
      )}
      {((addNew && !selectedContact) ||
        (selectedContact && editSelectedContact)) && (
          <Fragment>
            <CancelContactPickerActionButton
              text={
                addNew && !selectedContact
                  ? 'Cancel New Contact'
                  : 'Cancel Edit Contact'
              }
              onClick={
                addNew && !selectedContact
                  ? onCancelAddNew
                  : cancelEditSelectedContact
              }
            />
            <GridRow>
              <Column>
                <SourceTypePicker
                  label={contactType.label}
                  selectedKey={contactType.value}
                  onChange={onChangeContactField(contactType.field)}
                  required={contactType.required}
                  errorMessage={contactType.errorMessage}
                  sourceTypes={
                    contactType.options?.length
                      ? [...contactType.options]
                      : session.isFederal()
                        ? FECSourceTypes
                        : contactType.options
                  }
                  disabled={contactType.disabled}
                />
              </Column>
            </GridRow>
            {isSpecialCCExpenditure ? (
              <CreditCardEntity
                creditCardCompany={businessName}
                cardholderName={cardholderName}
                cardholderOccupation={cardholderOccupation}
                cardholderEmployer={cardholderEmployer}
                onChange={onChangeContactField}
              />
            ) : (
              <Fragment>
                {['Business', FECSourceTypeCodes.ORG].includes(
                    contactType.value,
                  ) && (
                  <BusinessEntity
                    businessName={businessName}
                    businessType={businessType}
                    contactName={contactName}
                    onChange={onChangeContactField}
                  />
                    )}
                {['Individual', FECSourceTypeCodes.IND].includes(
                    contactType.value,
                  ) && (
                  <IndividualEntity
                    salutation={salutation}
                    firstName={firstName}
                    middleName={middleName}
                    lastName={lastName}
                    employer={employer}
                    occupation={occupation}
                    suffix={suffix}
                    onChange={onChangeContactField}
                  />
                    )}
                {[FECSourceTypeCodes.CAN].includes(contactType.value) && (
                <CandidateEntity
                  candidateFecId={candidateFecId}
                  salutation={salutation}
                  firstName={firstName}
                  middleName={middleName}
                  lastName={lastName}
                  suffix={suffix}
                  employer={employer}
                  occupation={occupation}
                  onChange={onChangeContactField}
                  candidateOffice={candidateOffice}
                  candidateState={candidateState}
                  candidateDistrict={candidateDistrict}
                />
                  )}
                {[
                    'Committee',
                    FECSourceTypeCodes.PAC,
                    FECSourceTypeCodes.COM,
                  ].includes(contactType.value) && (
                  <CommitteeEntity
                    contactType={contactType.value}
                    committeeFecId={committeeFecId}
                    businessName={businessName}
                    committeeAffiliation={committeeAffiliation}
                    contactName={contactName}
                    onChange={onChangeContactField}
                  />
                    )}
                {[
                    'Candidate Committee',
                    FECSourceTypeCodes.CCM,
                    FECSourceTypeCodes.PTY,
                  ].includes(contactType.value) && (
                  <CandidateCommitteeEntity
                    committeeFecId={committeeFecId}
                    contactType={contactType.value}
                    contactName={contactName}
                    name={businessName}
                    onChange={onChangeContactField}
                    candidateOffice={candidateOffice}
                    candidateDistrict={candidateDistrict}
                    candidateState={candidateState}
                    candidateFecId={candidateFecId}
                    candidateFirstName={candidateFirstName}
                    candidateMiddleName={candidateMiddleName}
                    candidateLastName={candidateLastName}
                  />
                    )}
              </Fragment>
              )}
            <EntityAddress
              address1={address1}
              address2={address2}
              city={city}
              state={state}
              zipCode={zipCode}
              county={county}
              phone1={phone1}
              phone2={phone2}
              email={email}
              onChange={onChangeContactField}
            />
          </Fragment>
        )}
      {selectedContact && !addNew && !editSelectedContact && (
        <SelectedContactCard
          selectedContact={selectedContact}
          clearSelectedContact={clearSelectedContact}
          editSelectedContact={() => setEditSelectedContact(true)}
          disallowEdit={disallowEdit}
        />
      )}
    </Grid>
  );
};

ContactPicker.propTypes = {
  contactPickerLabel: PropTypes.string,
  session: PropTypes.object.isRequired,
  selectedContact: PropTypes.object,
  clearSelectedContact: PropTypes.func,
  onRenderSuggestionsItem: PropTypes.func.isRequired,
  onResolveSuggestions: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onChangeContactField: PropTypes.func.isRequired,
  resetForm: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  contactType: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object),
    ]),
    disabled: PropTypes.bool,
  }),
  businessName: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  businessType: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object),
    ]),
  }),
  contactName: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  salutation: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  firstName: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  middleName: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  lastName: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  employer: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  occupation: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  suffix: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  address1: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  address2: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  city: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  state: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  zipCode: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  county: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  phone1: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  phone2: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  email: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  committeeAffiliation: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  resetContactFields: PropTypes.func,
  cardholderName: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  cardholderEmployer: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  cardholderOccupation: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  committeeFecId: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    required: PropTypes.bool,
    show: PropTypes.bool,
  }),
  candidateOffice: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object),
    ]),
    disabled: PropTypes.bool,
  }),
  candidateState: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    show: PropTypes.bool,
  }),
  candidateDistrict: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  candidateFecId: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  candidateFirstName: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  candidateMiddleName: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  candidateLastName: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  isSpecialCCExpenditure: PropTypes.bool,
  cancelAddNew: PropTypes.func,
  onCreateNewContact: PropTypes.func,
  disallowEdit: PropTypes.bool,
  disallowCreate: PropTypes.bool,
};

ContactPicker.defaultProps = {
  selectedContact: null,
  clearSelectedContact: () => { },
  resetForm: false,
  disallowEdit: false,
  disallowCreate: false,
  committeeFecId: {
    field: 'committeeFecId',
    value: '',
    label: 'FEC ID',
    errorMessage: '',
    required: false,
    show: true,
  },
  contactType: {
    field: 'contactType',
    value: 0,
    label: 'Source Type',
    required: false,
    errorMessage: '',
    options: [],
  },
  businessName: {
    label: 'Business Name',
    value: '',
    field: 'businessName',
    required: false,
    errorMesssage: '',
    show: false,
  },
  businessType: {
    label: 'Business Type',
    value: '',
    field: 'businessType',
    required: false,
    errorMessage: '',
    show: false,
    options: [],
  },
  contactName: {
    label: 'Contact Name',
    value: '',
    field: 'contactName',
    required: false,
    errorMessage: '',
    show: false,
  },
  salutation: {
    label: 'Salutation',
    value: 'none',
    field: 'salutation',
    required: false,
    errorMessage: '',
    show: false,
  },
  firstName: {
    label: 'First Name',
    value: '',
    field: 'firstName',
    required: false,
    errorMessage: '',
    show: false,
  },
  middleName: {
    label: 'Middle Name',
    value: '',
    field: 'middleName',
    required: false,
    errorMessage: '',
    show: false,
  },
  lastName: {
    label: 'Last Name',
    value: '',
    field: 'lastName',
    required: false,
    errorMessage: '',
    show: false,
  },
  employer: {
    label: 'Employer',
    value: '',
    field: 'employer',
    required: false,
    errorMessage: '',
    show: false,
  },
  occupation: {
    label: 'Occupation',
    value: '',
    field: 'occupation',
    required: false,
    errorMessage: '',
    show: false,
  },
  suffix: {
    label: 'Suffix',
    value: '',
    field: 'suffix',
    required: false,
    errorMessage: '',
    show: false,
  },
  address1: {
    label: 'Address 1',
    value: '',
    field: 'addressLine1',
    required: false,
    errorMessage: '',
    show: false,
  },
  address2: {
    label: 'Address 2',
    value: '',
    field: 'addressLine2',
    required: false,
    errorMessage: '',
    show: false,
  },
  city: {
    label: 'City',
    value: '',
    field: 'city',
    required: false,
    errorMessage: '',
    show: false,
  },
  state: {
    label: 'State',
    value: '',
    field: 'state',
    required: false,
    errorMessage: '',
    show: false,
  },
  zipCode: {
    label: 'Zip Code',
    value: '',
    field: 'zipCode',
    required: false,
    errorMessage: '',
    show: false,
  },
  county: {
    label: 'County',
    value: '',
    field: 'county',
    required: false,
    errorMessage: '',
    show: false,
  },
  phone1: {
    label: 'Phone 1',
    value: '',
    field: 'phone1',
    required: false,
    errorMessage: '',
    show: false,
  },
  phone2: {
    label: 'Phone 2',
    value: '',
    field: 'phone2',
    required: false,
    errorMessage: '',
    show: false,
  },
  email: {
    label: 'Email',
    value: '',
    field: 'email',
    required: false,
    errorMessage: '',
    show: false,
  },
  committeeAffiliation: {
    label: 'Committee Affiliation',
    value: 0,
    field: 'committeeAffiliation',
    required: false,
    show: false,
    errorMessage: '',
  },
  resetContactFields: () => { },
  cardholderName: {
    label: 'Cardholder Name',
    field: 'cardholderName',
    value: '',
    required: false,
    show: false,
    errorMessage: '',
  },
  cardholderEmployer: {
    label: 'Cardholder Employer',
    field: 'cardholderEmployer',
    value: '',
    required: false,
    show: false,
    errorMessage: '',
  },
  cardholderOccupation: {
    label: 'Cardholder Occupation',
    field: 'cardholderOccupation',
    value: '',
    required: false,
    show: false,
    errorMessage: '',
  },
  candidateOffice: {
    field: 'candidateOffice',
    value: '',
    label: 'Candidate Office',
    required: false,
    errorMessage: '',
    options: [],
    show: false,
  },
  candidateState: {
    field: 'candidateState',
    value: 0,
    label: 'Candidate State',
    required: false,
    errorMessage: '',
    options: [],
    show: false,
  },
  candidateDistrict: {
    field: 'candidateDistrict',
    label: 'Candidate District',
    value: '',
    required: false,
    errorMessage: '',
    show: false,
  },
  candidateFecId: {
    field: 'candidateFecId',
    label: 'Candidate FEC ID',
    value: '',
    required: false,
    errorMessage: '',
    show: false,
  },
  candidateFirstName: {
    field: 'candidateFirstName',
    label: 'Candidate First Name',
    value: '',
    required: false,
    errorMessage: '',
    show: false,
  },
  candidateMiddleName: {
    field: 'candidateMiddleName',
    label: 'Candidate Middle Name',
    value: '',
    required: false,
    errorMessage: '',
    show: false,
  },
  candidateLastName: {
    field: 'candidateLastName',
    label: 'Candidate Last Name',
    value: '',
    required: false,
    errorMessage: '',
    show: false,
  },
  isSpecialCCExpenditure: false,
  cancelAddNew: null,
};

export default ContactPicker;
