import React, { Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  Checkbox,
  MessageBar,
  MessageBarType,
  DefaultButton,
  PrimaryButton,
  TextField,
} from 'office-ui-fabric-react';
import { Grid, GridRow, Column, MoneyField } from '../common';
import { getLabel } from '../../helpers/labelHelper';
import { ContactPicker } from '../Pickers';
import { useContactSearch } from '../../hooks/useContactSearch';
import {
  initialState,
  actions as reducerActions,
  ResponsiblePartyReducer,
} from '../LoanForm/ResponsiblePartyReducer';
import { validate } from '../LoanForm/responsiblePartyValidator';
import { nonFECIndividualSourceTypes, FECIndividualSourceTypes } from '../../helpers/constants';
import './AddResponsiblePartyDialog.css';

const AddPartyDialog = ({
  dialogHidden,
  session,
  election,
  electionYear,
  party,
  actions,
}) => {
  const [state, dispatch] = useReducer(ResponsiblePartyReducer, initialState);
  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    election,
    electionYear,
    'Individual',
    'Loan',
  );

  useEffect(() => {
    if (party !== null) {
      dispatch({
        type: reducerActions.SET_SELECTED_CONTACT,
        data: {
          party,
          committeeFecId: state.committeeFecId,
        },
      });
    }
  }, [party]);

  const onClose = () => {
    dispatch({ type: reducerActions.CLEAR_FORM });
    actions.closeDialog();
  };

  const clearSelectedContact = () => {
    dispatch({ type: reducerActions.CLEAR_CONTACT_INFO });
  };

  const resetContactFields = () => {
    dispatch({ type: reducerActions.RESET_CONTACT_INFO });
  };

  const onItemSelected = contact => {
    dispatch({
      type: reducerActions.SET_SELECTED_CONTACT,
      data: {
        party: contact,
      },
    });

    return null;
  };

  const handleContactChange = fieldName => (e, value) => {
    dispatch({
      type: reducerActions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const addParty = () => {
    const errors = validate(state, session);
    dispatch({ type: reducerActions.SET_FORM_ERRORS, data: { errors } });

    if (!Object.values(errors).some(f => f.length > 0)) {
      const { errors, ...rest } = state;
      const { selectedContact, ...partyDetails } = rest;

      if (party === null) {
        const payload = {
          ...partyDetails,
          committeeFecId: selectedContact?.committeeFecId,
          sortName: selectedContact?.lastName,
          responsiblePartyType: selectedContact?.contactType,
        };
        actions.addParty(payload);
      } else {
        const payload = {
          ...party,
          ...partyDetails,
          committeeFecId: selectedContact?.committeeFecId,
          sortName: selectedContact?.lastName,
          responsiblePartyType: selectedContact?.contactType,
        };
        actions.editParty(payload);
      }

      onClose();
      dispatch({ type: reducerActions.CLEAR_FORM });
      resetContactFields();
    }
  };

  const saveAndAddNewParty = () => {
    const errors = validate(state, session);
    dispatch({ type: reducerActions.SET_FORM_ERRORS, data: { errors } });
    if (Object.values(errors).some(f => f.length > 0)) {
      return;
    }

    const { errors: errs, ...rest } = state;
    const { selectedContact, ...partyDetails } = rest;

    if (party === null) {
      const payload = {
        ...partyDetails,
        sortName: state.lastName,
        responsiblePartyType: selectedContact.contactType,
      };
      actions.addParty(payload);
    } else {
      const payload = {
        ...party,
        ...partyDetails,
        sortName: state.lastName,
        responsiblePartyType: selectedContact.contactType,
      };
      actions.editParty(payload);
    }

    dispatch({ type: reducerActions.CLEAR_FORM });
    resetContactFields();
  };

  const businessNameLabel = () => {
    if (
      ['CCM', 'COM', 'PAC', 'Committee', 'Candidate Committee'].includes(
        state.responsiblePartyType,
      )
    ) {
      return 'Committee Name';
    }

    if (['PTY', 'ORG'].includes(state.responsiblePartyType)) {
      return 'Organization Name';
    }

    return 'Business Name';
  };

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.normal,
        title:
          party === null
            ? getLabel('Add Responsible Party', session)
            : getLabel('Edit Responsible Party', session),
        subText: '',
      }}
      modalProps={{
        isBlocking: true,
        containerClassName: 'AddResponsiblePartyDialog',
      }}
    >
      <div className="add-responsible-party">
        <Grid>
          <GridRow>
            <Column>
              {state.errors.selectedContactError && (
                <MessageBar messageBarType={MessageBarType.error}>
                  {state.errors.selectedContactError}
                </MessageBar>
              )}
              <ContactPicker
                session={session}
                selectedContact={state.selectedContact}
                clearSelectedContact={clearSelectedContact}
                onResolveSuggestions={onResolveSuggestions}
                onRenderSuggestionsItem={onRenderSuggestionsItem}
                onItemSelected={onItemSelected}
                disallowEdit={true}
                disallowCreate={true}
                onChangeContactField={handleContactChange}
                resetContactFields={resetContactFields}
                resetForm={state.resetForm}
                contactType={{
                  label: 'End Recipient Type',
                  field: 'responsiblePartyType',
                  required: true,
                  show: true,
                  value: state.responsiblePartyType,
                  options: session.isFederal()
                    ? FECIndividualSourceTypes
                    : nonFECIndividualSourceTypes,
                }}
                committeeFecId={{
                  label: 'FEC ID',
                  field: 'committeeFecId',
                  value: state.committeeFecId,
                  show: true,
                }}
                salutation={{
                  label: 'Salutation',
                  field: 'salutation',
                  value: state.salutation,
                  show: true,
                }}
                firstName={{
                  label: 'First Name',
                  field: 'firstName',
                  required: true,
                  show: true,
                  value: state.firstName,
                  errorMessage: state.errors.firstName,
                }}
                middleName={{
                  label: 'Middle Name',
                  field: 'middleName',
                  value: state.middleName,
                  show: true,
                }}
                lastName={{
                  label: 'Last Name',
                  field: 'lastName',
                  value: state.lastName,
                  required: true,
                  errorMessage: state.errors.lastName,
                  show: true,
                }}
                suffix={{
                  label: 'Suffix',
                  field: 'suffix',
                  value: state.suffix,
                  required: false,
                  show: true,
                }}
                occupation={{
                  label: 'Occupation',
                  field: 'occupation',
                  value: state.occupation,
                  show: true,
                }}
                employer={{
                  label: 'Employer',
                  field: 'employer',
                  value: state.employer,
                  show: true,
                }}
                address1={{
                  label: getLabel('Address Line 1', session),
                  field: 'addressLine1',
                  value: state.addressLine1,
                  show: true,
                }}
                address2={{
                  label: getLabel('Address Line 2', session),
                  field: 'addressLine2',
                  value: state.addressLine2,
                  show: true,
                }}
                city={{
                  label: 'City',
                  field: 'city',
                  value: state.city,
                  show: true,
                }}
                state={{
                  label: 'State',
                  field: 'state',
                  value: state.state,
                  show: true,
                }}
                zipCode={{
                  label: 'Zip Code',
                  field: 'zipCode',
                  value: state.zipCode,
                  show: true,
                }}
                county={{
                  label: 'County',
                  field: 'county',
                  value: state.county,
                  show: true,
                }}
                phone1={{
                  label: 'Phone 1',
                  field: 'phone1',
                  value: state.phone1,
                  show: true,
                }}
                phone2={{
                  label: 'Phone 2',
                  field: 'phone2',
                  value: state.phone2,
                  show: true,
                }}
                email={{
                  label: 'Email',
                  field: 'email',
                  value: state.email,
                  show: true,
                  errorMessage: state.errors.email,
                }}
                businessName={{
                  label: businessNameLabel(),
                  field: 'businessName',
                  value: state.businessName,
                  required: true,
                  show: true,
                  errorMessage: state.errors.businessName,
                }}
                businessType={{
                  label: 'Business Type',
                  field: 'businessType',
                  value: state.businessType,
                  show: true,
                }}
                contactName={{
                  label: 'Contact Name',
                  field: 'contactName',
                  value: state.contactName,
                  show: true,
                }}
                committeeAffiliation={{
                  label: 'Committee Type',
                  field: 'committeeType',
                  value: state.committeeAffiliation,
                  show: true,
                }}
                commonSource={{
                  label: 'Common Source',
                  field: 'commonSource',
                  value: state.commonSource,
                  show: true,
                }}
                specific={{
                  label: 'Specific',
                  field: 'specific',
                  value: state.specific,
                  show: true,
                }}
              />
            </Column>
          </GridRow>
          {!session.isFederal() && (
            <GridRow>
              <Column>
                <TextField
                  required
                  label="Fiduciary Relationship"
                  value={state.fiduciaryRelationship}
                  onChange={handleContactChange('fiduciaryRelationship')}
                  description="* If any such persons shall have a fiduciary relationship to the lending institution or party making the advance or extension of credit, the report shall specify such relationship."
                  errorMessage={state.errors.fiduciaryRelationship}
                />
              </Column>
            </GridRow>
          )}
          {session.isFederal() && (
            <Fragment>
              <GridRow>
                <Column>
                  <MoneyField
                    required
                    label="Amount Guaranteed Outstanding"
                    value={state.amountGuaranteedOutstanding}
                    errorMessage={state.errors.amountGuaranteedOutstanding}
                    onChange={handleContactChange(
                      'amountGuaranteedOutstanding',
                    )}
                  />
                </Column>
              </GridRow>
              <GridRow style={{ marginTop: 16 }}>
                <Column>
                  <Checkbox
                    checked={state.isCandidate}
                    label="Is Candidate"
                    checkmarkIconProps={{
                      iconName: 'Check',
                    }}
                    onChange={handleContactChange('isCandidate')}
                  />
                </Column>
              </GridRow>
            </Fragment>
          )}
        </Grid>
      </div>
      <div className="footer">
        <DefaultButton
          text="Cancel"
          onClick={onClose}
          style={{ marginRight: 16 }}
          className="cancel-add-responsible-party-btn"
        />
        <PrimaryButton
          text={party === null ? 'Add' : 'Save'}
          onClick={addParty}
          className="save-responsible-party-btn"
          style={{ marginRight: 16 }}
        />
        <PrimaryButton
          text="Save, Add New"
          onClick={saveAndAddNewParty}
          className="save-add-new-responsible-party-btn"
          iconProps={{
            iconName: 'Plus',
          }}
        />
      </div>
    </Dialog>
  );
};

AddPartyDialog.propTypes = {
  electionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  election: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  party: PropTypes.object,
  actions: PropTypes.object.isRequired,
  dialogHidden: PropTypes.bool,
  session: PropTypes.object.isRequired,
};

AddPartyDialog.defaultProps = {
  party: null,
  dialogHidden: true,
  election: '',
  electionYear: new Date().getFullYear(),
};

export default AddPartyDialog;
