import { getLabel } from '../../helpers/labelHelper';
import {
  cleansObject,
  zeroToUndefined,
  getCandidateNameEntry,
} from '../../helpers/payloadHelper';
import {
  filterOutSystemTags,
  formatDateOnly,
  isInvalidDate,
  isInvalidNumber,
} from '../../helpers/util';
import {
  emailRegex,
  expenditureRecipientTypes,
  FECSourceTypes,
  frCampaignTypes,
  GA_PAYMENT_CODE_OTHER,
  nonIndividualErrorTypeMap,
} from '../../helpers/constants';
import { errors as initialErrors } from '../../pages/Expenditures/expenditureBaseInitialState';
import { serializeRecipient } from '../../helpers/endRecipientHelper';

export const validate = (state, session) => {
  const errors = {
    ...initialErrors,
  };

  if (state.electionYear === 0 && state.frCampaignType !== frCampaignTypes.FederalNonCandidate) {
    errors.electionYearError = 'Election year is required';
  }

  if (state.election === 0 && state.frCampaignType !== frCampaignTypes.FederalNonCandidate) {
    errors.electionError = 'Election cycle is required';
  } else if (
    ['Other'].includes(state.election) &&
    state.otherElectionType.length === 0 &&
    session.isFederal()
  ) {
    errors.otherElectionTypeError = 'Disbursement for is required';
  }

  if (state.isAllocated) {
    // eslint-disable-next-line no-debugger
    if (state.expenditureType !== 'Federal Election Activity') {
      if (!state.activityOrEventIdentifier) {
        errors.activityOrEventIdentifierError = 'Activity/Event Identifier is required';
      }
      if (!state.allocatedActivityOrEventType) {
        errors.allocatedActivityOrEventTypeError = 'Activity/Event Type is required';
      }
    }
    if (state.federalShare === '' || isInvalidNumber(state.federalShare)) {
      errors.federalShareError = 'Enter amount in digits and decimal point';
    }
    if (state.nonFederalShare === '' || isInvalidNumber(state.nonFederalShare)) {
      errors.nonFederalShareError = 'Enter amount in digits and decimal point';
    }
  }

  if (state.expenditureType === 0) {
    errors.expenditureTypeError = `${getLabel(
      'Expenditure',
      session,
    )} type is required`;
  }

  if (session.isFederal() && state.disbursementCategory === '000') {
    errors.disbursementCategoryError = 'Disbursement category/type is required';
  }

  if (state.selectedContact === null) {
    if (state.contactType === 0) {
      errors.selectedContactError =
        'Please choose a contact or create a new contact';
    }

    if (
      ['Business', 'ORG'].includes(state.contactType) &&
      ['Credit Card'].includes(state.expenditureType)
    ) {
      if (state.businessName.length === 0) {
        errors.selectedContactError =
          'Please choose a contact or create a new contact';
      }
    }
  } else if (state.expenditureType === 'Credit Card' && state.businessName?.length === 0) {
    errors.selectedContactError = 'Credit card company is required';
  }

  if (state.paymentType === 0 && state.expenditureType !== 'In Kind') {
    errors.paymentTypeError = 'Payment type is required';
  }

  if (state.email && !emailRegex.test(state.email)) {
    errors.emailErrorMessage = 'Please use a valid email address';
  }

  if (!state.isAllocated && (state.amount && isInvalidNumber(state.amount))) {
    errors.amountErrorMessage =
      'Invalid number, include only digits and decimal point';
  }

  if (state.disbursementCategory === '010' && state.refundContributionId === '0') {
    errors.refundContributionErrorMessage =
      'Select the refunded contribution';
  }

  if (state.disbursementCategory === '009' && state.loanId === '0') {
    errors.loanErrorMessage =
      'Select the loan';
  }

  if (state.expenditureType === 'Refund' && state.refundContributionId === '0') {
    errors.refundContributionErrorMessage =
      'Select the refunded contribution';
  }

  if (!state.datePaid) {
    errors.datePaidErrorMessage = 'Date paid is required';
  } else if (isInvalidDate(state.datePaid)) {
    errors.datePaidErrorMessage = 'Invalid date';
  }

  if (state.expenditureType === 'Credit Card') {
    if (state.businessName.length === 0) {
      errors.creditCardCompanyError = 'Credit card company is required';
    }
  } else if (['Individual', 'IND', 'CAN'].includes(state.contactType)) {
    if (state.firstName.length === 0) {
      errors.firstNameErrorMessage = 'First name is required';
    }

    if (state.lastName.length === 0) {
      errors.lastNameErrorMessage = 'Last name is required';
    }
  } else if (
    Object.keys(nonIndividualErrorTypeMap).includes(state.contactType)
  ) {
    if (state.businessName.length === 0) {
      errors.businessNameError = `${
        nonIndividualErrorTypeMap[state.contactType]
      } is required`;
    }
  }
  return errors;
};

export const createPayload = (state, match = null) => {
  const isIndependentExpenditure = state.expenditureType === 'Independent Expenditure (Schedule E)';
  const payload = {
    _id: match?.params?.id,
    allocatedActivityOrEventType: state.allocatedActivityOrEventType,
    activityOrEventIdentifier: state.activityOrEventIdentifier,
    isAllocated: state.isAllocated,
    isRedesignation: state.isRedesignation,
    electionCycle: state.election,
    electionYear: state.electionYear,
    transactionIdNumber: state.transactionIdNumber,
    electionCycleOtherDescription: state.otherElectionType,
    expenditureType:
      state.expenditureType === 'In Kind' ? 'In-Kind' : state.expenditureType,
    supportOpposeCode: isIndependentExpenditure ? state.supportOpposeCode : undefined,
    disseminationDate: isIndependentExpenditure ? state.disseminationDate : undefined,
    completingFirstName: isIndependentExpenditure ? state.completingFirstName : undefined,
    completingLastName: isIndependentExpenditure ? state.completingLastName : undefined,
    completingDateSigned: isIndependentExpenditure ? state.completingDateSigned : undefined,
    disbursementCategory: zeroToUndefined(state.disbursementCategory),
    refundContributionId: state.refundContributionId === '0' ? undefined : state.refundContributionId,
    loanId: state.loanId === '0' ? undefined : state.loanId,
    contactType: state.contactType,
    paymentType: zeroToUndefined(state.paymentType),
    paymentCode: state.paymentCode,
    paymentCodeOther: state.paymentCode === GA_PAYMENT_CODE_OTHER ? state.paymentCodeOther : '',
    checkNumber: state.checkNumber,
    cardholderName: state.cardholderName,
    cardholderOccupation: state.cardholderOccupation,
    cardholderEmployer: state.cardholderEmployer,
    creditCardType: state.cardType,
    creditCardLast4: state.cardLast4,
    paymentReferenceId: state.referenceId,
    sourceCode: state.sourceCode,
    forceItemize: state.forceItemization,
    contactId: state.selectedContact?._id,
    purpose: state.purpose,
    expenditureDate: formatDateOnly(state.datePaid),
    tags: filterOutSystemTags(state.tags).filter(Boolean),
    committeeAffiliation: zeroToUndefined(state.committeeAffiliation),
    salutation:
      state.salutation !== 'none' ? zeroToUndefined(state.salutation) : undefined,
    firstName: state.firstName,
    middleName: state.middleName,
    lastName: state.lastName,
    suffix: state.suffix,
    employer: state.employer,
    occupation: state.occupation,
    committeeFecId: state.committeeFecId,
    address: {
      addressLine1: state.addressLine1,
      addressLine2: state.addressLine2,
      city: state.city,
      state: zeroToUndefined(state.state),
      zipCode: state.zipCode,
      county: state.county,
    },
    email: state.email,
    phone1: state.phone1,
    phone2: state.phone2,
    amount: state.amount || 0,
    federalShare: state.federalShare,
    nonFederalShare: state.nonFederalShare,
    budgetCategoryId: state.budgetCategoryId,
    isCandidate: state.isCandidate,
    businessName: state.businessName,
    businessType: zeroToUndefined(state.businessType),
    contactName: state.contactName,
    memoCode: state.memoCode ? 'X' : undefined,
    memoText: state.memoText,
    endRecipients:
      state.expenditureType === 'Credit Card' ||
      state.expenditureType === 'Reimbursement' ||
      state.expenditureType === 'In Kind'
        ? (state.endRecipients || [])
            .filter(er => !er.isRemoved)
            .map(serializeRecipient)
        : [],
    isContributionProcessingFee: state.isContributionProcessingFee,
    conduit: state.conduit,
    supportOppose: state.supportOppose,
    candidateOffice: state.candidateOffice,
    candidateState: zeroToUndefined(state.candidateState),
    candidateDistrict: state.candidateDistrict,
    candidateFirstName: getCandidateNameEntry(state.contactType, state.candidateFirstName),
    candidateMiddleName: getCandidateNameEntry(state.contactType, state.candidateMiddleName),
    candidateLastName: getCandidateNameEntry(state.contactType, state.candidateLastName),
    candidateFecId: state.candidateFecId,
    contributionOfficeSought: state.contributionOfficeSought,
    contributionOfficeState: state.contributionOfficeState,
    contributionDistrict: state.contributionDistrict,
    contributionElectionYear: state.contributionElectionYear,
    contributionElection: state.contributionElection,
    contributionOtherElectionType: state.contributionOtherElectionType,
  };

  if (state.isRecipientSave && state.recipientSaveExpenditureId) {
    payload._id = state.recipientSaveExpenditureId;
  }

  return cleansObject(payload);
};

export const getRecipientTypes = isFederal =>
  isFederal ? FECSourceTypes : expenditureRecipientTypes;
