import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import uuid from 'uuid/v4';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Toggle,
  Checkbox,
  MessageBar,
  MessageBarType,
  DefaultButton,
  PrimaryButton,
  Separator,
} from 'office-ui-fabric-react';
import { AddPartyDialog } from '../Dialogs';
import { Grid, GridRow, Column } from '../common';
import { getLabel, businessNameLabel } from '../../helpers/labelHelper';
import { toastTypes, FECSourceTypeCodes } from '../../helpers/constants';
import { useContactSearch } from '../../hooks/useContactSearch';
import {
  CashPayment,
  CheckPayment,
  CreditCardPayment,
  DebitCardPayment,
} from '../PaymentSections';
import ResponsibleParty from './ResponsibleParty';
import FederalIndividualLoan from './FederalIndividualLoan';
import FederalBusinessLoan from './FederalBusinessLoan';
import {
  YearPicker,
  ElectionCyclePicker,
  FECElectionCyclePicker,
  PaymentTypesPicker,
  ContactPicker,
} from '../Pickers';
import { Toast } from '../Toast';
import { filterOutSystemTags, formatDateOnly, scrollToTop } from '../../helpers/util';
import { validate, validateDepositoryAccount } from './loanFormValidations';
import { initialState, loanFormReducer, actions } from './loanFormReducer';
import {
  canEditTransactionIdNumber,
} from '../../helpers/campaignHelper';
import { isBetaUser } from '../../helpers/sessionHelper';

import './LoanForm.css';

export const LoanForm = ({
  onSave,
  onCancel,
  onDelete,
  campaign,
  session,
  electionYear,
  isReportable,
  messagingActions,
  validations,
  validationActions,
  verifyReport,
  loan,
  saveButtonDisabled,
  setSaveButtonDisabled,
  messages,
}) => {
  const [state, localDispatch] = useReducer(loanFormReducer, initialState);
  const reduxDispatch = useDispatch();

  const canEditTransactionId = canEditTransactionIdNumber(campaign) && isBetaUser(session);

  const isOtherElection = election => {
    const result =
      Boolean(election) &&
      session.isFederal() &&
      election === 'Other';
    return result;
  };

  useEffect(() => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'electionYear',
        value: electionYear,
      },
    });
  }, [electionYear]);

  useEffect(() => {
    if (loan !== null) {
      localDispatch({
        type: actions.GET_LOAN_SUCCESS,
        data: {
          loan,
          electionYear: loan.electionYear || campaign.nextElectionYear,
          isReportable,
        },
      });
    }
  }, [loan]);

  const clearFieldsForNew = () => {
    localDispatch({ type: actions.CLEAR_FIELDS_FOR_NEW });
  };

  const validateCheckNumber = () => {
    if (state.checkNumber && state.contactId) {
      reduxDispatch({
        type: validationActions.VALIDATE_CHECK_NUMBER,
        data: {
          contactId: state.contactId,
          checkNumber: state.checkNumber,
          type: 'loan',
        },
      });
    }
  };

  const clearSelectedContact = () => {
    localDispatch({ type: actions.CLEAR_SELECTED_CONTACT });
  };

  // form change handlers
  const handleChange = fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleChangeDate = fieldName => date => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: date,
      },
    });
  };

  const handleChangeAuthorizedRepText = fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_AUTHORIZED_REP,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const resetDepositoryAccount = originalData => {
    localDispatch({
      type: actions.RESET_DEPOSITORY_ACCOUNT,
      data: { originalData },
    });
  };

  const handleChangeAuthorizedRepDateSigned = date => {
    localDispatch({
      type: actions.HANDLE_CHANGE_AUTHORIZED_REP,
      data: {
        fieldName: 'dateSigned',
        value: date,
      },
    });
  };

  const clearSelectedDepositoryAccount = () => {
    localDispatch({ type: actions.CLEAR_DEPOSITORY_ACCOUNT });
  };

  const handleFederalBusinessLoanChange = fieldName => (e, value) => {
    if (
      fieldName === 'areFutureContributionsOrInterestIncomePledged' &&
      value === false
    ) {
      clearSelectedDepositoryAccount();
      localDispatch({
        type: actions.HANDLE_CHANGE_NO_FUTURE_CONTRIBUTIONS,
        data: {
          fieldName,
          value: value.key !== undefined ? value.key : value,
        },
      });
    } else {
      localDispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName,
          value: value.key !== undefined ? value.key : value,
        },
      });
    }
  };

  const handleDepositoryAccountChange = fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_DEPOSITORY_ACCOUNT,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleDepositoryAccountDateChange = date => {
    localDispatch({
      type: actions.HANDLE_CHANGE_DEPOSITORY_ACCOUNT,
      data: {
        fieldName: 'establishedDate',
        value: date,
      },
    });
  };

  const handleSelectExistingDepositoryAccount = account => {
    localDispatch({
      type: actions.SET_EXISTING_DEPOSITORY_ACCOUNT,
      data: { account },
    });
  };

  const handleSaveDepositoryAccount = async callback => {
    const errors = validateDepositoryAccount(state.depositoryAccount);

    localDispatch({
      type: actions.SET_ERRORS,
      data: {
        errors,
      },
    });

    if (!Object.values(errors).some(e => e.length > 0)) {
      const payload = {
        location: state.depositoryAccount.location,
        addressLine1: state.depositoryAccount.addressLine1,
        addressLine2: state.depositoryAccount.addressLine2,
        city: state.depositoryAccount.city,
        state: state.depositoryAccount.state,
        zipCode: state.depositoryAccount.zipCode,
      };

      if (state.depositoryAccount._id) {
        await axios.put(
          `/api/filer/depositoryAccounts/${state.depositoryAccount._id}`,
          payload,
          { withCredentials: true },
        );
      } else {
        const { data: _id } = await axios.post(
          '/api/filer/depositoryAccounts',
          payload,
          {
            withCredentials: true,
          },
        );

        localDispatch({
          type: actions.HANDLE_CHANGE_DEPOSITORY_ACCOUNT,
          data: {
            fieldName: '_id',
            value: _id,
          },
        });
      }

      if (callback) {
        callback();
      }
    }
  };

  // party dialog functions
  const openPartyDialog = () => {
    localDispatch({ type: actions.OPEN_PARTY_DIALOG });
  };

  const closePartyDialog = () => {
    localDispatch({ type: actions.CLOSE_PARTY_DIALOG });
  };

  const clearPartyAddNew = () => {
    localDispatch({ type: actions.CLEAR_PARTY_ADD_NEW });
  };

  const selectParty = id => {
    localDispatch({
      type: actions.SELECT_PARTY,
      data: {
        id,
      },
    });
  };

  const addParty = newParty => {
    localDispatch({
      type: actions.ADD_PARTY,
      data: {
        newParty,
        _id: newParty._id || uuid(),
      },
    });
  };

  const editParty = updatedParty => {
    localDispatch({
      type: actions.EDIT_PARTY,
      data: {
        updatedParty,
      },
    });
  };

  const deleteParty = id => {
    localDispatch({ type: actions.DELETE_PARTY, data: { id } });
  };

  // sub renderers
  const renderPaymentTypeForm = () => {
    const paymentInfoActions = {
      handlePaymentInfoChange: handleChange,
      handleChangeDate: handleChangeDate('loanDate'),
    };

    const paymentTypeMap = {
      Cash: () => (
        <CashPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountError}
          dateReceived={state.loanDate}
          dateReceivedErrorMessage={state.errors.loanDateError}
          referenceId={state.referenceId}
          dateLabel={`${getLabel('Loan Date', session)}`}
          showAmountRemaining={false}
        />
      ),
      Check: () => (
        <CheckPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountError}
          dateReceived={state.loanDate}
          dateReceivedErrorMessage={state.errors.loanDateError}
          checkNumber={state.checkNumber}
          description={state.description}
          referenceId={state.referenceId}
          dateLabel={`${getLabel('Loan Date', session)}`}
          showAmountRemaining={false}
          onBlurCheckNumber={validateCheckNumber}
          checkNumberError={validations.checkNumberError}
        />
      ),
      'Credit Card': () => (
        <CreditCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountError}
          dateReceived={state.loanDate}
          dateReceivedErrorMessage={state.errors.loanDateError}
          description={state.description}
          referenceId={state.referenceId}
          cardholderName={state.cardholderName}
          cardLast4={state.cardLast4}
          cardType={state.cardType}
          dateLabel={`${getLabel('Loan Date', session)}`}
          includeCardholderName
          showAmountRemaining={false}
        />
      ),
      'Debit Card': () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountError}
          dateReceived={state.loanDate}
          dateReceivedErrorMessage={state.errors.loanDateError}
          description={state.description}
          referenceId={state.referenceId}
          dateLabel={`${getLabel('Loan Date', session)}`}
          showAmountRemaining={false}
        />
      ),
      'Wire Transfer': () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountError}
          dateReceived={state.loanDate}
          dateReceivedErrorMessage={state.errors.loanDateError}
          description={state.description}
          referenceId={state.referenceId}
          dateLabel={`${getLabel('Loan Date', session)}`}
          showAmountRemaining={false}
        />
      ),
      ACH: () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountError}
          dateReceived={state.loanDate}
          dateReceivedErrorMessage={state.errors.loanDateError}
          description={state.description}
          referenceId={state.referenceId}
          dateLabel={`${getLabel('Loan Date', session)}`}
          showAmountRemaining={false}
        />
      ),
      Other: () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountError}
          dateReceived={state.loanDate}
          dateReceivedErrorMessage={state.errors.loanDateError}
          description={state.description}
          referenceId={state.referenceId}
          dateLabel={`${getLabel('Loan Date', session)}`}
          showAmountRemaining={false}
        />
      ),
    };

    if (paymentTypeMap[state.paymentType]) {
      return (
        <>
          <Separator />
          <div className="payment-type-section">
            {paymentTypeMap[state.paymentType]()}
          </div>
        </>
      );
    }

    return null;
  };

  const renderIndividualForm = () => {
    const federalIndividualLoanActions = {
      handleChange,
      handleDateChange: handleChangeDate,
    };
    return (
      <FederalIndividualLoan
        {...state.errors}
        {...state}
        actions={federalIndividualLoanActions}
      />
    );
  };

  const renderBusinessForm = () => {
    const federalBusinessLoanActions = {
      handleChange: handleFederalBusinessLoanChange,
      handleDateChange: handleChangeDate,
      handleDepositoryAccountChange,
      handleDepositoryAccountDateChange,
      handleSelectExistingDepositoryAccount,
      clearSelectedDepositoryAccount,
      handleSaveDepositoryAccount,
      handleChangeTreasurerSignedDate: handleChangeDate('treasurerDateSigned'),
      handleChangeAuthorizedRepText,
      handleChangeAuthorizedRepDateSigned,
      resetDepositoryAccount,
    };

    return (
      <FederalBusinessLoan
        {...state}
        campaign={campaign}
        actions={federalBusinessLoanActions}
        setSaveButtonDisabled={setSaveButtonDisabled}
      />
    );
  };

  const addPartyActions = {
    closeDialog: closePartyDialog,
    addParty,
    editParty,
    clearPartyAddNew,
  };

  const save = (addNew = false) => {
    const errors = validate({ session, state });

    localDispatch({ type: actions.SET_ERRORS, data: { errors } });

    if (Object.values(errors).some(c => c.length > 0)) {
      reduxDispatch({
        type: messagingActions.SET_TOAST,
        data: {
          toastType: toastTypes.ERROR,
          message: 'There are form errors, please correct any to continue',
        },
      });
    } else {
      const payload = {
        electionCycle: state.election,
        electionYear: state.electionYear,
        electionCycleOtherDescription: state.otherElectionType || undefined,
        contactType: state.contactType,
        businessName: state.businessName || undefined,
        businessType: state.businessType || undefined,
        contactName: state.contactName || undefined,
        salutation: state.salutation === 'none' ? undefined : state.salutation,
        firstName: state.firstName,
        middleName: state.middleName,
        lastName: state.lastName,
        suffix: state.suffix,
        occupation: state.occupation,
        employer: state.employer,
        address: {
          addressLine1: state.addressLine1 || undefined,
          addressLine2: state.addressLine2 || undefined,
          city: state.city || undefined,
          state: state.state || undefined,
          zipCode: state.zipCode || undefined,
        },
        email: state.email || undefined,
        phone1: state.phone1 || undefined,
        phone2: state.phone2 || undefined,
        paymentType: state.paymentType,
        checkNumber: state.checkNumber || undefined,
        creditCardType: state.creditCardType || undefined,
        creditCardLast4: state.cardLast4 || undefined,
        cardholderName: state.cardholderName || undefined,
        paymentReferenceId: state.referenceId,
        forceItemize: state.forceItemization,
        contactId: state.selectedContact?._id || undefined,
        description: state.description || undefined,
        tags: filterOutSystemTags(state.tags).filter(Boolean),
        transactionIdNumber: state.transactionIdNumber,
        responsibleParties: state.responsibleParties.reduce((acc, rp) => {
          if (!rp.removed) {
            const responsiblePartyObj = {
              contactId: rp.contactId,
              salutation: rp.salutation === 'none' ? undefined : rp.salutation,
              firstName: rp.firstName,
              middleName: rp.middleName || undefined,
              contactType: rp.responsiblePartyType || rp.contactType,
              lastName: rp.lastName,
              occupation: rp.occupation || undefined,
              employer: rp.employer || undefined,
              address: {
                addressLine1: rp.addressLine1 || undefined,
                addressLine2: rp.addressLine2 || undefined,
                city: rp.city || undefined,
                state: rp.state || undefined,
                zipCode: rp.zipCode || undefined,
                county: rp.county || undefined,
              },
              email: rp.email || undefined,
              phone1: rp.phone1 || undefined,
              phone2: rp.phone2 || undefined,
              isCandidate: rp.isCandidate,
              fiduciaryRelationship: rp.fiduciaryRelationship || undefined,
              transactionIdNumber: rp.transactionIdNumber,
              amountGuaranteedOutstanding: parseFloat(rp.amountGuaranteedOutstanding),
            };
            if (!rp.isNew) {
              responsiblePartyObj._id = rp._id;
            }
            acc = [...acc, responsiblePartyObj];
          }
          return acc;
        }, []),
        amount: state.amount,
        loanDate: formatDateOnly(state.loanDate),
      };

      if (session.isFederal()) {
        payload.interestRate = state.interestRate;
        payload.dueDate = formatDateOnly(state.dueDate);
        payload.outstandingBalance = state.outstandingBalance || undefined;

        switch (state.contactType) {
          case FECSourceTypeCodes.IND:
            payload.cumulativePayment = state.cumulativePaymentToDate;
            payload.isPersonalFundsOfCandidate = state.isPersonalFundsOfCandidate;
            payload.isSecured = state.isSecured;
            break;
          case FECSourceTypeCodes.CAN:
            payload.cumulativePayment = state.cumulativePaymentToDate;
            payload.isPersonalFundsOfCandidate = state.isPersonalFundsOfCandidate;
            payload.isSecured = state.isSecured;
            payload.candidateOffice = state.candidateOffice;
            payload.candidateState = state.candidateState;
            payload.candidateDistrict = state.candidateDistrict;
            payload.candidateFecId = state.candidateFecId;
            payload.candidateFirstName = state.candidateFirstName;
            payload.candidateMiddleName = state.candidateMiddleName;
            payload.candidateLastName = state.candidateLastName;
            break;
          case FECSourceTypeCodes.ORG:
            payload.isLoanRestructured = state.isLoanRestructured;
            payload.originalLoanDate = state.originalLoanDate
              ? formatDateOnly(state.originalLoanDate)
              : undefined;
            payload.isLineOfCredit = state.isLineOfCredit;
            payload.drawAmount = state.drawAmount || undefined;
            payload.otherPartiesSecondarilyLiable =
              state.otherPartiesSecondarilyLiable;
            payload.isCollateralPledged = state.isCollateralPledged;
            payload.collateralPledgedDescription =
              state.collateralPledgedDescription;
            payload.collateralPledgedEstimatedValue =
              state.collateralPledgedEstimatedValue || undefined;
            payload.lenderPerfectedSecurity = state.lenderPerfectedSecurity;
            payload.loanBasisDescription = state.loanBasisDescription;
            payload.areFutureContributionsOrInterestIncomePledged = state.areFutureContributionsOrInterestIncomePledged
              ? state.areFutureContributionsOrInterestIncomePledged
              : undefined;
            payload.futureContributionsOrInterestDescription = state.areFutureContributionsOrInterestIncomePledged
              ? state.futureContributionsOrInterestDescription
              : undefined;
            payload.futureContributionsOrInterestEstimatedValue = state.areFutureContributionsOrInterestIncomePledged
              ? state.futureContributionsOrInterestEstimatedValue
              : undefined;
            payload.depositoryAccount = state.areFutureContributionsOrInterestIncomePledged
              ? {
                _id: state.depositoryAccount._id || undefined,
                location: state.depositoryAccount.location,
                establishedDate: state.depositoryAccount.establishedDate
                  ? formatDateOnly(state.depositoryAccount.establishedDate)
                  : undefined,
                address: {
                  addressLine1: state.depositoryAccount.addressLine1,
                  addressLine2: state.depositoryAccount.addressLine2,
                  city: state.depositoryAccount.city,
                  state: state.depositoryAccount.state,
                  zipCode: state.depositoryAccount.zipCode,
                },
              }
              : undefined;
            payload.treasurerDateSigned = state.treasurerDateSigned
              ? formatDateOnly(state.treasurerDateSigned)
              : undefined;

            payload.authorizedRepresentative = {
              prefix:
                state.authorizedRepresentative.prefix === 'none'
                  ? undefined
                  : state.authorizedRepresentative.prefix,
              firstName: state.authorizedRepresentative.firstName || undefined,
              middleName: state.authorizedRepresentative.middleName || undefined,
              lastName: state.authorizedRepresentative.lastName || undefined,
              suffix: state.authorizedRepresentative.suffix || undefined,
              title: state.authorizedRepresentative.title || undefined,
              dateSigned: state.authorizedRepresentative.title
                ? formatDateOnly(state.authorizedRepresentative.dateSigned)
                : undefined,
            };
            break;
          default:
            return reduxDispatch({
              type: messagingActions.SET_TOAST,
              data: {
                toastType: toastTypes.ERROR,
                message: 'Unknown federal lender source type',
              },
            });
        }
      }

      const callbacks = () => {
        clearFieldsForNew();
        scrollToTop();
      };

      onSave(payload, addNew, callbacks);
    }
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    state.election,
    campaign.isNonCandidateCommittee
      ? new Date().getFullYear()
      : state.electionYear,
    state.contactType,
    'Loan',
  );

  const onItemSelected = contact => {
    localDispatch({ type: actions.ON_CONTACT_SELECTED, data: { contact } });
    if (state.checkNumber) {
      reduxDispatch({
        type: validationActions.VALIDATE_CHECK_NUMBER,
        data: {
          contactId: contact._id,
          checkNumber: state.checkNumber,
          type: 'loan',
        },
      });
    }
    return null;
  };

  const resetContactFields = () => {
    localDispatch({ type: actions.RESET_SELECTED_CONTACT_INFO });
  };

  return (
    <Fragment>
      <Grid>
        <GridRow>
          <Column lg={isOtherElection(state.election) ? 4 : 6}>
            <YearPicker
              label="Election Year"
              required
              value={state.electionYear}
              onChange={handleChange('electionYear')}
              errorMessage={state.errors.electionYearError}
            />
          </Column>
          <Column lg={isOtherElection(state.election) ? 4 : 6}>
            {session.isFederal() ? (
              <FECElectionCyclePicker
                label="Election"
                required
                value={state.election}
                onChange={handleChange('election')}
                placeholder="Select"
                errorMessage={state.errors.electionError}
              />
            ) : (
              <ElectionCyclePicker
                label="Election"
                required
                value={state.election}
                onChange={handleChange('election')}
                errorMessage={state.errors.electionError}
              />
              )}
          </Column>
          {isOtherElection(state.election) && (
            <Column lg={4}>
              <TextField
                label="Election For"
                value={state.otherElectionType}
                required
                errorMessage={state.errors.otherElectionTypeError}
                onChange={handleChange('otherElectionType')}
              />
            </Column>
          )}
        </GridRow>
        <Separator />
        <GridRow>
          <Column>
            <PaymentTypesPicker
              required
              label="Loan Type"
              selectedKey={state.paymentType}
              errorMessage={state.errors.paymentTypeError}
              onChange={handleChange('paymentType')}
            />
          </Column>
        </GridRow>
        <Separator />
        <GridRow>
          <Column>
            {state.errors.selectedContactError && (
              <MessageBar messageBarType={MessageBarType.error}>
                {state.errors.selectedContactError}
              </MessageBar>
            )}
            <ContactPicker
              session={session}
              selectedContact={state.selectedContact}
              clearSelectedContact={clearSelectedContact}
              cancelAddNew={clearSelectedContact}
              onResolveSuggestions={onResolveSuggestions}
              onRenderSuggestionsItem={onRenderSuggestionsItem}
              onItemSelected={onItemSelected}
              onChangeContactField={handleChange}
              resetContactFields={resetContactFields}
              contactType={{
                label: 'LenderType',
                field: 'contactType',
                required: true,
                show: true,
                errorMessage: state.errors.lenderTypeError,
                value: state.contactType,
                disabled: false,
                options: session.isFederal()
                  ? [
                    {
                      key: FECSourceTypeCodes.IND,
                      text: 'Individual (a person)',
                    },
                    {
                      key: FECSourceTypeCodes.ORG,
                      text: 'Organization (not a committee and not a person)',
                    },
                    {
                      key: FECSourceTypeCodes.CAN,
                      text: 'Candidate',
                    },
                  ]
                  : ['Individual', 'Business'],
              }}
              committeeFecId={{
                label: 'FEC ID',
                field: 'committeeFecId',
                value: state.committeeFecId,
                show: true,
              }}
              salutation={{
                label: 'Salutation',
                field: 'salutation',
                value: state.salutation,
                required: false,
                show: true,
              }}
              firstName={{
                label: 'First Name',
                field: 'firstName',
                value: state.firstName,
                required: true,
                errorMessage: state.errors.firstNameError,
                show: true,
              }}
              middleName={{
                label: 'Middle Name',
                field: 'middleName',
                value: state.middleName,
                required: false,
                show: true,
              }}
              lastName={{
                label: 'Last Name',
                field: 'lastName',
                value: state.lastName,
                required: true,
                errorMessage: state.errors.lastNameError,
                show: true,
              }}
              suffix={{
                label: 'Suffix',
                field: 'suffix',
                value: state.suffix,
                required: false,
                show: true,
              }}
              employer={{
                label: 'Employer',
                field: 'employer',
                value: state.employer,
                required: false,
                show: true,
              }}
              occupation={{
                label: 'Occupation',
                field: 'occupation',
                value: state.occupation,
                required: false,
                show: true,
              }}
              address1={{
                label: getLabel('Address Line 1', session),
                field: 'addressLine1',
                value: state.addressLine1,
                required: false,
                show: true,
              }}
              address2={{
                label: getLabel('Address Line 2', session),
                field: 'addressLine2',
                value: state.addressLine2,
                required: false,
                show: true,
              }}
              city={{
                label: 'City',
                field: 'city',
                value: state.city,
                required: false,
                show: true,
              }}
              state={{
                label: 'State',
                field: 'state',
                value: state.state,
                required: false,
                show: true,
              }}
              zipCode={{
                label: 'Zip Code',
                field: 'zipCode',
                value: state.zipCode,
                required: false,
                show: true,
              }}
              county={{
                label: 'County',
                field: 'county',
                value: state.county,
                required: false,
                show: true,
              }}
              phone1={{
                label: 'Phone 1',
                field: 'phone1',
                value: state.phone1,
                show: true,
              }}
              phone2={{
                label: 'Phone 2',
                field: 'phone2',
                value: state.phone2,
                show: true,
              }}
              email={{
                label: 'Email Address',
                field: 'email',
                value: state.email,
                show: true,
                errorMessage: state.errors.emailError,
              }}
              candidateOffice={{
                label: 'Candidate Office',
                field: 'candidateOffice',
                value: state.candidateOffice,
                show: true,
              }}
              candidateState={{
                label: 'Candidate State',
                field: 'candidateState',
                value: state.candidateState,
                show: true,
              }}
              candidateDistrict={{
                label: 'Candidate District',
                field: 'candidateDistrict',
                value: state.candidateDistrict,
                show: true,
              }}
              candidateFecId={{
                label: 'Candidate FEC ID',
                field: 'candidateFecId',
                value: state.candidateFecId,
                show: true,
              }}
              candidateFirstName={{
                label: 'Candidate First Name',
                field: 'candidateFirstName',
                value: state.candidateFirstName,
                show: true,
              }}
              candidateMiddleName={{
                label: 'Candidate Middle Name',
                field: 'candidateMiddleName',
                value: state.candidateMiddleName,
                show: true,
              }}
              candidateLastName={{
                label: 'Candidate Last Name',
                field: 'candidateLastName',
                value: state.candidateLastName,
                show: true,
              }}
              businessName={{
                label: businessNameLabel(state.contactType),
                field: 'businessName',
                value: state.businessName,
                show: true,
                required: true,
                errorMessage: state.errors.businessNameError,
              }}
              businessType={{
                label: session.isFederal()
                  ? 'Organization Type'
                  : 'Business Type',
                field: 'businessType',
                value: state.businessType,
                required: false,
                show: true,
              }}
              contactName={{
                label: 'Contact Name',
                field: 'contactName',
                value: state.contactName,
                required: false,
                show: true,
              }}
              committeeAffiliation={{
                label: 'Committee Type',
                field: 'committeeAffiliation',
                value: state.committeeAffiliation,
                show: true,
              }}
              commonSource={{
                label: 'Common Source',
                field: 'commonSource',
                value: state.commonSource,
                show: true,
              }}
              specific={{
                label: 'Specific',
                field: 'specific',
                value: state.specific,
                show: true,
              }}
            />
          </Column>
        </GridRow>
        {renderPaymentTypeForm()}
        {session.isFederal() &&
          [
            FECSourceTypeCodes.IND,
            'Individual',
            FECSourceTypeCodes.CAN,
          ].includes(state.contactType) &&
          renderIndividualForm()}
        {session.isFederal() &&
          [FECSourceTypeCodes.ORG, 'Business'].includes(state.contactType) &&
          renderBusinessForm()}
        {campaign.isNonCandidateCommittee && false && (
          <GridRow>
            <Column>
              <Toggle
                label="Reportable"
                onText="Yes"
                offText="No"
                checked={state.isReportable}
                onChange={handleChange('isReportable')}
              />
            </Column>
          </GridRow>
        )}
        <Separator />
        <GridRow>
          <Column lg={3}>
            <TextField
              value={state.tags}
              onChange={handleChange('tags')}
              label="Tags"
            />
          </Column>
          <Column lg={3}>
            <TextField
              value={state.transactionIdNumber}
              label="Transaction Id Number"
              readOnly={!canEditTransactionId}
              onChange={canEditTransactionId ? handleChange('transactionIdNumber') : null}
            />
          </Column>
        </GridRow>
        <GridRow>
          <Column>
            <Checkbox
              checked={state.forceItemization}
              label="Force Itemization"
              onChange={handleChange('forceItemization')}
              checkmarkIconProps={{
                iconName: 'Check',
              }}
            />
          </Column>
        </GridRow>
        {state.errors.isPersonalFundsOfCandidateError && (
          <GridRow>
            <Column>
              <MessageBar messageBarType={MessageBarType.error}>
                {state.errors.isPersonalFundsOfCandidateError}
              </MessageBar>
            </Column>
          </GridRow>
        )}
        {state.errors.otherPartiesNotListedError && (
          <GridRow>
            <Column>
              <MessageBar messageBarType={MessageBarType.error}>
                {state.errors.otherPartiesNotListedError}
              </MessageBar>
            </Column>
          </GridRow>
        )}
        <GridRow>
          <Column classNames="add-responsible-party" sm={12} lg={4}>
            <DefaultButton
              text={`${getLabel('Add Responsible Party', session)}`}
              iconProps={{
                iconName: 'Plus',
              }}
              onClick={openPartyDialog}
            />
          </Column>
          {state.responsibleParties.length > 0 &&
            state.responsibleParties.filter(rp => !rp.removed).length > 0 && (
              <Column sm={12} lg={8}>
                <h3>{getLabel('Responsible Parties', session)}</h3>
                <div>
                  {state.responsibleParties
                    .filter(rp => !rp.removed)
                    .map((rp, index) => (
                      <ResponsibleParty
                        party={rp}
                        key={rp._id || index}
                        editParty={selectParty}
                        deleteParty={deleteParty}
                      />
                    ))}
                </div>
              </Column>
            )}
        </GridRow>
      </Grid>
      <div className="loan-actions-wrapper">
        <div className="actions">
          <DefaultButton
            text="Cancel"
            onClick={onCancel}
            className="loan-btn"
          />
          <PrimaryButton
            text="Save"
            onClick={() => save(false)}
            className="loan-btn"
            disabled={saveButtonDisabled}
          />
          {!verifyReport && (
            <Fragment>
              <PrimaryButton
                text="Save, Add New"
                onClick={() => save(true)}
                className="loan-btn"
                iconProps={{
                  iconName: 'Plus',
                }}
                disabled={saveButtonDisabled}
              />
              {onDelete && (
                <DefaultButton
                  style={{
                    backgroundColor: '#a80000',
                    color: '#fff',
                  }}
                  text="Delete"
                  className="loan-btn"
                  onClick={onDelete}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
      <AddPartyDialog
        electionYear={state.electionYear}
        election={state.election}
        party={state.selectedParty}
        actions={addPartyActions}
        dialogHidden={state.partyDialogHidden}
        session={session}
      />
      {messages.message && (
        <Toast
          message={messages.message}
          toastType={messages.toastType}
          position={messages.position}
        />
      )}
    </Fragment>
  );
};

LoanForm.propTypes = {
  session: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  electionYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isReportable: PropTypes.bool,
  messagingActions: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
  validationActions: PropTypes.object.isRequired,
  verifyReport: PropTypes.bool,
  loan: PropTypes.object,
  saveButtonDisabled: PropTypes.bool.isRequired,
  setSaveButtonDisabled: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
};

LoanForm.defaultProps = {
  isReportable: false,
  onDelete: null,
  verifyReport: false,
  loan: null,
};
