import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { SalutationPicker } from '../SalutationPicker';
import { GridRow, Column } from '../../common';

const IndividualEntity = ({
  salutation,
  firstName,
  middleName,
  lastName,
  suffix,
  employer,
  occupation,
  onChange,
}) => {
  return (
    <Fragment>
      {salutation.show && (
        <GridRow>
          <Column md={4}>
            <SalutationPicker
              selectedKey={salutation.value}
              label={salutation.label}
              onChange={onChange(salutation.field)}
              errorMessage={salutation.errorMessage}
              required={salutation.required}
            />
          </Column>
        </GridRow>
      )}
      <GridRow>
        <Column md={suffix.show ? 3 : 4}>
          <TextField
            value={firstName.value}
            label={firstName.label}
            onChange={onChange(firstName.field)}
            required={firstName.required}
            errorMessage={firstName.errorMessage}
          />
        </Column>
        <Column md={suffix.show ? 3 : 4}>
          <TextField
            value={middleName.value}
            label={middleName.label}
            onChange={onChange(middleName.field)}
            required={middleName.required}
            errorMessage={middleName.errorMessage}
          />
        </Column>
        <Column md={suffix.show ? 3 : 4}>
          <TextField
            value={lastName.value}
            label={lastName.label}
            onChange={onChange(lastName.field)}
            required={lastName.required}
            errorMessage={lastName.errorMessage}
          />
        </Column>
        {suffix.show && (
          <Column md={3}>
            <TextField
              value={suffix.value}
              label={suffix.label}
              onChange={onChange(suffix.field)}
              required={suffix.required}
              errorMessage={suffix.errorMessage}
            />
          </Column>
        )}
      </GridRow>
      <GridRow>
        {employer.show && (
          <Column md={6}>
            <TextField
              value={employer.value}
              label={employer.label}
              onChange={onChange(employer.field)}
              required={employer.required}
              errorMessage={employer.errorMessage}
            />
          </Column>
        )}
        {occupation.show && (
          <Column md={6}>
            <TextField
              value={occupation.value}
              label={occupation.label}
              onChange={onChange(occupation.field)}
              required={occupation.required}
              errorMessage={occupation.errorMessage}
            />
          </Column>
        )}
      </GridRow>
    </Fragment>
  );
};

IndividualEntity.propTypes = {
  salutation: PropTypes.object.isRequired,
  firstName: PropTypes.object.isRequired,
  middleName: PropTypes.object.isRequired,
  lastName: PropTypes.object.isRequired,
  employer: PropTypes.object.isRequired,
  occupation: PropTypes.object.isRequired,
  suffix: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IndividualEntity;
