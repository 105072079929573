export const fecStateMapByStateName = {
  'Armed Forces Americas': 'AA',
  'Armed Forces Europe': 'AE',
  Alaska: 'AK',
  Alabama: 'AL',
  'Armed Forces Pacific': 'AP',
  Arkansas: 'AR',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  'District of Columbia': 'DC',
  Delaware: 'DE',
  Florida: 'FL',
  'Federated States of Micronesia': 'FM',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Iowa: 'IA',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Massachusetts: 'MA',
  Maryland: 'MD',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Michigan: 'MI',
  Minnesota: 'MN',
  Missouri: 'MO',
  'Mariana Islands': 'MP',
  Mississippi: 'MS',
  Montana: 'MT',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Nebraska: 'NE',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  Nevada: 'NV',
  'New York': 'NY',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  Palau: 'PW',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Virginia: 'VA',
  'Virgin Islands': 'VI',
  Vermont: 'VT',
  Washington: 'WA',
  Wisconsin: 'WI',
  'West Virginia': 'WV',
  Wyoming: 'WY',
  'Foreign Countries': 'ZZ',
};

export const stateMapByStateName = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  'Foreign Countries': 'ZZ',
};

export const fecStateMapByAbbreviation = {
  AA: 'Armed Forces Americas',
  AE: 'Armed Forces Europe',
  AK: 'Alaska',
  AL: 'Alabama',
  AP: 'Armed Forces Pacific',
  AR: 'Arkansas',
  AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  FM: 'Federated States of Micronesia',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland ',
  ME: 'Maine',
  MH: 'Marshall Islands`',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MP: 'Mariana Islands',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  PW: 'Palau',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Island',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
  ZZ: 'Foreign Countries',
};

export const stateMapByAbbreviation = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  ZZ: 'Foreign Countries',
};

export const fecStates = [
  { abbr: 'AA', name: 'Armed Forces Americas' },
  { abbr: 'AE', name: 'Armed Forces Europe' },
  { abbr: 'AK', name: 'Alaska' },
  { abbr: 'AL', name: 'Alabama' },
  { abbr: 'AP', name: 'Armed Forces Pacific' },
  { abbr: 'AR', name: 'Arkansas' },
  { abbr: 'AS', name: 'American Samoa' },
  { abbr: 'AZ', name: 'Arizona' },
  { abbr: 'CA', name: 'California' },
  { abbr: 'CO', name: 'Colorado' },
  { abbr: 'CT', name: 'Connecticut' },
  { abbr: 'DC', name: 'District of Columbia' },
  { abbr: 'DE', name: 'Delaware' },
  { abbr: 'FL', name: 'Florida' },
  { abbr: 'FM', name: 'Federated States of Micronesia' },
  { abbr: 'GA', name: 'Georgia' },
  { abbr: 'GU', name: 'Guam' },
  { abbr: 'HI', name: 'Hawaii' },
  { abbr: 'IA', name: 'Iowa' },
  { abbr: 'ID', name: 'Idaho' },
  { abbr: 'IL', name: 'Illinois' },
  { abbr: 'IN', name: 'Indiana' },
  { abbr: 'KS', name: 'Kansas' },
  { abbr: 'KY', name: 'Kentucky' },
  { abbr: 'LA', name: 'Louisiana' },
  { abbr: 'MA', name: 'Massachusetts' },
  { abbr: 'MD', name: 'Maryland ' },
  { abbr: 'ME', name: 'Maine' },
  { abbr: 'MH', name: 'Marshall Islands' },
  { abbr: 'MI', name: 'Michigan' },
  { abbr: 'MN', name: 'Minnesota' },
  { abbr: 'MO', name: 'Missouri' },
  { abbr: 'MP', name: 'Mariana Islands' },
  { abbr: 'MS', name: 'Mississippi' },
  { abbr: 'MT', name: 'Montana' },
  { abbr: 'NC', name: 'North Carolina' },
  { abbr: 'ND', name: 'North Dakota' },
  { abbr: 'NE', name: 'Nebraska' },
  { abbr: 'NH', name: 'New Hampshire' },
  { abbr: 'NJ', name: 'New Jersey' },
  { abbr: 'NM', name: 'New Mexico' },
  { abbr: 'NV', name: 'Nevada' },
  { abbr: 'NY', name: 'New York' },
  { abbr: 'OH', name: 'Ohio' },
  { abbr: 'OK', name: 'Oklahoma' },
  { abbr: 'OR', name: 'Oregon' },
  { abbr: 'PA', name: 'Pennsylvania' },
  { abbr: 'PR', name: 'Puerto Rico' },
  { abbr: 'PW', name: 'Palau' },
  { abbr: 'RI', name: 'Rhode Island' },
  { abbr: 'SC', name: 'South Carolina' },
  { abbr: 'SD', name: 'South Dakota' },
  { abbr: 'TN', name: 'Tennessee' },
  { abbr: 'TX', name: 'Texas' },
  { abbr: 'UT', name: 'Utah' },
  { abbr: 'VA', name: 'Virginia' },
  { abbr: 'VI', name: 'Virgin Island' },
  { abbr: 'VT', name: 'Vermont' },
  { abbr: 'WA', name: 'Washington' },
  { abbr: 'WI', name: 'Wisconsin' },
  { abbr: 'WV', name: 'West Virginia' },
  { abbr: 'WY', name: 'Wyoming' },
  { abbr: 'ZZ', name: 'Foreign Countries' },
];

export const states = [
  { abbr: 'AL', name: 'Alabama' },
  { abbr: 'AK', name: 'Alaska' },
  { abbr: 'AZ', name: 'Arizona' },
  { abbr: 'AR', name: 'Arkansas' },
  { abbr: 'CA', name: 'California' },
  { abbr: 'CO', name: 'Colorado' },
  { abbr: 'CT', name: 'Connecticut' },
  { abbr: 'DE', name: 'Delaware' },
  { abbr: 'DC', name: 'District of Columbia' },
  { abbr: 'FL', name: 'Florida' },
  { abbr: 'GA', name: 'Georgia' },
  { abbr: 'HI', name: 'Hawaii' },
  { abbr: 'ID', name: 'Idaho' },
  { abbr: 'IL', name: 'Illinois' },
  { abbr: 'IN', name: 'Indiana' },
  { abbr: 'IA', name: 'Iowa' },
  { abbr: 'KS', name: 'Kansas' },
  { abbr: 'KY', name: 'Kentucky' },
  { abbr: 'LA', name: 'Louisiana' },
  { abbr: 'ME', name: 'Maine' },
  { abbr: 'MD', name: 'Maryland' },
  { abbr: 'MA', name: 'Massachusetts' },
  { abbr: 'MI', name: 'Michigan' },
  { abbr: 'MN', name: 'Minnesota' },
  { abbr: 'MS', name: 'Mississippi' },
  { abbr: 'MO', name: 'Missouri' },
  { abbr: 'MT', name: 'Montana' },
  { abbr: 'NE', name: 'Nebraska' },
  { abbr: 'NV', name: 'Nevada' },
  { abbr: 'NH', name: 'New Hampshire' },
  { abbr: 'NJ', name: 'New Jersey' },
  { abbr: 'NM', name: 'New Mexico' },
  { abbr: 'NY', name: 'New York' },
  { abbr: 'NC', name: 'North Carolina' },
  { abbr: 'ND', name: 'North Dakota' },
  { abbr: 'OH', name: 'Ohio' },
  { abbr: 'OK', name: 'Oklahoma' },
  { abbr: 'OR', name: 'Oregon' },
  { abbr: 'PA', name: 'Pennsylvania' },
  { abbr: 'RI', name: 'Rhode Island' },
  { abbr: 'SC', name: 'South Carolina' },
  { abbr: 'SD', name: 'South Dakota' },
  { abbr: 'TN', name: 'Tennessee' },
  { abbr: 'TX', name: 'Texas' },
  { abbr: 'UT', name: 'Utah' },
  { abbr: 'VT', name: 'Vermont' },
  { abbr: 'VA', name: 'Virginia' },
  { abbr: 'WA', name: 'Washington' },
  { abbr: 'WV', name: 'West Virginia' },
  { abbr: 'WI', name: 'Wisconsin' },
  { abbr: 'WY', name: 'Wyoming' },
  { abbr: 'ZZ', name: 'Foreign Countries' },
];

export const partiesObjMap = {
  1: { id: 1, name: 'Democrat' },
  2: { id: 2, name: 'Independent' },
  3: { id: 3, name: 'Non-Partisan' },
  4: { id: 4, name: 'Republican' },
};

export const partiesArray = [
  { id: 1, name: 'Democrat' },
  { id: 2, name: 'Independent' },
  { id: 3, name: 'Non-Partisan' },
  { id: 4, name: 'Republican' },
];

export const currencyRegex = /^\d+\.?[0-9]{0,2}$/;

export const numRegex = /-?(\d+|\d+\.\d+|\.\d+)([eE][-+]?\d+)?/;

export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]{2,}@[a-zA-Z0-9-]{2,}(?:\.[a-zA-Z0-9-]{2,})+$/;

export const CampaignUserRoles = {
  SysAdmin: 'SysAdmin',
  Admin: 'Admin',
  Accounting: 'Accounting',
  DataEntry: 'DataEntry',
  ContributionEntry: 'ContributionEntry',
  ExpenditureEntry: 'ExpenditureEntry',
};

export const campaignUserRoleOptions = [
  { key: 'Admin', text: 'Account Administrator' },
  { key: 'Accounting', text: 'Accounting' },
  { key: 'DataEntry', text: 'Data Entry' },
  { key: 'ContributionEntry', text: 'Contribution Entry' },
  { key: 'ExpenditureEntry', text: 'Expenditure Entry' },
];

export const signupOfficeTypes = {
  FEDERAL: 1,
  STATE: 2,
  LOCAL: 3,
};

export const salutations = [
  'none',
  'Mr.',
  'Mrs.',
  'Ms.',
  'Miss',
  'Dr.',
  'Honorable',
];

export const committeeTypes = [
  { key: 'political-party', text: 'Political Party' },
  { key: 'individual', text: 'Individual' },
  { key: 'corporation', text: 'Corporation' },
  { key: 'political-action-committee', text: 'Political Action Committee' },
  { key: 'independent-committee', text: 'Independent Committee', hidden: true },
  { key: 'statewide-referendum', text: 'Statewide Referendum', hidden: true },
  {
    key: 'constitutional-amendment',
    text: 'Constitutional Amendment',
    hidden: true,
  },
  {
    key: 'county-or-municipal-ballot-question',
    text: 'County or Municipal Ballot Question',
    hidden: true,
  },
];

export const contributionTypes = [
  { key: 'In Kind', text: 'In Kind' },
  { key: 'Monetary', text: 'Monetary' },
  { key: 'Credit Received on Loan', text: 'Credit Received on Loan' },
  { key: 'Common Source', text: 'Common Source' },
];

export const fecContributionTypes = [
  { key: 'In Kind', text: 'In Kind' },
  { key: 'Monetary', text: 'Monetary' },
];

export const fecContributionCategories = [
  { key: 'Contribution', text: 'Contribution' },
  { key: 'Offset to Expenditures', text: 'Offset to Expenditures' },
  { key: 'Other Receipt', text: 'Other Receipt' },
  { key: 'Small Dollar Event Receipt', text: 'Small Dollar Event Receipt' },
];

export const expenditureTypes = [
  'In Kind',
  'Monetary',
  'Loan Repayment',
  'Credit Card',
  'Deferred Payment',
  'Payment on Deferred Expense',
  'Refund',
  'Reimbursement',
  '3rd Party',
  'Other',
];

export const federalPacExpenditureTypes = [
  'In Kind',
  'Monetary',
  'Credit Card',
  'Independent Expenditure (Schedule E)',
  'Coordinated Party Expenditure (Schedule F)',
  'Reimbursement',
  'Loan Made',
  'Other',
  'Federal Election Activity',
];

export const contributionSourceTypes = [
  'Individual',
  'Business',
  'Common Source',
  'Committee',
];

export const expenditureRecipientTypes = [
  'Individual',
  'Committee',
  'Business',
];

export const lenderTypes = ['Individual', 'Business'];

export const endRecipientTypes = ['Individual', 'Business', 'Committee'];

export const paymentTypes = [
  'Cash',
  'Check',
  'Credit Card',
  'Debit Card',
  'Wire Transfer',
  'ACH',
  'Other',
];

export const businessTypes = [
  'Anonymous Organization',
  'Bank/ Lender',
  'Corporation',
  'LLC',
  'Non-Profit',
  'Partnership',
  'Union',
];

export const politicalCommitteeTypes = [
  'Authorized Committee',
  'Candidate Committee',
  'Labor Organization',
  'Multi-Candidate Federal PAC',
  'Multi-Candidate State PAC',
  'Non-Qualified Committee',
  'Political Party Committee',
  'State Office Committee',
];

export const creditCardTypes = [
  'American Express',
  'Visa',
  'Mastercard',
  'Discover',
];

export const statuses = {
  NOT_STARTED: 'not-started',
  PROCESSING: 'processing',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const toastTypes = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const toastPositions = {
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right',
};

export const sortDirections = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
};

export const moneyRegex = /^[0-9](\.[0-9]2)?$/;

export const officeTypes = {
  FEDERAL: 'Federal',
  STATE: 'State',
  LOCAL: 'Local',
};

export const contactTypes = {
  INDIVIDUAL: 'Individual',
  BUSINESS: 'Business',
  COMMON_SOURCE: 'Common Source',
  COMMITTEE: 'Committee',
};

export const CREDIT_CARD_EXPENDITURE = 'CREDIT_CARD_EXPENDITURE';

export const federalOffices = {
  A: 'Attorney General',
  H: 'United States House of Representatives',
  S: 'United States Senate',
  P: 'President',
};

export const federalOfficesOptions = [
  { key: 'A', text: 'Attorney General' },
  { key: 'H', text: 'United States House of Representatives' },
  { key: 'S', text: 'United States Senate' },
  { key: 'P', text: 'President' },
];

export const federalPartyAffiliationOptions = [
  { key: 'DEM', text: 'Democratic Party' },
  { key: 'REP', text: 'Republican Party' },
  { key: 'REF', text: 'Reform Party' },
  { key: 'GRE', text: 'Green Party' },
  { key: 'IND', text: 'Independent' },
];

export const fecNonCandidateCommitteeOptions = [
  { key: 'pac-separate-segregated-fund', text: 'PAC Separate Segregated Fund' },
  {
    key: 'pac-support-oppose-more-than-one',
    text: 'PAC Support Oppose More Than One',
  },
  {
    key: 'independent-expenditure-only-super-pac',
    text: 'SUPER PAC Independent Expenditure',
  },
  {
    key: 'contribution-and-non-contribution-hybrid-pac',
    text: 'HYBRID PAC Contribution and Non Contribution',
  },
  {
    key: 'jfc-disburses-to-candidate-auth-committee',
    text: 'JFC Disburses To Candidate Authorized Committee',
  },
  {
    key: 'jfc-disburses-to-no-candidate-auth-committee',
    text: 'JFC Does Not Disburse To Candidate Authorized Committee',
  },
];

export const fecPacOrganizationOptions = [
  { key: 'C', text: 'Corporation' },
  { key: 'T', text: 'Trade Association' },
  { key: 'L', text: 'Labor Organization' },
  { key: 'M', text: 'Membership Organization' },
  { key: 'V', text: 'Cooperative' },
  { key: 'W', text: 'Corporation Without Capital Stock' },
];

export const fecCommitteeTypes = {
  CandidatePrincipal: 'candidate-principal',
  PacSeparateSegregatedFund: 'pac-separate-segregated-fund',
  PacSupportOpposeMoreThanOne: 'pac-support-oppose-more-than-one',
  PacIndependentExpenditureOnly: 'independent-expenditure-only-super-pac',
  PacContributionAndNonContribution:
    'contribution-and-non-contribution-hybrid-pac',
  JFCDisbursesToCandidate: 'jfc-disburses-to-candidate-auth-committee',
  JFCDisbursesToNoCandidate: 'jfc-disburses-to-no-candidate-auth-committee',
};

export const frCampaignTypes = {
  FederalCandidate: 'federal-candidate',
  FederalNonCandidate: 'federal-non-candidate',
  StateLocalCandidate: 'state-local-candidate',
  StateLocalNonCandidate: 'state-local-non-candidate',
};

export const allocatedActivityOrEventOptions = [
  { key: 'allocated-for-administrative', text: 'Administrative' },
  { key: 'allocated-for-fundraising', text: 'Fundraising' },
  { key: 'allocated-for-exempt', text: 'Exempt' },
  { key: 'allocated-for-voter-drive', text: 'Voter Drive' },
  {
    key: 'allocated-for-direct-candidate-support',
    text: 'Direct Candidate Support',
  },
  {
    key: 'allocated-for-public-comm',
    text: 'Public Comm (ref to party only) by PAC',
  },
];

export const disbursementCategories = [
  {
    key: '001',
    text: '001 - Administrative/Salary/Overhead Expenses',
    included: [
      'rent',
      'staff salaries',
      'postage office supplies',
      'equipment',
      'furniture',
      'ballot access fees',
      'petition drives',
      'party fees',
      'legal and accounting expenses',
    ],
  },
  {
    key: '002',
    text: '002 - Travel Expenses',
    included: [
      'travel reimbursement expenses',
      'cost of commercial carrier tickets',
      'reimbursements for use of private vehicles',
      'advance payments for use of corporate aircraft',
      'lodging meals expenses incurred during travel',
    ],
  },
  {
    key: '003',
    text: '003 - Solicitation and Fundraising Expenses',
    included: [
      'cost for direct mail solitications and fundraising events including printing, mailing lists, consultant fees, call lists, invitations, catering costs, and room rental',
    ],
  },
  {
    key: '004',
    text: '004 - Advertising Expenses',
    included: [
      'general public political advertising',
      'purchase of radio/television broadcast/cable time',
      'print advertisements',
      'related productions costs',
    ],
  },
  {
    key: '005',
    text: '005 - Polling Expenses',
    included: [],
  },
  {
    key: '006',
    text: '006 - Campaign Materials',
    included: [
      'buttons',
      'bumper stickers',
      'brochures',
      'mass mailings',
      'pens',
      'posters',
      'balloons',
    ],
  },
  {
    key: '007',
    text: '007 - Campaign Event Expenses',
    included: [
      'costs associated with candidate appearances',
      'campaign rallies',
      'town meetings',
      'phone banks',
      'included catering costs',
      'door to door get-out-the-vote efforts',
      'driving voters to the polls',
    ],
  },
  {
    key: '008',
    text: '008 - Transfers',
    included: ['to other authorized committees of the same candidate'],
  },
  {
    key: '009',
    text: '009 - Loan Repayments',
    included: [
      'repayments of loans made/guaranteed by the candidate or other person',
    ],
  },
  {
    key: '010',
    text: '010 - Refunds of Contributions',
    included: [
      'contribution refunds to individuals/persons, political party committees or other political committees',
    ],
  },
  {
    key: '011',
    text: '011 - Political Contributions',
    included: [
      'contributions to other federal candidates and committess',
      'donations to nonfederal candidates and committees',
    ],
  },
  {
    key: '012',
    text: '012 - Donations',
    included: ['donations to charitable or civic organizations'],
  },
];

export const presidentialDisbursementCategories = [
  {
    key: '101',
    text: '101 - Expenses that are not allocable',
    included: [],
  },
  {
    key: '102',
    text: '102 - Media expenditures',
    included: [],
  },
  {
    key: '103',
    text: '103 - Expenditures for mass mailings and other campaign materials',
    included: [
      'buttons',
      'bumper stickers',
      'brochures',
      'mass mailings',
      'pens',
      'posters',
      'balloons',
    ],
  },
  {
    key: '104',
    text: '104 - Overhead expenditures of state offices and their facilities',
    included: [
      'rent, staff salaries, postage, office supplies, equipment, furniture, ballot access fees, petition drives, party fees and legal and accounting expenses',
    ],
  },
  {
    key: '105',
    text: '105 - Expenditures for special telephone programs',
    included: [],
  },
  {
    key: '106',
    text: '106 - Public opinion poll expenditures',
    included: [],
  },
  {
    key: '107',
    text: '107 - Fundraising expenditures',
    included: [
      'costs for direct mail solicitations & fundraising events',
      'printing',
      'mailing lists',
      'consultant fees',
      'call lists',
      'invitations',
      'catering costs',
      'room rental',
    ],
  },
];

export const UTCRegex = RegExp(
  /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d\.\d{0-3}(?:\+00:00|Z)?/,
);

export const FecMonthlyReportTypes = [
  {
    key: 'JanuaryMonthlyReport',
    text: 'YE',
    fullText: 'January 31 Year End (YE)',
    listingText: 'JANUARY MONTHLY',
  },
  {
    key: 'FebruaryMonthlyReport',
    text: 'M2',
    fullText: 'February 20 Monthly Report (M2)',
    listingText: 'FEBRUARY MONTHLY',
  },
  {
    key: 'MarchMonthlyReport',
    text: 'M3',
    fullText: 'March 20 Monthly Report (M3)',
    listingText: 'MARCH MONTHLY',
  },
  {
    key: 'AprilMonthlyReport',
    text: 'M4',
    fullText: 'April 20 Monthly Report (M4)',
    listingText: 'APRIL MONTHLY',
  },
  {
    key: 'MayMonthlyReport',
    text: 'M5',
    fullText: 'May 20 Monthly Report (M5)',
    listingText: 'MAY MONTHLY',
  },
  {
    key: 'JuneMonthlyReport',
    text: 'M6',
    fullText: 'June 20 Monthly Report (M6)',
    listingText: 'JUNE MONTHLY',
  },
  {
    key: 'JulyMonthlyReport',
    text: 'M7',
    fullText: 'July 20 Monthly Report (M7)',
    listingText: 'JULY MONTHLY',
  },
  {
    key: 'AugustMonthlyReport',
    text: 'M8',
    fullText: 'August 20 Monthly Report (M8)',
    listingText: 'AUGUST MONTHLY',
  },
  {
    key: 'SeptemberMonthlyReport',
    text: 'M9',
    fullText: 'September 20 Monthly Report (M9)',
    listingText: 'SEPTEMBER MONTHLY',
  },
  {
    key: 'OctoberMonthlyReport',
    text: 'M10',
    fullText: 'October 20 Monthly Report (M10)',
    listingText: 'OCTOBER MONTHLY',
  },
  {
    key: 'NovemberMonthlyReport',
    text: 'M11',
    fullText: 'November 20 Monthly Non-Election (M11)',
    listingText: 'NOVEMBER MONTHLY NON-ELECTION',
  },
  {
    key: 'DecemberMonthlyReport',
    text: 'M12',
    fullText: 'December 20 Monthly Non-Election (M12)',
    listingText: 'DECEMBER MONTHLY NON-ELECTION',
  },
];

export const FecReportTypes = [
  {
    key: 'April15QuarterlyReport',
    text: 'Q1',
    fullText: 'April 15 Quarterly Report (Q1)',
    listingText: 'APRIL QUARTERLY',
  },
  {
    key: 'July15QuarterlyReport',
    text: 'Q2',
    fullText: 'July 15 Quarterly Report (Q2)',
    listingText: 'JULY QUARTERLY',
  },
  {
    key: 'October15QuarterlyReport',
    text: 'Q3',
    fullText: 'October 15 Quarterly Report (Q3)',
    listingText: 'OCTOBER QUARTERLY',
  },
  {
    key: 'January31YearEndReport',
    text: 'YE',
    fullText: 'January 31 Year-End (YE)',
    listingText: 'YEAR-END',
  },
  {
    key: 'July31MidYearReport',
    text: 'MY',
    fullText: 'July 31 Mid-Year (MY)',
    listingText: 'MID-YEAR',
  },
  {
    key: 'TwelveDayPrePrimaryElectionReport',
    text: '12P',
    fullText: '12-Day PRE-Election Primary (12P)',
    listingText: 'PRE-PRIMARY',
  },
  {
    key: 'TwelveDayPreGeneralElectionReport',
    text: '12G',
    fullText: '12-Day PRE-Election General (12G)',
    listingText: 'PRE-ELECTION',
  },
  {
    key: 'TwelveDayPreConventionElectionReport',
    text: '12C',
    fullText: '12-Day PRE-Election Convention (12C)',
    listingText: 'PRE-ELECTION',
  },
  {
    key: 'TwelveDayPreSpecialElectionReport',
    text: '12S',
    fullText: '12-Day PRE-Election Special (12S)',
    listingText: 'PRE-ELECTION',
  },
  {
    key: 'TwelveDayPreRunoffElectionReport',
    text: '12R',
    fullText: '12-Day PRE-Election Runoff (12R)',
    listingText: 'PRE-ELECTION',
  },
  {
    key: 'ThirtyDatePostGeneralElectionReport',
    text: '30G',
    fullText: '30-Day POST-Election General (30G)',
    listingText: 'POST-ELECTION',
  },
  {
    key: 'ThirtyDatePostRunoffElectionReport',
    text: '30R',
    fullText: '30-Day POST-Election Runoff (30R)',
    listingText: 'POST-ELECTION',
  },
  {
    key: 'ThirtyDatePostSpecialElectionReport',
    text: '30S',
    fullText: '30-Day POST-Election Special (30S)',
    listingText: 'POST-ELECTION',
  },
  {
    key: 'TerminationReport',
    text: 'TER',
    fullText: 'Termination Report (TER)',
    listingText: 'TERMINATION',
  },
];

export const FecElectionCycles = [
  'Primary',
  'General',
  'Convention',
  'Runoff',
  'Recount',
  'Special',
  'Other',
];

export const federalReportTypeMap = {
  April15QuarterlyReport: 'Q1',
  July15QuarterlyReport: 'Q2',
  October15QuarterlyReport: 'Q3',
  January31YearEndReport: 'YE',
  TerminationReport: 'TER',
  TwelveDayPrePrimaryElectionReport: '12P',
  TwelveDayPreGeneralElectionReport: '12G',
  TwelveDayPreConventionElectionReport: '12C',
  TwelveDayPreSpecialElectionReport: '12S',
  ThirtyDatePostGeneralElectionReport: '30G',
  ThirtyDatePostRunoffElectionReport: '30R',
  ThirtyDatePostSpecialElectionReport: '30S',
};

export const nonFECSourceTypes = [
  { key: 'Business', text: 'Business' },
  { key: 'Committee', text: 'Committee' },
  { key: 'Common Source', text: 'Common Source' },
  { key: 'Individual', text: 'Individual' },
];

export const nonFECIndividualSourceTypes = [
  { key: 'Individual', text: 'Individual' },
];

export const FECSourceTypeCodes = {
  CAN: 'CAN',
  CCM: 'CCM',
  COM: 'COM',
  IND: 'IND',
  ORG: 'ORG',
  PAC: 'PAC',
  PTY: 'PTY',
};

export const FECSourceTypes = [
  { key: 'CAN', text: 'Candidate' },
  { key: 'CCM', text: 'Candidate Campaign Committee (Federally Registered)' },
  { key: 'COM', text: 'Non-Federal Committee' },
  { key: 'IND', text: 'Individual (a person)' },
  { key: 'ORG', text: 'Organization (not a committee and not a person)' },
  { key: 'PAC', text: 'Political Action Committee (Federally Registered)' },
  { key: 'PTY', text: 'Political Party Organization (Federally Registered)' },
];

export const StateSourceTypes = [
  { key: 'Individual', text: 'Individual' },
  { key: 'Business', text: 'Business' },
  { key: 'Common Source', text: 'Common Source' },
  { key: 'Committee', text: 'Committee' },
];

export const FECIndividualSourceTypes = [
  { key: 'CAN', text: 'Candidate' },
  { key: 'IND', text: 'Individual (a person)' },
];

export const contactIconMap = {
  Individual: 'User',
  Business: 'Building',
  'Common Source': 'UserFriends',
  Committee: 'Users',
  'Candidate Committee': 'ChalkboardTeacher',
  'PAC: multicandidate': 'Bolt',
  'PAC: non-multicandidate': 'UserTie',
  'Party Committee: state / district / local': 'City',
  'Party Committee: national': 'FlagUsa',
  CAN: 'UserTie',
  CCM: 'ChalkboardTeacher',
  COM: 'Users',
  IND: 'User',
  ORG: 'Building',
  PAC: 'Bolt',
  PTY: 'AddressCardAlt',
};

export const nonIndividualErrorTypeMap = {
  Business: 'Business name',
  ORG: 'Organization name',
  PTY: 'Organization name',
  CCM: 'Committee name',
  COM: 'Committee name',
  Committee: 'Committee name',
  PAC: 'Committee name',
  'Candidate Committee': 'Committee name',
  'Common Source': 'Business name',
};

export const FECReportingFrequencies = {
  Monthly: 'monthly',
  Quarterly: 'quarterly',
};

export const AnedotProcessedStates = {
  PROCESSED: 'Processed',
  NOT_PROCESSED: 'NotProcessed',
  DELETED: 'Deleted',
};

export const fecEndRecipientTypes = [
  FECSourceTypeCodes.IND,
  FECSourceTypeCodes.ORG,
  FECSourceTypeCodes.COM,
];

export const ReportBalanceSource = {
  CampaignDefault: 'CampaignDefault',
  PreviousFiling: 'PreviousFiling',
};

export const timeZones = [
  { text: 'Alaska', key: 'US/Alaska' },
  { text: 'Hawaii', key: 'US/Hawaii' },
  { text: 'Arizona', key: 'US/Arizona' },
  { text: 'Pacific', key: 'US/Pacific' },
  { text: 'Mountain', key: 'US/Mountain' },
  { text: 'Central', key: 'US/Central' },
  { text: 'Eastern', key: 'US/Eastern' },
];

export const activityTypes = {
  Contribution: 'contribution',
  Expenditure: 'expenditure',
  Investment: 'investment',
  Loan: 'loan',
};

export const gaOccupationCodes = [
  { key: '1', text: 'Accountant' },
  { key: '2', text: 'Administrative Assistant' },
  { key: '3', text: 'Administrative services manager' },
  { key: '4', text: 'Auditing Clerk' },
  { key: '5', text: 'Auditor' },
  { key: '6', text: 'Automotive service technician and mechanic' },
  { key: '7', text: 'Bartender' },
  { key: '8', text: 'Billing and posting clerk and machine operator' },
  { key: '9', text: 'Bookkeeping, accounting, and auditing clerk' },
  { key: '10', text: 'Bus and truck mechanic and diesel engine specialist' },
  { key: '11', text: 'Bus driver, school' },
  { key: '12', text: 'Business operations specialist' },
  { key: '13', text: 'Carpenter' },
  { key: '14', text: 'Cashier' },
  { key: '15', text: 'Cement mason and concrete finisher ' },
  { key: '16', text: 'Child, family, and school social worker ' },
  { key: '17', text: 'Computer programmer' },
  { key: '18', text: 'Computer support specialist' },
  { key: '19', text: 'Computer systems analyst' },
  { key: '20', text: 'Construction laborer' },
  { key: '21', text: 'Cook, institution and cafeteria' },
  { key: '22', text: 'Cook, restaurant' },
  { key: '23', text: 'Cooks, fast food' },
  { key: '24', text: 'Counter and rental clerk' },
  {
    key: '25',
    text: 'Counter attendant, cafeteria, food conceion, and coffee shop',
  },
  { key: '26', text: 'Customer service representative' },
  { key: '27', text: 'Dental assistant' },
  { key: '28', text: 'Derrick Operator' },
  { key: '29', text: 'Diagnostic Medical Sonographer' },
  { key: '30', text: 'Dining room and cafeteria attendant' },
  { key: '31', text: 'Dishwasher' },
  { key: '32', text: 'Driver/sales worker' },
  { key: '33', text: 'Electrical and electronic engineering technician' },
  { key: '34', text: 'Electrician' },
  { key: '35', text: 'Electronic engineer, except computer' },
  { key: '36', text: 'Elementary school teacher' },
  { key: '37', text: 'Engineering manager' },
  { key: '38', text: 'Executive secretary ' },
  { key: '39', text: 'Financial manager' },
  {
    key: '40',
    text:
      'First-line supervisor/manager of construction trades and extraction workers',
  },
  {
    key: '41',
    text:
      'First-line supervisor/manager of food preparation and serving workers',
  },
  {
    key: '42',
    text:
      'First-line supervisor/manager of mechanics, installers, and repairers',
  },
  {
    key: '43',
    text: 'First-line supervisor/manager of non-retail sales workers',
  },
  {
    key: '44',
    text:
      'First-line supervisor/manager of office and administrative support workers',
  },
  {
    key: '45',
    text: 'First-line supervisor/manager of production and operating workers',
  },
  { key: '46', text: 'First-line supervisor/manager of retail sales workers' },
  { key: '47', text: 'Food preparation and serving worker ' },
  { key: '48', text: 'Food preparation worker' },
  { key: '49', text: 'Food server, nonrestaurant' },
  { key: '50', text: 'General and operations manager' },
  { key: '51', text: 'Healthcare practitioner and technical worker' },
  { key: '52', text: 'Healthcare support worker' },
  {
    key: '53',
    text: 'Heating, air conditioning, and refrigeration mechanic and installer',
  },
  { key: '54', text: 'Helper--installation, maintenance, and repair workers' },
  { key: '55', text: 'Helper--production worker' },
  { key: '56', text: 'Home health aide' },
  { key: '57', text: 'Host and hostess, restaurant, lounge, and coffee shop' },
  { key: '58', text: 'Hotel, motel, and resort desk clerk' },
  { key: '59', text: 'Industrial truck and tractor operator' },
  { key: '60', text: 'Information and record clerk' },
  { key: '61', text: 'Inspector, tester, sorter, sampler, and weigher' },
  { key: '62', text: 'Insurance sales agent' },
  { key: '63', text: 'Interpreter' },
  {
    key: '64',
    text: 'Janitor and cleaner, except maids and housekeeping cleaners',
  },
  { key: '65', text: 'Laborers and freight, stock, and material mover, hand' },
  { key: '66', text: 'Landscaping and groundskeeping worker' },
  { key: '67', text: 'Lawyer' },
  { key: '68', text: 'Licensed practical and licensed vocational nurse' },
  { key: '69', text: 'Maid and housekeeping cleaner' },
  { key: '70', text: 'Maintenance and repair worker, general' },
  { key: '71', text: 'Management analyst' },
  { key: '72', text: 'Manager' },
  { key: '73', text: 'Medical assistant' },
  { key: '74', text: 'Medical secretary' },
  { key: '75', text: 'Meeting & Convention Planner' },
  { key: '76', text: 'Middle school teacher' },
  { key: '77', text: 'Nurse' },
  { key: '78', text: 'Nursing aide, orderly, and attendant' },
  { key: '79', text: 'Office clerk, general' },
  {
    key: '80',
    text: 'Operating engineer and other construction equipment operator',
  },
  { key: '81', text: 'Packer and packager, hand' },
  { key: '82', text: 'Paralegal and legal assistant' },
  { key: '83', text: 'Personal and home care aide' },
  { key: '84', text: 'Plumber, pipefitter, and steamfitter' },
  { key: '85', text: 'Police and sheriffs patrol officer' },
  { key: '86', text: 'Postal service mail carrier' },
  { key: '87', text: 'Preschool teacher' },
  { key: '88', text: 'Receptionist and information clerk' },
  { key: '89', text: 'Registered nurse' },
  { key: '90', text: 'Retail salesperson' },
  { key: '91', text: 'Rigger' },
  { key: '92', text: 'Roustabout, Oil & Gas' },
  { key: '93', text: 'Sales manager' },
  { key: '94', text: 'Sales representative' },
  { key: '95', text: 'Secondary school teacher' },
  { key: '96', text: 'Secretary, except legal, medical, and executive' },
  { key: '97', text: 'Security guard' },
  { key: '98', text: 'Shipping, receiving, and traffic clerk' },
  { key: '99', text: 'Social and community service manager' },
  { key: '100', text: 'Social and human service assistant' },
  { key: '101', text: 'Stock clerks and order filler' },
  { key: '102', text: 'Teacher' },
  { key: '103', text: 'Teacher assistant' },
  { key: '104', text: 'Team assembler' },
  { key: '105', text: 'Telemarketer' },
  { key: '106', text: 'Teller' },
  { key: '107', text: 'Truck driver, heavy and tractor-trailer' },
  { key: '108', text: 'Truck driver, light or delivery services' },
  { key: '109', text: 'Waiter and waitress' },
  { key: '597', text: 'Other' },
  { key: '598', text: 'Retired' },
  { key: '599', text: 'Farmer' },
];

export const GA_OCCUPATION_OTHER = '597';

export const gaPaymentCodes = [
  { key: 'CMP', text: 'CMP - Campaign Paraphernalia/Misc' },
  { key: 'CNS', text: 'CNS - Campaign Consultants' },
  { key: 'CTB', text: 'CTB - Contribution (explain nonmonetary) *' },
  { key: 'CVC', text: 'CVC - Civic Donations' },
  { key: 'DON', text: 'DON - Donation' },
  { key: 'FIL', text: 'FIL - Candidate Filing/Ballot Fees' },
  { key: 'FND', text: 'FND - Fundraising Events' },
  {
    key: 'IND',
    text: 'IND - Independent Expenditure Supporting/Opposing Others (explain)*',
  },
  { key: 'LEG', text: 'LEG - Legal Defense' },
  { key: 'LIT', text: 'LIT - Campaign Literature and Mailings' },
  { key: 'LMD', text: 'LMD - Loan Made' },
  { key: 'MBR', text: 'MBR - Member Communications' },
  { key: 'MTG', text: 'MTG - Meetings and Appearances' },
  { key: 'OFC', text: 'OFC - Office Expenses' },
  { key: 'OTH', text: 'OTH - Other' },
  { key: 'PET', text: 'PET - Petition XCirculating' },
  { key: 'PHO', text: 'PHO - Phone Banks' },
  { key: 'POL', text: 'POL - Polling and Survey Research' },
  { key: 'POS', text: 'POS - Postage, Delivery and Messenger Services' },
  { key: 'PRO', text: 'PRO - Professional Services (legal, accounting)' },
  { key: 'PRT', text: 'PRT - Print Ads' },
  { key: 'RAD', text: 'RAD - Radio Airtime and Production Costs' },
  { key: 'RFD', text: 'RFD - Returned Contributions' },
  { key: 'SAL', text: 'SAL - Campaign Workers’ Salaries' },
  { key: 'TEL', text: 'TEL - TV or Cable Airtime and Production Costs' },
  { key: 'TRC', text: 'TRC - Candidate Travel, Lodging, and Meals' },
  { key: 'TRS', text: 'TRS - Staff/Spouse Travel, Lodging, and Meals' },
  {
    key: 'TSF',
    text: 'TSF - Transfer Between Committees of the Same Candidate/Sponsor',
  },
  { key: 'VOT', text: 'VOT - Voter Registration' },
  { key: 'WEB', text: 'WEB - Information Technology Costs (internet, e-mail)' },
];

export const GA_PAYMENT_CODE_OTHER = 'OTH';

export const ExpenditureTypes = {
  CreditCard: 'Credit Card',
  DeferredPayment: 'Deferred Payment',
  InKind: 'In-Kind',
  LoanRepayment: 'Loan Repayment',
  Monetary: 'Monetary',
  PaymentOnDeferredExpense: 'Payment on Deferred Expense',
  Refund: 'Refund',
  Reimbursement: 'Reimbursement',
  Other: 'Other',
};

export const FederalPacExpenditureTypes = {
  InKind: 'In Kind',
  Monetary: 'Monetary',
  IndependentExpenditure: 'Independent Expenditure (Schedule E)',
  CoordinatedPartyExpenditure: 'Coordinated Party Expenditure (Schedule F)',
  LoanMade: 'Loan Made',
  LoanRepayment: 'Loan Repayment',
  Other: 'Other',
  FederalElectionActivity: 'Federal Election Activity',
};

export const DisbursementCategories = {
  Administrative: '001',
  Travel: '002',
  Solicitation: '003',
  Advertising: '004',
  Polling: '005',
  CampaignMaterials: '006',
  CampaignEvent: '007',
  Transfers: '008',
  LoanRepayments: '009',
  RefundsOfContributions: '010',
  PoliticalContributions: '011',
  Donations: '012',
};

export const ReportTypes = {
  Amended: 'Amended',
  Original: 'Original',
  TBD: 'TBD Contribution Report',
  Fec48HourNotice: '48HourNotice',
  Fec48HourNoticeAmended: '48HourNoticeAmended',
  Fec24HourNotice: '24HourNotice',
  Fec24HourNoticeAmended: '24HourNoticeAmended',
};

export const DataImportTypes = [
  { key: 'ContributionBase', text: 'Contribution Import' },
  { key: 'ContributionWithJFCAttribution', text: 'JFC Contribution Import' },
  { key: 'ContributionWithHSPAttribution', text: 'HSP Contribution Import' },
];

export const ImportProgressStep = {
  DataType: 'Data Type',
  Upload: 'Upload',
  FileFormat: 'File Format',
  Questions: 'Questions',
  ColumnMapping: 'Column Mapping',
};

export const ImportDataType = {
  Contribution: 'contributions',
  Expenditure: 'expenditures',
  Loan: 'loans',
  Contact: 'contacts',
};

export const importFileAcceptTypes = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'text/csv': ['.csv'],
};

export const ImportStatus = {
  Created: 'Created',
  Pending: 'Pending',
  Processed: 'Processed',
  Error: 'Error',
  Completed: 'Completed',
  Failed: 'Failed',
};

export const ContributionFileFormat = {
  Anedot: 'Anedot Contributions Format',
  DemocracyEngine: 'Democracy Engine Format',
  FrontRunner: 'FrontRunner Contributions Format',
  HSPMail: 'HSP Mail Format',
  MailingAndCaging: 'Mailing & Caging Format',
};

export const ImportColumnType = {
  String: 'string',
  Number: 'number',
  Date: 'date',
  Boolean: 'boolean',
};

export const FecEntityCodes = {
  Candidate: 'CAN',
  CandidateCampaignCommitteeFederallyRegistered: 'CCM',
  NonFederalCommittee: 'COM',
  Individual: 'IND',
  Organization: 'ORG',
  PoliticalActionCommitteeFederallyRegistered: 'PAC',
  PoliticalPartyOrganizationFederallyRegistered: 'PTY',
};

export const IntegrationPartners = {
  ActBlue: 'ActBlue',
  Anedot: 'Anedot',
  AnedotActionPages: 'AnedotActionPages',
  AnedotCampaigns: 'AnedotCampaigns',
  DonateRight: 'DonateRight',
  WinRed: 'WinRed',
};
