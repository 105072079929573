import { createReducer } from '../../reducers/createReducer';
import { filterOutSystemTags } from '../../helpers/util';
import { initialState as state, errors } from './contributionBaseInitialState';

export const initialState = {
  ...state,
  errors: {
    ...errors,
  },
};

export const clearContact = () => ({
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  employer: '',
  occupation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  phone1: '',
  phone2: '',
  email: '',
  businessName: '',
  businessType: 0,
  contactName: '',
  commonSource: '',
  specific: '',
  committeeAffiliation: 0,
  selectedContact: null,
  committeeFecId: '',
  contactType: 0,
});

export const clearConduitContact = () => ({
  conduitContactId: '',
  conduitContactType: 0,
  conduitSalutation: 'none',
  conduitFirstName: '',
  conduitLastName: '',
  conduitSuffix: '',
  conduitEmployer: '',
  conduitOccupation: '',
  conduitBusinessName: '',
  conduitBusinessType: 0,
  conduitContactName: '',
  conduitCommitteeAffiliation: 0,
  conduitEmail: '',
  conduitPhone1: '',
  conduitPhone2: '',
  conduitAddressLine1: '',
  conduitAddressLine2: '',
  conduitCity: '',
  conduitState: 0,
  conduitZipCode: '',
  conduitCounty: '',
  selectedConduitContact: null,
});

export const actions = {
  SET_INITIAL_CAMPAIGN: 'SET_INITIAL_CAMPAIGN',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
  CLEAR_FIELDS_FOR_NEW: 'CLEAR_FIELDS_FOR_NEW',
  CLEAR_PAYEE_INFO: 'CLEAR_PAYEE_INFO',
  ON_CONTACT_SELECTED: 'ON_CONTACT_SELECTED',
  ON_CONDUIT_CONTACT_SELECTED: 'ON_CONDUIT_CONTACT_SELECTED',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  HANDLE_COUPLED_CHANGE: 'HANDLE_COUPLED_CHANGE',
  HANDLE_CHANGE_SOURCE_TYPE: 'HANDLE_CHANGE_SOURCE_TYPE',
  HANDLE_CHANGE_CONDUIT_CONTACT_TYPE: 'HANDLE_CHANGE_CONDUIT_CONTACT_TYPE',
  HANDLE_CHANGE_PAYMENT_TYPE: 'HANDLE_CHANGE_PAYMENT_TYPE',
  HANDLE_CHANGE_ELECTION_DETAILS: 'HANDLE_CHANGE_ELECTION_DETAILS',
  HANDLE_FEDERAL_ELECTION_CHANGE: 'HANDLE_FEDERAL_ELECTION_CHANGE',
  HANDLE_LOAN_CHANGE: 'HANDLE_LOAN_CHANGE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  CLEAR_SELECTED_CONTACT: 'CLEAR_SELECTED_CONTACT',
  CLEAR_SELECTED_CONDUIT_CONTACT: 'CLEAR_SELECTED_CONDUIT_CONTACT',
  HANDLE_CHANGE_INCLUDE_CONDUIT_CONTACT:
    'HANDLE_CHANGE_INCLUDE_CONDUIT_CONTACT',
  ON_LINKED_CONTRIBUTION_SELECTED: 'ON_LINKED_CONTRIBUTION_SELECTED',
  ON_PRESENTATION_MEMO_CONTRIBUTION_SELECTED: 'ON_PRESENTATION_MEMO_CONTRIBUTION_SELECTED',
  CLEAR_LINKED_CONTRIBUTION: 'CLEAR_LINKED_CONTRIBUTION',
  CLEAR_PRESENTATION_ONLY_CONTRIBUTION: 'CLEAR_PRESENTATION_ONLY_CONTRIBUTION',
  SHOW_CONTINUE_UPDATE: 'SHOW_CONTINUE_UPDATE',
  HIDE_CONTINUE_UPDATE: 'HIDE_CONTINUE_UPDATE',
};

export const actionMap = {
  [actions.SET_INITIAL_CAMPAIGN]: (
    state,
    { data: { electionYear, isReportable } },
  ) => ({
    ...state,
    electionYear,
    isReportable,
  }),
  [actions.SET_QUERY_PARAMS]: (
    state,
    { data: { contributionType, reportIdRedirect, tbd, fec, election, frCampaignType } },
  ) => ({
    ...state,
    contributionType,
    reportIdRedirect,
    tbd,
    fec,
    election,
    frCampaignType,
  }),
  [actions.CLEAR_FIELDS_FOR_NEW]: state => ({
    ...initialState,
    reportIdRedirect: state.reportIdRedirect,
    election: state.election,
    electionYear: state.electionYear,
    contributionType: state.contributionType,
    frCampaignType: state.frCampaignType,
  }),
  [actions.CLEAR_PAYEE_INFO]: state => ({
    ...state,
    salutation: 'none',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    employer: '',
    occupation: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: 0,
    zipCode: '',
    county: '',
    phone1: '',
    phone2: '',
    email: '',
    businessName: '',
    businessType: 0,
    contactName: '',
    commonSource: '',
    specific: '',
    committeeAffiliation: 0,
    selectedContact: null,
    committeeFecId: '',
  }),
  [actions.ON_CONTACT_SELECTED]: (state, { data: { contact } }) => {
    const {
      _id: contactId,
      contactType,
      tags,
      address,
      ...contactDetails
    } = contact;
    return {
      ...state,
      businessName: contact.businessName || '',
      selectedContact: { ...contact, tags: filterOutSystemTags(tags) },
      tags: filterOutSystemTags(tags),
      ...contactDetails,
      ...address,
      contactType,
      transactionIdNumber: '', // Don't write contact id number into state of contribution
    };
  },
  [actions.ON_CONDUIT_CONTACT_SELECTED]: (state, { data: { contact } }) => {
    const {
      _id,
      firstName = '',
      middleName = '',
      lastName = '',
      suffix = '',
      businessName = '',
      address,
      contactType,
      salutation = 'none',
      businessType = 0,
      contactName = '',
      committeeAffiliation = 0,
      email = '',
      phone1 = '',
      phone2 = '',
      occupation = '',
      employer = '',
      committeeFecId = '',
    } = contact;
    const {
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state: conduitState = 0,
      zipCode = '',
      county = '',
    } = address;
    return {
      ...state,
      selectedConduitContact: contact,
      conduitContactType: contactType,
      conduitContactId: _id,
      conduitSalutation: salutation,
      conduitFirstName: firstName,
      conduitMiddleName: middleName,
      conduitLastName: lastName,
      conduitSuffix: suffix,
      conduitBusinessName: businessName,
      conduitBusinessType: businessType,
      conduitContactName: contactName,
      conduitCommitteeAffiliation: committeeAffiliation,
      conduitAddressLine1: addressLine1,
      conduitAddressLine2: addressLine2,
      conduitCity: city,
      conduitState,
      conduitZipCode: zipCode,
      conduitCounty: county,
      conduitEmail: email,
      conduitPhone1: phone1,
      conduitPhone2: phone2,
      conduitOccupation: occupation,
      conduitEmployer: employer,
      conduitFecId: committeeFecId,
    };
  },
  [actions.HANDLE_CHANGE_ELECTION_DETAILS]: (
    state,
    { data: { fieldName, value } },
  ) => ({
    ...state,
    [fieldName]: value,
  }),
  [actions.HANDLE_LOAN_CHANGE]: (state, action) => {
    return {
      ...state,
      loanId: action.data.loanId,
    };
  },
  [actions.HANDLE_CHANGE_SOURCE_TYPE]: (state, { data: { value } }) => {
    const currentSourceType = state.contactType;
    return {
      ...state,
      contactType: value,
      errors: {
        ...(currentSourceType === value
          ? state.errors
          : {
              ...state.errors,
              firstNameErrorMessage: '',
              lastNameErrorMessage: '',
              businessNameError: '',
            }),
      },
    };
  },
  [actions.HANDLE_CHANGE_CONDUIT_CONTACT_TYPE]: (
    state,
    { data: { value } },
  ) => {
    const currentConduitType = state.conduitContactType;
    return {
      ...state,
      conduitContactType: value,
      errors: {
        ...(currentConduitType === value
          ? state.errors
          : {
              ...state.errors,
              conduitFirstName: '',
              conduitLastName: '',
              conduitSuffix: '',
              conduitBusinessName: '',
              conduitContactType: '',
            }),
      },
    };
  },
  [actions.HANDLE_CHANGE_PAYMENT_TYPE]: (state, { data: { value } }) => {
    return {
      ...state,
      paymentType: value,
      checkNumber: '',
      cardLast4: '',
      cardType: 0,
    };
  },
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.HANDLE_COUPLED_CHANGE]: (state, { data: { fieldName1, fieldName2, value } }) => {
    return {
      ...state,
      [fieldName1]: value,
      [fieldName2]: value,
    };
  },
  [actions.HANDLE_FEDERAL_ELECTION_CHANGE]: (
    state,
    { data: { value, otherElectionType } },
  ) => ({
    ...state,
    election: value,
    otherElectionType,
  }),
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors: {
      ...state.errors,
      ...errors,
    },
  }),
  [actions.CLEAR_SELECTED_CONTACT]: state => ({
    ...state,
    ...clearContact(),
  }),
  [actions.CLEAR_SELECTED_CONDUIT_CONTACT]: state => ({
    ...state,
    ...clearConduitContact(),
  }),
  [actions.HANDLE_CHANGE_INCLUDE_CONDUIT_CONTACT]: (
    state,
    { data: { value } },
  ) => {
    if (!value) {
      return {
        ...state,
        includeConduitContact: value,
        ...clearConduitContact(),
      };
    }
    return {
      ...state,
      includeConduitContact: value,
    };
  },
  [actions.ON_LINKED_CONTRIBUTION_SELECTED]: (
    state,
    { data: { linkedContribution } },
  ) => ({
    ...state,
    linkedContribution,
  }),
  [actions.ON_PRESENTATION_MEMO_CONTRIBUTION_SELECTED]: (
    state,
    { data: { presentationOnlyMemoContribution } },
  ) => ({
    ...state,
    presentationOnlyMemoContribution,
  }),
  [actions.CLEAR_LINKED_CONTRIBUTION]: (
    state,
  ) => ({
    ...state,
    linkedContribution: null,
  }),
  [actions.CLEAR_PRESENTATION_ONLY_CONTRIBUTION]: (
    state,
  ) => ({
    ...state,
    presentationOnlyMemoContribution: null,
  }),
  [actions.SHOW_CONTINUE_UPDATE]: (
    state,
    { data: { addNew, continueEditMessageList } },
  ) => ({
    ...state,
    confirmContinueUpdateHidden: false,
    addNew,
    continueEditMessageList,
  }),
  [actions.HIDE_CONTINUE_UPDATE]: (
    state,
  ) => ({
    ...state,
    confirmContinueUpdateHidden: true,
    addNew: false,
  }),
};

export const addContributionReducer = createReducer(initialState, actionMap);
