import { createReducer } from '../../reducers/createReducer';
import { statuses } from '../../helpers/constants';
import { filterOutSystemTags, deserializeDate } from '../../helpers/util';
import { initialState as state, errors } from './contributionBaseInitialState';

const contributionTypeMap = {
  'In-Kind': 'In Kind',
  Monetary: 'Monetary',
  'Credit Received on Loan': 'Credit Received on Loan',
  'Common Source': 'Common Source',
};

export const initialState = {
  ...state,
  errors: {
    ...errors,
  },
};

export const clearContact = () => ({
  contactType: 0,
  selectedContact: null,
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  employer: '',
  occupation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  phone1: '',
  phone2: '',
  email: '',

  businessName: '',
  businessType: 0,
  contactName: '',

  committeeAffiliation: 0,
  commonSource: '',
  specific: '',

  committeeFecId: '',
});

export const clearConduitContact = () => ({
  conduitContactType: 0,
  selectedConduitContact: null,
  conduitContactId: '',
  conduitSalutation: 'none',
  conduitFirstName: '',
  conduitMiddleName: '',
  conduitLastName: '',
  conduitSuffix: '',
  conduitEmployer: '',
  conduitOccupation: '',
  conduitBusinessName: '',
  conduitBusinessType: 0,
  conduitContactName: '',
  conduitCommitteeAffiliation: 0,
  conduitEmail: '',
  conduitPhone1: '',
  conduitPhone2: '',
  conduitAddressLine1: '',
  conduitAddressLine2: '',
  conduitCity: '',
  conduitState: 0,
  conduitZipCode: '',
  conduitCounty: '',
});

export const actions = {
  GET_CONTRIBUTION_FOR_EDIT_SUCCESS: 'GET_CONTRIBUTION_FOR_EDIT_SUCCESS',
  CLEAR_SELECTED_CONTACT: 'CLEAR_SELECTED_CONTACT',
  CLEAR_SELECTED_CONDUIT_CONTACT: 'CLEAR_SELECTED_CONDUIT_CONTACT',
  ON_CONTACT_SELECTED: 'ON_CONTACT_SELECTED',
  ON_CONDUIT_CONTACT_SELECTED: 'ON_CONDUIT_CONTACT_SELECTED',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  HANDLE_COUPLED_CHANGE: 'HANDLE_COUPLED_CHANGE',
  HANDLE_CHANGE_PAYMENT_TYPE: 'HANDLE_CHANGE_PAYMENT_TYPE',
  HANDLE_CHANGE_SOURCE_TYPE: 'HANDLE_CHANGE_SOURCE_TYPE',
  HANDLE_CHANGE_CONDUIT_CONTACT_TYPE: 'HANDLE_CHANGE_CONDUIT_CONTACT_TYPE',
  HANDLE_CHANGE_ELECTION_DETAILS: 'HANDLE_CHANGE_ELECTION_DETAILS',
  HANDLE_CHANGE_FEDERAL_ELECTION: 'HANDLE_CHANGE_FEDERAL_ELECTION',
  HANDLE_LOAN_CHANGE: 'HANDLE_LOAN_CHANGE',
  SHOW_CONFIRM_DELETE_MODAL: 'SHOW_CONFIRM_DELETE_MODAL',
  HIDE_CONFIRM_DELETE_MODAL: 'HIDE_CONFIRM_DELETE_MODAL',
  SHOW_CONFIRM_LINKED_DELETE_MODAL: 'SHOW_CONFIRM_LINKED_DELETE_MODAL',
  HIDE_CONFIRM_LINKED_DELETE_MODAL: 'HIDE_CONFIRM_LINKED_DELETE_MODAL',
  SHOW_CONTINUE_UPDATE: 'SHOW_CONTINUE_UPDATE',
  HIDE_CONTINUE_UPDATE: 'HIDE_CONTINUE_UPDATE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  CLEAR_FIELDS_FOR_NEW: 'CLEAR_FIELDS_FOR_NEW',
  HANDLE_CHANGE_INCLUDE_CONDUIT_CONTACT:
    'HANDLE_CHANGE_INCLUDE_CONDUIT_CONTACT',
  ON_LINKED_CONTRIBUTION_SELECTED: 'ON_LINKED_CONTRIBUTION_SELECTED',
  ON_PRESENTATION_MEMO_CONTRIBUTION_SELECTED: 'ON_PRESENTATION_MEMO_CONTRIBUTION_SELECTED',
  CLEAR_LINKED_CONTRIBUTION: 'CLEAR_LINKED_CONTRIBUTION',
  CLEAR_PRESENTATION_ONLY_CONTRIBUTION: 'CLEAR_PRESENTATION_ONLY_CONTRIBUTION',
};

export const actionMap = {
  [actions.GET_CONTRIBUTION_FOR_EDIT_SUCCESS]: (
    state,
    { data: { contribution, params, isFederal, frCampaignType } },
  ) => {
    const { address = {}, conduit } = contribution;
    // It's possible to delete the contact associated with contribution
    let { contactId } = contribution;
    contactId = contactId || {};
    const deserializedDate = deserializeDate(contribution.receivedDate);
    const newState = {
      ...state,
      frCampaignType, // Used for validation in contribution form reducer
      electionYear:
        typeof contribution.electionYear === 'string'
          ? parseInt(contribution.electionYear, 10)
          : contribution.electionYear,
      election: contribution.electionCycle,
      promptingRecordId: contribution._id,
      transactionIdNumber: contribution.transactionIdNumber,
      otherElectionType: contribution.electionCycleOtherDescription || '',
      contributionType: contributionTypeMap[contribution.contributionType] || 0,
      contributionCategory: contribution.contributionCategory,
      linkedTransactionId: contribution.linkedTransactionId,
      presentationOnlyMemoContributionId: contribution?.presentationOnlyMemo?.originalContributionId,
      budgetCategoryId: contribution.budgetCategoryId || '',
      paymentType: contribution.paymentType || 0,
      contactType: contribution.contactType || 0,
      status: statuses.SUCCESS,
      referenceId: contribution.paymentReferenceId || '',
      sourceCode: contribution.sourceCode || '',
      isLoanRepayment: contribution.isLoanRepayment || false,
      loanId: contribution.loanId,
      depositId: contribution.depositId || '',
      description: contribution.description || '',
      amount: contribution.amount || '',
      checkNumber: contribution.checkNumber || '',
      tags: filterOutSystemTags(contribution.tags || ''),
      forceItemization: contribution.forceItemize,
      isNotReportable: contribution.isNotReportable,
      frozenFecFormType: contribution.frozenFecFormType,
      isFrozenFecFormType: contribution.isFrozenFecFormType,
      isItemFiled: contribution.isItemFiled || false,
      reconciliationId: contribution.reconciliationId || false,
      reconciled: contribution.reconciled || false,
      cardLast4: contribution.creditCardLast4 || '',
      cardType: contribution.creditCardType || 0,
      cardholderName: contribution.cardholderName || '',
      dateReceived: deserializedDate,
      isCandidate: contribution.isCandidate || false,
      isTransfer: contribution.isTransfer || false,
      reportIdRedirect: params.reportId,
      redirect: params.redirect,
      verifyReport: params.verifyReport,
      tbd: Boolean(params.tbd),
      fec: Boolean(params.fec),
      candidateOffice: contribution.candidateOffice || '',
      candidateState: contribution.candidateState || 0,
      candidateDistrict: contribution.candidateDistrict || '',
      candidateFecId: contactId?.candidateFecId || contribution.candidateFecId || '',
      candidateFirstName: contribution.candidateFirstName || '',
      candidateMiddleName: contribution.candidateMiddleName || '',
      candidateLastName: contribution.candidateLastName || '',
      committeeFecId: contactId?.committeeFecId || contribution?.committeeFecId || '',
      addressLine1:
        address.addressLine1 || contactId?.address?.addressLine1 || '',
      addressLine2:
        address.addressLine2 || contactId?.address?.addressLine2 || '',
      city: address.city || contactId?.address?.city || '',
      state: address.state || contactId?.address?.state || 0,
      zipCode: address.zipCode || contactId?.address?.zipCode || '',
      county: address.county || contactId?.address?.county || '',

      selectedContact: { ...contactId },

      salutation: contribution.salutation || contactId?.salutation || 'none',
      firstName: contribution.firstName || contactId?.firstName || '',
      middleName: contribution.middleName || contactId?.middleName || '',
      lastName: contribution.lastName || contactId?.lastName || '',
      suffix: contribution.suffix || contactId?.suffix || '',
      occupation: contribution.occupation || contactId?.occupation || '',
      employer: contribution.employer || contactId?.employer || '',

      phone1: contribution.phone1 || contactId?.phone1 || '',
      phone2: contribution.phone2 || contactId?.phone2 || '',
      email: contribution.email || contactId?.email || '',

      businessName:
        contribution.businessName ||
        contactId?.businessName ||
        '',
      businessType: contribution.businessType || contactId?.businessType || 0,
      contactName: contribution.contactName || contactId?.contactName || '',

      committeeAffiliation:
        contribution.committeeAffiliation ||
        contactId?.committeeAffiliation ||
        0,

      commonSource: contribution.commonSource || contactId?.commonSource || '',
      specific: contribution.specific || contactId?.specific || '',

      isRedesignation: contribution.isRedesignation || false,
      memoCode: contribution.memoCode || false,
      memoText: contribution.memoText || '',
      includeConduitContact: Boolean(conduit?.contactId),
      linkedTo: contribution.linkedTo || null,

      mailCode: contribution.mailCode,
      envelopeCode: contribution.envelopeCode,
      externalDonorId: contribution.externalDonorId,
      selectedConduitContact: conduit?.contactId ? conduit : null,
      ...(isFederal &&
        conduit && {
          conduitContactType: conduit.contactType,
          conduitContactId: conduit.contactId || null,
          conduitSalutation: conduit.salutation || 'none',
          conduitFirstName: conduit.firstName || '',
          conduitMiddleName: conduit.middleName || '',
          conduitLastName: conduit.lastName || '',
          conduitSuffix: conduit.suffix || '',
          conduitEmployer: conduit.employer || '',
          conduitOccupation: conduit.occupation || '',
          conduitEmail: conduit.email || '',
          conduitPhone1: conduit.phone1 || '',
          conduitPhone2: conduit.phone2 || '',
          conduitBusinessName: conduit.businessName || '',
          conduitBusinessType: conduit.businessType || 0,
          conduitContactName: conduit.contactName || '',
          conduitCommitteeAffiliation: conduit.committeeAffiliation || 0,
          conduitCommonSource: conduit.commonSource || '',
          conduitSpecific: conduit.specific || '',
          conduitFecId: conduit.committeeFecId || '',
          conduitAddressLine1: conduit.address?.addressLine1 || '',
          conduitAddressLine2: conduit.address?.addressLine2 || '',
          conduitCity: conduit.address?.city || '',
          conduitState: conduit.address?.state || 0,
          conduitZipCode: conduit.address?.zipCode || '',
          conduitCounty: conduit.address?.county,
        }),
    };
    return newState;
  },
  [actions.CLEAR_SELECTED_CONTACT]: state => ({
    ...state,
    ...clearContact(),
  }),
  [actions.CLEAR_SELECTED_CONDUIT_CONTACT]: state => ({
    ...state,
    ...clearConduitContact(),
  }),
  [actions.ON_CONTACT_SELECTED]: (state, { data: { contact } }) => {
    const { address = {} } = contact;
    return {
      ...state,
      contactType: contact.contactType,
      selectedContact: { ...contact },
      salutation: contact.salutation || 'none',
      firstName: contact.firstName || '',
      middleName: contact.middleName || '',
      lastName: contact.lastName || '',
      suffix: contact.suffix || '',
      employer: contact.employer || '',
      occupation: contact.occupation || '',
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      city: address.city || '',
      state: address.state || 0,
      zipCode: address.zipCode || '',
      county: address.county || '',
      phone1: contact.phone1 || '',
      phone2: contact.phone2 || '',
      email: contact.email || '',

      businessName: contact.businessName || '',
      businessType: contact.businessType || 0,
      contactName: contact.contactName || '',

      committeeAffiliation: contact.committeeAffiliation || 0,
      committeeFecId: contact.committeeFecId || '',
      candidateFecId: contact.candidateFecId || '',
      candidateFirstName: contact.candidateFirstName || '',
      candidateMiddleName: contact.candidateMiddleName || '',
      candidateLastName: contact.candidateLastName || '',
      candidateDistrict: contact.candidateDistrict || '',
      candidateOffice: contact.candidateOffice || '',
      candidateState: contact.candidateState || 0,

      commonSource: contact.commonSource || '',
      specific: contact.specific || '',
    };
  },
  [actions.ON_CONDUIT_CONTACT_SELECTED]: (state, { data: { contact } }) => {
    const { address = {} } = contact;
    return {
      ...state,
      conduitContactType: contact.contactType,
      selectedConduitContact: { ...contact },
      conduitContactId: contact._id,
      conduitSalutation: contact.salutation || 'none',
      conduitFirstName: contact.firstName || '',
      conduitMiddleName: contact.middleName || '',
      conduitLastName: contact.lastName || '',
      conduitSuffix: contact.suffix || '',
      conduitBusinessName: contact.businessName || '',
      conduitBusinessType: contact.businessType || 0,
      conduitContactName: contact.contactName || '',
      conduitCommitteeAffiliation: contact.committeeAffiliation || 0,
      conduitEmployer: contact.employer || '',
      conduitOccupation: contact.occupation || '',
      conduitEmail: contact.email || '',
      conduitPhone1: contact.phone1 || '',
      conduitPhone2: contact.phone2 || '',
      conduitAddressLine1: address.addressLine1 || '',
      conduitAddressLine2: address.addressLine2 || '',
      conduitCity: address.city || '',
      conduitState: address.state || 0,
      conduitZipCode: address.zipCode || '',
      conduitCounty: address.county || '',
      conduitFecId: contact.committeeFecId || '',
    };
  },
  [actions.HANDLE_CHANGE_ELECTION_DETAILS]: (
    state,
    { data: { fieldName, value } },
  ) => {
    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.HANDLE_CHANGE_SOURCE_TYPE]: (state, { data: { value } }) => {
    const currentSourceType = state.contactType;
    return {
      ...state,
      contactType: value,
      errors: {
        ...(currentSourceType === value
          ? state.errors
          : {
              ...state.errors,
              firstNameErrorMessage: '',
              lastNameErrorMessage: '',
              businessNameError: '',
            }),
      },
    };
  },
  [actions.HANDLE_CHANGE_CONDUIT_CONTACT_TYPE]: (
    state,
    { data: { value } },
  ) => {
    const currentConduitType = state.conduitContactType;
    return {
      ...state,
      conduitContactType: value,
      errors: {
        ...(currentConduitType === value
          ? state.errors
          : {
              ...state.errors,
              conduitFirstName: '',
              conduitLastName: '',
              conduitSuffix: '',
              conduitBusinessName: '',
              conduitContactType: '',
            }),
      },
    };
  },
  [actions.HANDLE_CHANGE_PAYMENT_TYPE]: (state, { data: { value } }) => {
    return {
      ...state,
      paymentType: value,
      checkNumber: '',
      cardLast4: '',
      cardType: 0,
    };
  },
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.HANDLE_COUPLED_CHANGE]: (state, { data: { fieldName1, fieldName2, value } }) => {
    return {
      ...state,
      [fieldName1]: value,
      [fieldName2]: value,
    };
  },
  [actions.HANDLE_CHANGE_FEDERAL_ELECTION]: (state, { data: { value } }) => ({
    ...state,
    election: value,
    otherElectionType: ['Other', 'Special'].includes(value)
      ? state.otherElectionType
      : '',
  }),
  [actions.HANDLE_LOAN_CHANGE]: (state, action) => {
    return {
      ...state,
      loanId: action.data.loanId,
    };
  },
  [actions.HIDE_CONFIRM_LINKED_DELETE_MODAL]: state => ({
    ...state,
    confirmLinkedContributionDeleteHidden: true,
  }),
  [actions.SHOW_CONFIRM_LINKED_DELETE_MODAL]: state => ({
    ...state,
    confirmLinkedContributionDeleteHidden: false,
  }),
  [actions.HIDE_CONFIRM_DELETE_MODAL]: state => ({
    ...state,
    confirmDeleteHidden: true,
  }),
  [actions.SHOW_CONFIRM_DELETE_MODAL]: state => ({
    ...state,
    confirmDeleteHidden: false,
  }),
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors: {
      ...state.errors,
      ...errors,
    },
  }),
  [actions.CLEAR_FIELDS_FOR_NEW]: state => ({
    ...initialState,
    election: state.election,
    electionYear: state.electionYear,
    contributionType: state.contributionType,
    fec: state.fec,
    reportIdRedirect: state.reportIdRedirect,
    redirect: state.redirect,
    tbd: state.tbd,
  }),
  [actions.HANDLE_CHANGE_INCLUDE_CONDUIT_CONTACT]: (
    state,
    { data: { value } },
  ) => {
    if (!value) {
      return {
        ...state,
        includeConduitContact: value,
        ...clearConduitContact(),
      };
    }
    return {
      ...state,
      includeConduitContact: value,
    };
  },
  [actions.ON_LINKED_CONTRIBUTION_SELECTED]: (
    state,
    { data: { linkedContribution } },
  ) => ({
    ...state,
    linkedContribution,
  }),
  [actions.ON_PRESENTATION_MEMO_CONTRIBUTION_SELECTED]: (
    state,
    { data: { presentationOnlyMemoContribution } },
  ) => ({
    ...state,
    presentationOnlyMemoContribution,
  }),
  [actions.CLEAR_LINKED_CONTRIBUTION]: (
    state,
  ) => ({
    ...state,
    linkedContribution: null,
  }),
  [actions.CLEAR_PRESENTATION_ONLY_CONTRIBUTION]: (
    state,
  ) => ({
    ...state,
    presentationOnlyMemoContribution: null,
  }),
  [actions.SHOW_CONTINUE_UPDATE]: (
    state,
    { data: { addNew, continueEditMessageList } },
  ) => ({
    ...state,
    confirmContinueUpdateHidden: false,
    addNew,
    continueEditMessageList,
  }),
  [actions.HIDE_CONTINUE_UPDATE]: (
    state,
  ) => ({
    ...state,
    confirmContinueUpdateHidden: true,
    addNew: false,
  }),
};

export const editContributionReducer = createReducer(initialState, actionMap);
