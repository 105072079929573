import { createReducer } from '../../reducers/createReducer';
import { statuses } from '../../helpers/constants';
import { deserializeRecipient } from '../../helpers/endRecipientHelper';
import {
  expenditureTypeMap,
  getContactInformation,
} from '../../helpers/expenditureHelpers';
import { filterOutSystemTags, deserializeDate } from '../../helpers/util';
import {
  expenditureBaseInitialState,
  errors,
} from './expenditureBaseInitialState';

const { addRecipientDialogHidden, ...rest } = expenditureBaseInitialState;

export const initialState = {
  ...rest,
  status: statuses.NOT_STARTED,
  redirect: '',
  verifyReport: false,
  fec: false,
  confirmDeleteHidden: true,
  editRecipientDialogHidden: true,
  bulkRecipientDialogHidden: true,
  errors: { ...errors },
};

export const actions = {
  GET_EXPENDITURE_FOR_EDIT: 'GET_EXPENDITURE_FOR_EDIT',
  GET_EXPENDITURE_FOR_EDIT_FAILURE: 'GET_EXPENDITURE_FOR_EDIT_FAILURE',
  GET_EXPENDITURE_FOR_EDIT_SUCCESS: 'GET_EXPENDITURE_FOR_EDIT_SUCCESS',
  CLEAR_SELECTED_CONTACT: 'CLEAR_SELECTED_CONTACT',
  ON_CONTACT_SELECTED: 'ON_CONTACT_SELECTED',
  ON_CONDUIT_SELECTED: 'ON_CONDUIT_SELECTED',
  ON_SUPPORT_OPPOSE_SELECTED: 'ON_SUPPORT_OPPOSE_SELECTED',
  HANDLE_CHANGE_DATE_PAID: 'HANDLE_CHANGE_DATE_PAID',
  HANDLE_FEDERAL_ELECTION_CHANGE: 'HANDLE_FEDERAL_ELECTION_CHANGE',
  HANDLE_CONTRIBUTION_FEDERAL_ELECTION_CHANGE: 'HANDLE_CONTRIBUTION_FEDERAL_ELECTION_CHANGE',
  HANDLE_REFUND_CONTRIBUTION_CHANGE: 'HANDLE_REFUND_CONTRIBUTION_CHANGE',
  HANDLE_LOAN_CHANGE: 'HANDLE_LOAN_CHANGE',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  HANDLE_COUPLED_CHANGE: 'HANDLE_COUPLED_CHANGE',
  OPEN_CONFIRM_DELETE_MODAL: 'OPEN_CONFIRM_DELETE_MODAL',
  CLOSE_CONFIRM_DELETE_MODAL: 'CLOSE_CONFIRM_DELETE_MODAL',
  CLOSE_END_RECIPIENT_DIALOG: 'CLOSE_END_RECIPIENT_DIALOG',
  CLOSE_BULK_END_RECIPIENT_DIALOG: 'CLOSE_BULK_END_RECIPIENT_DIALOG',
  SHOW_CONTINUE_UPDATE: 'SHOW_CONTINUE_UPDATE',
  HIDE_CONTINUE_UPDATE: 'HIDE_CONTINUE_UPDATE',
  SELECT_RECIPIENT: 'SELECT_RECIPIENT',
  RECIPIENT_ADDED: 'RECIPIENT_ADDED',
  EDIT_RECIPIENT: 'EDIT_RECIPIENT',
  DELETE_RECIPIENT: 'DELETE_RECIPIENT',
  ON_RECIPIENT_SAVE: 'ON_RECIPIENT_SAVE',
  ON_BULK_RECIPIENT_SAVE: 'ON_BULK_RECIPIENT_SAVE',
  RESET_SELECTED_CONTACT_INFO: 'RESET_SELECTED_CONTACT_INFO',
  RESET_SELECTED_SUPPORT_OPPOSE_INFO: 'RESET_SELECTED_SUPPORT_OPPOSE_INFO',
  RESET_REFUND_CONTRIBUTION_INFO: 'RESET_REFUND_CONTRIBUTION_INFO',
  CLEAR_RECIPIENT_INFO: 'CLEAR_RECIPIENT_INFO',
  RESET_SELECTED_CONDUIT_INFO: 'RESET_SELECTED_CONDUIT_INFO',
};

const actionMap = {
  [actions.GET_EXPENDITURE_FOR_EDIT]: state => {
    return {
      ...state,
      status: statuses.PROCESSING,
    };
  },
  [actions.GET_EXPENDITURE_FOR_EDIT_SUCCESS]: (state, action) => {
    const { expenditure, params, frCampaignType } = action.data;
    const selectedContact = getContactInformation(state, expenditure);
    const {
      reportId = null,
      redirect = null,
      verifyReport = false,
      fec = false,
    } = params;

    const { address = {}, contactId, ...rest } = expenditure;
    return {
      ...state,
      ...rest,
      ...address,
      frCampaignType,
      transactionIdNumber: expenditure.transactionIdNumber,
      allocatedActivityOrEventType: expenditure.allocatedActivityOrEventType,
      contributionElectionYear:
        typeof expenditure.contributionElectionYear === 'string'
          ? parseInt(expenditure.contributionElectionYear, 10)
          : expenditure.contributionElectionYear,
      activityOrEventIdentifier: expenditure.activityOrEventIdentifier,
      federalShare: expenditure.federalShare,
      nonFederalShare: expenditure.nonFederalShare,
      state: address.state || 0,
      electionYear: parseInt(expenditure.electionYear, 10),
      budgetCategoryId: expenditure.budgetCategoryId || '',
      election: expenditure.electionCycle || 0,
      otherElectionType: expenditure.electionCycleOtherDescription,
      expenditureType: expenditureTypeMap[expenditure.expenditureType],
      disbursementCategory: expenditure.disbursementCategory,
      paymentType: expenditure.paymentType,
      contactType: expenditure.contactType,
      isItemFiled: expenditure.isItemFiled || false,
      reconciliationId: expenditure.reconciliationId || '',
      depositId: expenditure.depositId || '',
      businessName: !['IND', 'Individual', 'CAN'].includes(
        expenditure.contactType,
      )
        ? expenditure.contactId.businessName
        : '',
      datePaid: deserializeDate(expenditure.expenditureDate),
      status: statuses.SUCCESS,
      referenceId: expenditure.paymentReferenceId || '',
      tags: filterOutSystemTags(expenditure.tags),
      forceItemization: expenditure.forceItemize || false,
      cardLast4: expenditure.creditCardLast4 || '',
      cardType: expenditure.creditCardType || 0,
      purpose: expenditure.purpose || '',
      endRecipients: expenditure.endRecipients.map(er => ({
        ...deserializeRecipient(er),
        committeeFecId: expenditure.committeeFecId,
      })).map(er => ({ ...er, isDirty: false })),
      selectedContact,
      reportIdRedirect: reportId,
      redirect,
      verifyReport,
      fec,
      isContributionProcessingFee: expenditure.isContributionProcessingFee,
      completingFirstName: expenditure.completingFirstName,
      completingLastName: expenditure.completingLastName,
      completingDateSigned: deserializeDate(expenditure.completingDateSigned),
      disseminationDate: deserializeDate(expenditure.disseminationDate),
      supportOpposeCode: expenditure.supportOpposeCode,
    };
  },
  [actions.GET_EXPENDITURE_FOR_EDIT_FAILURE]: state => ({
    ...state,
    status: statuses.ERROR,
  }),
  [actions.CLEAR_SELECTED_CONTACT]: state => ({
    ...state,
    selectedContact: null,
  }),
  [actions.CLEAR_RECIPIENT_INFO]: state => {
    return {
      ...state,
      selectedContact: null,
      contactType: ['Credit Card'].includes(state.expenditureType)
        ? 'Business'
        : 0,
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      employer: '',
      occupation: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      businessName: '',
      businessType: 0,
      contactName: '',
      cardholderName: '',
      cardholderEmployer: '',
      cardholderOccupation: '',
      committeeAffiliation: 0,
    };
  },
  [actions.RESET_SELECTED_CONTACT_INFO]: state => {
    if (state.selectedContact) {
      return {
        ...state,
        salutation: state.selectedContact.salutation || 'none',
        firstName: state.selectedContact.firstName || '',
        middleName: state.selectedContact.middleName || '',
        lastName: state.selectedContact.lastName || '',
        suffix: state.selectedContact.suffix || '',
        employer: state.selectedContact.employer || '',
        occupation: state.selectedContact.occupation || '',
        addressLine1: state.selectedContact.address.addressLine1 || '',
        addressLine2: state.selectedContact.address.addressLine2 || '',
        city: state.selectedContact.address.city || '',
        state: state.selectedContact.address.state || 0,
        zipCode: state.selectedContact.address.zipCode || '',
        county: state.selectedContact.address.county || '',
        phone1: state.selectedContact.phone1 || '',
        phone2: state.selectedContact.phone2 || '',
        email: state.selectedContact.email || '',
        businessName: state.selectedContact.businessName || '',
        businessType: state.selectedContact.businessType || 0,
        contactName: state.selectedContact.contactName || '',
        committeeAffiliation: state.selectedContact.committeeAffiliation || 0,
        cardholderName: state.selectedContact.cardholderName || '',
        cardholderOccupation: state.selectedContact.cardholderOccupation || '',
        cardholderEmployer: state.selectedContact.cardholderEmployer || '',
      };
    }

    return {
      ...state,
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      employer: '',
      occupation: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      businessName: '',
      businessType: 0,
      contactName: '',
      committeeAffiliation: 0,
      cardholderName: '',
      cardholderOccupation: '',
      cardholderEmployer: '',
    };
  },
  [actions.RESET_SELECTED_CONDUIT_INFO]: state => {
    return {
      ...state,
      conduit: null,
    };
  },
  [actions.RESET_SELECTED_SUPPORT_OPPOSE_INFO]: state => {
    return {
      ...state,
      supportOppose: null,
      contributionOfficeSought: 0,
      contributionOfficeState: 0,
      contributionDistrict: '',
    };
  },
  [actions.RESET_REFUND_CONTRIBUTION_INFO]: state => {
    return {
      ...state,
      refundContributionId: '0',
    };
  },
  [actions.ON_CONTACT_SELECTED]: (state, action) => {
    const { selectedContact } = action.data;
    const {
      address: contactAddress,
      _id,
      contactId,
      salutation,
      businessType,
      committeeAffiliation,
      tags,
      ...rest
    } = selectedContact;
    return {
      ...state,
      selectedContact: {
        ...selectedContact,
        tags: filterOutSystemTags(tags),
        salutation: salutation || 'none',
        businessType: businessType || 0,
        committeeAffiliation: committeeAffiliation || 0,
        phone1: selectedContact.phone1 || '',
        phone2: selectedContact.phone2 || '',
        address: {
          ...selectedContact.address,
          state: (selectedContact.address || {}).state || 0,
        },
        contactType: selectedContact.contactType,
      },
      contactType: selectedContact.contactType,
      ...contactAddress,
      tags: filterOutSystemTags(tags),
      ...rest,
      salutation: salutation || 'none',
      businessType: businessType || 0,
      committeeAffiliation: committeeAffiliation || 0,
      phone1: selectedContact.phone1 || '',
      phone2: selectedContact.phone2 || '',
      purpose: state.purpose || selectedContact.purpose || '',
      refundContributionId: '0',
    };
  },
  [actions.ON_CONDUIT_SELECTED]: (state, action) => {
    const { selectedConduitContact } = action.data;
    return {
      ...state,
      conduit: { ...selectedConduitContact },
    };
  },
  [actions.ON_SUPPORT_OPPOSE_SELECTED]: (state, action) => {
    const { selectedSupportOpposeContact } = action.data;
    return {
      ...state,
      supportOppose: { ...selectedSupportOpposeContact },
      contributionOfficeSought: selectedSupportOpposeContact.candidateOffice,
      contributionOfficeState: selectedSupportOpposeContact.candidateState,
      contributionDistrict: selectedSupportOpposeContact.candidateDistrict,
    };
  },
  [actions.HANDLE_COUPLED_CHANGE]: (state, { data: { fieldName1, fieldName2, value } }) => {
    return {
      ...state,
      [fieldName1]: value,
      [fieldName2]: value,
    };
  },
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'contactType') {
      const currentRecipientType = state.contactType;
      return {
        ...state,
        [fieldName]: value,
        errors: {
          ...(currentRecipientType === value
            ? state.errors
            : {
                ...state.errors,
                businessNameError: '',
                firstNameErrorMessage: '',
                lastNameErrorMessage: '',
                creditCardCompanyError: '',
              }),
        },
      };
    }

    if (fieldName === 'paymentType') {
      return {
        ...state,
        [fieldName]: value,
        checkNumber: '',
        cardType: 0,
        cardLast4: '',
      };
    }
    if (fieldName === 'election' || fieldName === 'electionYear') {
      return {
        ...state,
        refundContributionId: '0',
        [fieldName]: value,
      };
    }
    if (fieldName === 'disbursementCategory' && value !== '010') {
      return {
        ...state,
        refundContributionId: '0',
        [fieldName]: value,
      };
    }
    if (fieldName === 'expenditureType') {
      return {
        ...state,
        isAllocated: false,
        [fieldName]: value,
      };
    }
    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.HANDLE_CHANGE_DATE_PAID]: (state, action) => {
    const { datePaid } = action.data;
    return {
      ...state,
      datePaid,
      errors: {
        ...state.errors,
        datePaidErrorMessage:
          datePaid &&
          datePaid.getFullYear &&
          datePaid.getFullYear().toString().length !== 4
            ? 'Invalid date'
            : '',
      },
    };
  },
  [actions.HANDLE_FEDERAL_ELECTION_CHANGE]: (state, action) => ({
    ...state,
    election: action.data.election || 0,
    otherElectionType: action.data.otherElectionType,
    refundContributionId: '0',
  }),
  [actions.HANDLE_CONTRIBUTION_FEDERAL_ELECTION_CHANGE]: (state, action) => ({
    ...state,
    contributionElection: action.data.contributionElection || 0,
    contributionOtherElectionType: action.data.contributionOtherElectionType,
    election: action.data.contributionElection || 0,
    otherElectionType: action.data.contributionOtherElectionType,
  }),
  [actions.HANDLE_REFUND_CONTRIBUTION_CHANGE]: (state, action) => ({
    ...state,
    refundContributionId: action.data.refundContributionId,
  }),
  [actions.HANDLE_LOAN_CHANGE]: (state, action) => ({
    ...state,
    loanId: action.data.loanId,
  }),
  [actions.OPEN_CONFIRM_DELETE_MODAL]: state => ({
    ...state,
    confirmDeleteHidden: false,
  }),
  [actions.CLOSE_CONFIRM_DELETE_MODAL]: state => ({
    ...state,
    confirmDeleteHidden: true,
  }),
  [actions.CLOSE_END_RECIPIENT_DIALOG]: state => ({
    ...state,
    editRecipientDialogHidden: true,
    selectedRecipient: null,
  }),
  [actions.CLOSE_BULK_END_RECIPIENT_DIALOG]: state => ({
    ...state,
    bulkRecipientDialogHidden: true,
    selectedRecipient: null,
  }),
  [actions.SELECT_RECIPIENT]: (state, action) => {
    return {
      ...state,
      selectedRecipient: state.endRecipients.find(
        er => er._id === action.data.recipientId,
      ),
      editRecipientDialogHidden: false,
    };
  },
  [actions.RECIPIENT_ADDED]: (state, action) => {
    const { expenditure } = action.data;
    let { endRecipients } = expenditure;
    endRecipients = (endRecipients || []).map(deserializeRecipient).map(e => ({ ...e, isDirty: false }));
    return {
      ...state,
      ...expenditure,
      electionYear: parseInt(expenditure.electionYear, 10),
      election: expenditure.electionCycle || 0,
      expenditureType: expenditureTypeMap[expenditure.expenditureType],
      paymentType: expenditure.paymentType,
      contactType: expenditure.contactType,
      datePaid: deserializeDate(expenditure.expenditureDate),
      status: statuses.SUCCESS,
      referenceId: expenditure.paymentReferenceId || '',
      tags: filterOutSystemTags(expenditure.tags),
      forceItemization: expenditure.forceItemize || false,
      cardLast4: expenditure.creditCardLast4 || '',
      cardType: expenditure.creditCardType || 0,
      purpose: expenditure.purpose || '',
      endRecipients,
    };
  },
  [actions.EDIT_RECIPIENT]: (state, action) => {
    const { updatedRecipient } = action.data;
    const isIndividualType = ['Individual', 'IND', 'CAN'].includes(
      updatedRecipient.contactType,
    );
    return {
      ...state,
      endRecipients: state.endRecipients.map(er => {
        if (er._id === updatedRecipient._id) {
          er = {
            ...er,
            ...updatedRecipient,
            isDirty: true,
            firstName: isIndividualType
              ? updatedRecipient.firstName
              : undefined,
            middleName: isIndividualType
              ? updatedRecipient.middleName
              : undefined,
            lastName: isIndividualType ? updatedRecipient.lastName : undefined,
            suffix: isIndividualType ? updatedRecipient.suffix : undefined,
            salutation: isIndividualType ? updatedRecipient.salutation : 'none',
            occupation: isIndividualType
              ? updatedRecipient.occupation
              : undefined,
            employer: isIndividualType ? updatedRecipient.employer : undefined,
            displayName: isIndividualType
              ? `${updatedRecipient.lastName}, ${updatedRecipient.firstName}`
              : updatedRecipient.businessName,
            businessName: isIndividualType
              ? undefined
              : updatedRecipient.businessName,
            contactName: isIndividualType
              ? undefined
              : updatedRecipient.contactName,
            businessType: isIndividualType ? 0 : updatedRecipient.businessType,
          };
        }
        return er;
      }),
      selectedRecipient: null,
    };
  },
  [actions.DELETE_RECIPIENT]: (state, action) => {
    return {
      ...state,
      endRecipients: state.endRecipients.map(er => {
        if (er._id === action.data.recipientId) {
          er.isRemoved = true;
        }
        return er;
      }),
    };
  },
  [actions.FORM_ERRORS]: (state, action) => {
    return {
      ...state,
      errors: action.data.errors,
    };
  },
  [actions.ON_RECIPIENT_SAVE]: state => {
    return {
      ...state,
      editRecipientDialogHidden: false,
    };
  },
  [actions.ON_BULK_RECIPIENT_SAVE]: state => {
    return {
      ...state,
      bulkRecipientDialogHidden: false,
    };
  },
  [actions.SHOW_CONTINUE_UPDATE]: (
    state,
    { data: { addNew, continueEditMessageList } },
  ) => ({
    ...state,
    confirmContinueUpdateHidden: false,
    addNew,
    continueEditMessageList,
  }),
  [actions.HIDE_CONTINUE_UPDATE]: (
    state,
  ) => ({
    ...state,
    confirmContinueUpdateHidden: true,
    addNew: false,
  }),
};

export const editExpenditureReducer = createReducer(initialState, actionMap);
