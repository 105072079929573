import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
  PrimaryButton,
  TextField,
} from 'office-ui-fabric-react';
import { validate } from './DebtDialogValidations';
import { ContactPicker } from '../Pickers';
import { useContactSearch } from '../../hooks/useContactSearch';
import { Grid, GridRow, Column, MoneyField } from '../common';

const initialState = {
  contactId: undefined,
  contactType: 0,
  businessName: '',
  prefix: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  employer: '',
  occupation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  phone1: '',
  phone2: '',
  email: '',
  purpose: '',
  periodBeginningBalance: '',
  incurredAmount: '',
  paymentAmount: '',
  periodEndingBalance: '',
};

const DebtDialog = ({
  dialogHidden,
  closeDialog,
  debtToEdit,
  updateDebt,
  addDebt,
  electionYear,
  session,
}) => {
  const [state, setState] = useState({ ...initialState });
  const [selectedContact, setSelectedContact] = useState(null);
  const [errors, setErrors] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    null,
    electionYear,
    state.contactType || '',
    null,
  );

  const onResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (debtToEdit !== null) {
      const debt = { ...debtToEdit };
      if (debt.contactId) {
        setSelectedContact({
          contactType: debt.contactType,
          businessName: debt.businessName || '',
          prefix: debt.prefix || 'none',
          firstName: debt.firstName || '',
          middleName: debt.middleName || '',
          lastName: debt.lastName || '',
          suffix: debt.suffix || '',
          occupation: debt.occupation || '',
          employer: debt.employer || '',
          phone1: debt.phone1 || '',
          phone2: debt.phone2 || '',
          email: debt.email || '',
          address: {
            addressLine1: debt.address ? debt.address.addressLine1 : '',
            addressLine2: debt.address ? debt.address.addressLine2 : '',
            city: debt.address ? debt.address.city : '',
            state: debt.address ? debt.address.state : '',
            zipCode: debt.address ? debt.address.zipCode : '',
          },
        });
      }
      setState({
        contactId: debt.contactId || undefined,
        contactType: debt.contactType,
        businessName: debt.businessName || '',
        prefix: debt.prefix || 'none',
        firstName: debt.firstName || '',
        middleName: debt.middleName || '',
        lastName: debt.lastName || '',
        suffix: debt.suffix || '',
        employer: debt.employer || '',
        occupation: debt.occupation || '',
        addressLine1: debt.address ? debt.address.addressLine1 : '',
        addressLine2: debt.address ? debt.address.addressLine2 : '',
        city: debt.address ? debt.address.city : '',
        state: debt.address ? debt.address.state : '',
        zipCode: debt.address ? debt.address.zipCode : '',
        purpose: debt.purpose || '',
        periodBeginningBalance: debt.periodBeginningBalance,
        incurredAmount: debt.incurredAmount,
        paymentAmount: debt.paymentAmount,
        periodEndingBalance: debt.periodEndingBalance,
        phone1: debt.phone1 || '',
        phone2: debt.phone2 || '',
        email: debt.email || '',
      });
    }
  }, [debtToEdit]);

  const handleChange = fieldName => (e, value) => {
    setState({
      ...state,
      [fieldName]: value.key !== undefined ? value.key : value,
    });

    if (fieldName === 'contactType') {
      if (state.contactType !== value.key) {
        setErrors({});
      }
    }
  };

  const onCloseDialog = () => {
    closeDialog();
    setState({ ...initialState });
    setSelectedContact(null);
    setErrors({});
  };

  const onSave = () => {
    const validationErrors = validate(state, selectedContact);
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      if (debtToEdit) {
        updateDebt(state);
      } else {
        addDebt(state);
      }

      onCloseDialog();
    }
  };

  const onItemSelected = contact => {
    setState({
      ...state,
      contactId: contact._id || undefined,
      businessName: !['IND', 'CAN'].includes(contact.contactType)
        ? contact.businessName || contact.businessName
        : '',
      prefix: contact.salutation || 'none',
      salutation: contact.salutation,
      firstName: contact.firstName || '',
      middleName: contact.middleName || '',
      lastName: contact.lastName || '',
      suffix: contact.suffix || '',
      occupation: contact.occupation || '',
      employer: contact.employer || '',
      addressLine1: contact.address ? contact.address.addressLine1 : '',
      addressLine2: contact.address ? contact.address.addressLine2 : '',
      city: contact.address ? contact.address.city : '',
      state: contact.address ? contact.address.state : '',
      zipCode: contact.address ? contact.address.zipCode : '',
      contactType: contact.contactType,
    });
    setSelectedContact(contact);
    return null;
  };

  const clearSelectedContact = () => {
    setSelectedContact(null);
    setState({ ...initialState });
  };

  return (
    <Dialog
      minWidth={screenWidth < 910 ? '95%' : '50%'}
      maxMidth={screenWidth < 910 ? '95%' : 775}
      hidden={dialogHidden}
      onDismiss={onCloseDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: `${debtToEdit ? 'Edit' : 'Add'} Debt`,
        subText: '',
      }}
    >
      <ContactPicker
        selectedContact={selectedContact}
        session={session}
        clearSelectedContact={clearSelectedContact}
        onResolveSuggestions={onResolveSuggestions}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        onItemSelected={onItemSelected}
        contactType={{
          label: 'Contact Type',
          field: 'contactType',
          required: true,
          errorMessage: errors.contactTypeError,
          value: state.contactType,
          show: true,
        }}
        businessName={{
          label: 'Organization Name',
          field: 'businessName',
          required: false,
          value: state.businessName,
          show: true,
        }}
        salutation={{
          label: 'Prefix',
          value: state.prefix,
          field: 'prefix',
          show: true,
          required: false,
        }}
        firstName={{
          label: 'First Name',
          value: state.firstName,
          field: 'firstName',
          show: true,
          required: false,
        }}
        middleName={{
          label: 'Middle Name',
          value: state.middleName,
          field: 'middleName',
          show: true,
          required: false,
        }}
        lastName={{
          label: 'Last Name',
          value: state.lastName,
          field: 'lastName',
          show: true,
          required: false,
        }}
        suffix={{
          label: 'Suffix',
          value: state.suffix,
          field: 'suffix',
          show: true,
          required: false,
        }}
        employer={{
          label: 'Employer',
          value: state.employer,
          field: 'employer',
          show: true,
          required: false,
        }}
        occupation={{
          label: 'Occupation',
          value: state.occupation,
          field: 'occupation',
          show: true,
          required: false,
        }}
        address1={{
          label: 'Mailing Address 1',
          value: state.addressLine1,
          field: 'addressLine1',
          required: false,
          show: true,
        }}
        address2={{
          label: 'Mailing Address 2',
          value: state.addressLine2,
          field: 'addressLine2',
          required: false,
          show: true,
        }}
        city={{
          label: 'City',
          value: state.city,
          field: 'city',
          required: false,
          show: true,
        }}
        state={{
          label: 'State',
          value: state.state,
          field: 'state',
          required: false,
          show: true,
        }}
        zipCode={{
          label: 'Zip Code',
          value: state.zipCode,
          field: 'zipCode',
          required: false,
          show: true,
        }}
        phone1={{
          label: 'Phone 1',
          value: state.phone1,
          field: 'phone1',
          required: false,
          show: true,
        }}
        phone2={{
          label: 'Phone 2',
          value: state.phone2,
          field: 'phone2',
          required: false,
          show: true,
        }}
        email={{
          label: 'Email',
          value: state.email,
          field: 'email',
          required: false,
          show: true,
          errorMessage: errors.emailErrorMessage,
        }}
        onChangeContactField={handleChange}
      />
      <Grid>
        <GridRow style={{ paddingBottom: 16 }}>
          <Column>
            <TextField
              label="Purpose"
              value={state.purpose}
              onChange={handleChange('purpose')}
            />
          </Column>
        </GridRow>
        <GridRow style={{ borderTop: '1px solid #919191', paddingTop: 16 }}>
          <Column md={6}>
            <MoneyField
              label="Period Beginning Balance"
              value={state.periodBeginningBalance}
              onChange={handleChange('periodBeginningBalance')}
              required
              errorMessage={errors.periodBeginningBalanceError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="Incurred Amount"
              value={state.incurredAmount}
              onChange={handleChange('incurredAmount')}
              required
              errorMessage={errors.incurredAmountError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="Payment Amount"
              value={state.paymentAmount}
              onChange={handleChange('paymentAmount')}
              required
              errorMessage={errors.paymentAmountError}
            />
          </Column>
          <Column md={6}>
            <MoneyField
              label="Period Ending Balance"
              value={state.periodEndingBalance}
              onChange={handleChange('periodEndingBalance')}
              required
              errorMessage={errors.periodEndingBalanceError}
            />
          </Column>
        </GridRow>
      </Grid>
      <DialogFooter>
        <DefaultButton
          text="Cancel"
          onClick={onCloseDialog}
          styles={{ root: { marginRight: 16 } }}
        />
        <PrimaryButton text="Save" onClick={onSave} />
      </DialogFooter>
    </Dialog>
  );
};

DebtDialog.propTypes = {
  dialogHidden: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  debtToEdit: PropTypes.object,
  updateDebt: PropTypes.func.isRequired,
  addDebt: PropTypes.func.isRequired,
  electionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  session: PropTypes.object.isRequired,
};

DebtDialog.defaultProps = {
  debtToEdit: null,
  electionYear: 0,
};

export default DebtDialog;
