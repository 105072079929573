import { createReducer } from '../../reducers/createReducer';
import { filterOutSystemTags } from '../../helpers/util';

export const initialState = {
  errors: {
    integrationPartnerError: '',
    confirmPasswordError: '',
  },
  integrationPartner: 0,
  active: true,
  memoText: '',
  defaultWinRedMemoText: 'Total earmarked through conduit WinRed PAC. Conduit Limit not affected.',
  username: '',
  password: '',
  confirmPassword: '',
  updateCredentials: false,
  automaticallyImport: true,
  // Contact info
  contactType: 0,
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  employer: '',
  occupation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  phone1: '',
  phone2: '',
  email: '',
  businessName: '',
  businessType: 0,
  contactName: '',
};

export const actions = {
  SET_INTEGRATION_SETTING: 'SET_INTEGRATION_SETTING',
  CLEAR_FIELDS_FOR_NEW: 'CLEAR_FIELDS_FOR_NEW',
  CLEAR_PASS_FIELDS: 'CLEAR_PASS_FIELDS',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  HANDLE_CHANGE_AUTHORIZED_REP: 'HANDLE_CHANGE_AUTHORIZED_REP',
  CLEAR_DEPOSITORY_ACCOUNT: 'CLEAR_DEPOSITORY_ACCOUNT',
  HANDLE_CHANGE_NO_FUTURE_CONTRIBUTIONS:
    'HANDLE_CHANGE_NO_FUTURE_CONTRIBUTIONS,',
  HANDLE_CHANGE_DEPOSITORY_ACCOUNT: 'HANDLE_CHANGE_DEPOSITORY_ACCOUNT',
  SET_ERRORS: 'SET_ERRORS',
  OPEN_PARTY_DIALOG: 'OPEN_PARTY_DIALOG',
  CLOSE_PARTY_DIALOG: 'CLOSE_PARTY_DIALOG',
  CLEAR_PARTY_ADD_NEW: 'CLEAR_PARTY_ADD_NEW',
  SELECT_PARTY: 'SELECT_PARTY',
  ADD_PARTY: 'ADD_PARTY',
  EDIT_PARTY: 'EDIT_PARTY',
  DELETE_PARTY: 'DELETE_PARTY',
  CLEAR_SELECTED_CONTACT: 'CLEAR_SELECTED_CONTACT',
  ON_CONTACT_SELECTED: 'ON_CONTACT_SELECTED',
  RESET_SELECTED_CONTACT_INFO: 'RESET_SELECTED_CONTACT_INFO',
  SET_EXISTING_DEPOSITORY_ACCOUNT: 'SET_EXISTING_DEPOSITORY_ACCOUNT',
  RESET_DEPOSITORY_ACCOUNT: 'RESET_DEPOSITORY_ACCOUNT',
};

// const defaultWinRedMemoText = 'Total earmarked through conduit WinRed PAC. Conduit Limit not affected.';

export const actionMap = {
  [actions.SET_INTEGRATION_SETTING]: (
    state,
    { data: { integrationSetting } },
  ) => {
    const r = {
      ...state,
      integrationSetting,
      integrationPartner: integrationSetting?.integrationSetting?.integrationPartner,
      memoText: integrationSetting?.integrationSetting?.memoText,
      active: integrationSetting?.integrationSetting?.active,
      username: integrationSetting?.integrationSetting.username,
      automaticallyImport: integrationSetting?.integrationSetting.automaticallyImport,
      // form state
      selectedContact: {
        ...integrationSetting?.conduitContactUser,
        _id: integrationSetting?.conduitContactUser?.contactId,
        address: {
          ...integrationSetting?.conduitContactUser?.address,
        },
      },
    };
    if (!integrationSetting?.conduitContactUser) {
      r.selectedContact = null;
    }
    return r;
  },
  [actions.CLEAR_FIELDS_FOR_NEW]: () => ({
    ...initialState,
  }),
  [actions.CLEAR_PASS_FIELDS]: (state) => ({
    ...state,
    password: '',
    confirmPassword: '',
    errors: {
      ...state.errors,
      confirmPasswordError: '',
    },
  }),
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'election') {
      return {
        ...state,
        [fieldName]: value,
        otherElectionType: ['Other', 'Special'].includes(value)
          ? state.otherElectionType
          : '',
      };
    }

    if (fieldName === 'contactType') {
      const currentLenderType = state.contactType;
      return {
        ...state,
        [fieldName]: value,
        errors: {
          ...(currentLenderType === value
            ? state.errors
            : {
                ...state.errors,
                firstNameError: '',
                lastNameError: '',
                businessNameError: '',
              }),
        },
      };
    }

    return {
      ...state,
      [fieldName]: value,
    };
  },
  [actions.SET_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors: {
      ...state.errors,
      ...errors,
    },
  }),
  [actions.CLEAR_SELECTED_CONTACT]: state => ({
    ...state,
    contactId: '',
    salutation: 'none',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    employer: '',
    occupation: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: 0,
    zipCode: '',
    county: '',
    businessName: '',
    businessType: 0,
    contactName: '',
    email: '',
    phone1: '',
    phone2: '',
    selectedContact: null,
    contactType: 0,
  }),
  [actions.ON_CONTACT_SELECTED]: (state, { data: { contact } }) => {
    const {
      address: contactAddress,
      _id,
      contactId,
      salutation,
      businessType,
      tags,
      ...rest
    } = contact;

    return {
      ...state,
      selectedContact: {
        ...contact,
        salutation: salutation || 'none',
        businessType: businessType || 0,
        address: {
          ...contact.address,
          state: (contact.address || {}).state || 0,
        },
      },
      contactId: _id,
      contactType: contact.contactType,
      ...contactAddress,
      ...rest,
      salutation: salutation || 'none',
      businessType: businessType || 0,
      description: state.description || contact.purpose || '',
      tags: filterOutSystemTags(tags),
      transactionIdNumber: state.transactionIdNumber,
    };
  },
  [actions.RESET_SELECTED_CONTACT_INFO]: state => {
    if (state.selectedContact) {
      return {
        ...state,
        salutation: state.selectedContact.salutation || 'none',
        firstName: state.selectedContact.firstName || '',
        middleName: state.selectedContact.middleName || '',
        lastName: state.selectedContact.lastName || '',
        suffix: state.selectedContact.suffix || '',
        employer: state.selectedContact.employer || '',
        occupation: state.selectedContact.occupation || '',
        addressLine1: state.selectedContact.address?.addressLine1 || '',
        addressLine2: state.selectedContact.address?.addressLine2 || '',
        city: state.selectedContact.address?.city || '',
        state: state.selectedContact.address?.state || 0,
        zipCode: state.selectedContact.address?.zipCode || '',
        county: state.selectedContact.address?.county || '',
        phone1: state.selectedContact.phone1 || '',
        phone2: state.selectedContact.phone2 || '',
        email: state.selectedContact.email || '',
        businessName: state.selectedContact.businessName || '',
        businessType: state.selectedContact.businessType || 0,
        contactName: state.selectedContact.contactName || '',
      };
    }

    return {
      ...state,
      contactType: 0,
      salutation: 'none',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      employer: '',
      occupation: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 0,
      zipCode: '',
      county: '',
      phone1: '',
      phone2: '',
      email: '',
      businessName: '',
      businessType: 0,
      contactName: '',
    };
  },
};

export const integrationSettingDialogReducer = createReducer(initialState, actionMap);
