import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { types as contributionActions } from '../../actions/contributionActions';
import { types as searchValueActions } from '../../reducers/searchValueReducer';
import { BackButton } from '../../components/common';
import ObservableContributionsList from '../../components/ObservableContributionsList';
import { scrollToTop, itemDateBeforeLastFiling } from '../../helpers/util';
import { sortDirections } from '../../helpers/constants';
import {
  getLabel,
  convertItemUpdateErrorsToMessageList,
  itemUpdateWarning,
} from '../../helpers/labelHelper';
import { useIntersect } from '../../hooks/useIntersect';
import {
  getCampaign,
  getCheckForNoOccupationOrEmployer,
  getContributionsList,
  getContributionsListStatus,
  getEndDate,
  getNameSearchValue,
  getStartDate,
  getUserSession,
} from '../../selectors';
import { buildParams } from './viewAllHelper';
import './ViewAll.css';
import { ConfirmContinueUpdateDialog } from '../../components/Dialogs';

export const ViewAllContributions = ({ history }) => {
  const reduxDispatch = useDispatch();
  const contributions = useSelector(getContributionsList);
  const getContributionsStatus = useSelector(getContributionsListStatus);
  const filterText = useSelector(getNameSearchValue);
  const endDate = useSelector(getEndDate);
  const startDate = useSelector(getStartDate);
  const session = useSelector(getUserSession);
  const campaign = useSelector(getCampaign);
  const checkForNoEmployeeOrOccupation = useSelector(getCheckForNoOccupationOrEmployer);
  const { lastFiledReportEndDate } = campaign;

  const [state, setState] = useState({
    skip: 0,
    limit: 100,
    sortBy: '',
    sortDir: sortDirections.NONE,
    confirmContinueUpdateHidden: true,
    continueEditMessageList: [],
    editItemId: null,
    startDate: null,
    endDate: null,
  });
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const [backToTopButtonRef, scrollContainer] = useIntersect({});

  useEffect(() => {
    scrollToTop();
    return () => {
      reduxDispatch({
        type: contributionActions.CLEAR_CONTRIBUTIONS,
      });
    };
  }, []);

  useEffect(() => {
    if (scrollContainer?.isIntersecting === true && showBackToTopButton) {
      setShowBackToTopButton(false);
    } else if (
      scrollContainer?.isIntersecting === false &&
      !showBackToTopButton &&
      contributions?.length > 100
    ) {
      setShowBackToTopButton(true);
    }
  }, [scrollContainer, contributions]);

  useEffect(() => {
    const params = buildParams(state, filterText, startDate, endDate, checkForNoEmployeeOrOccupation);
    reduxDispatch({
      type: contributionActions.GET_CONTRIBUTIONS,
      data: { params },
    });
  }, [filterText, startDate, endDate, checkForNoEmployeeOrOccupation, state.sortBy, state.sortDir]);

  useEffect(() => {
    if (state.skip > 0) {
      const params = buildParams(state, filterText, startDate, endDate, checkForNoEmployeeOrOccupation);
      reduxDispatch({
        type: contributionActions.GET_CONTRIBUTIONS_NEXT_PAGE,
        data: { params },
      });
    }
  }, [state.skip]);

  const nextPage = () => {
    setState(state => ({
      ...state,
      skip: state.skip + 1,
    }));
  };

  const handleChange = fieldName => (e, val) => {
    const value = val.key !== undefined ? val.key : val;
    setState({
      ...state,
      [fieldName]: value,
      skip: 0,
    });
  };

  const setSort = ({ sortField, sortDirection }) => {
    setState({
      ...state,
      sortBy: sortField,
      sortDir: sortDirection,
      skip: 0,
    });
  };

  const edit = id => {
    history.push(`/filer/editContribution/${id}`);
  };

  const editClick = id => {
    const item = contributions.find(c => c._id === id);
    const { isItemFiled, reconciliationId, depositId, receivedDate } = (item || {});
    const isBadItemDate = itemDateBeforeLastFiling(receivedDate, lastFiledReportEndDate);
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: 'receipt',
      isItemFiled,
      reconciliationId,
      depositId,
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return setState(state => ({
        ...state,
        confirmContinueUpdateHidden: false,
        continueEditMessageList,
        editItemId: id,
      }));
    }

    edit(id);
  };

  const cancelContinueUpdate = () => {
    return setState(state => ({
      ...state,
      confirmContinueUpdateHidden: true,
      continueEditMessageList: [],
      editItemId: null,
    }));
  };

  const menuProps = useMemo(() => ({
    items: [
      {
        key: 'Monetary',
        text: `Monetary ${getLabel('Contribution', session)}`,
        iconProps: {
          iconName: 'WavyBill',
          className: 'ms-fontColor-themePrimary',
        },
        onClick: () => history.push('/filer/addContribution?type=Monetary'),
      },
      {
        key: 'In Kind',
        text: `In Kind/Other ${getLabel('Contribution', session)}`,
        iconProps: {
          iconName: 'WavyBill',
          className: 'ms-fontColor-themePrimary',
        },
        onClick: () => history.push('/filer/addContribution?type=In Kind'),
      },
    ],
  }));

  const handleFilterChange = (e, entry) => {
    reduxDispatch({
      type: searchValueActions.SET_NAME_SEARCH,
      data: { nameSearchValue: entry },
    });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const changeDate = fieldName => value => {
    const payload = { fieldName, value };
    reduxDispatch({
      type: searchValueActions.HANDLE_CHANGE,
      data: payload,
    });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const clearDates = () => {
    reduxDispatch({ type: searchValueActions.CLEAR_DATES });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const setCheckForNoOccupationOrEmployer = () => {
    reduxDispatch({
      type: searchValueActions.SET_NO_EMPLOYEE_OR_OCCUPUPATION_VALIDATION,
      data: { checkForNoEmployeeOrOccupation: !checkForNoEmployeeOrOccupation },
    });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const actions = {
    handleFilterChange,
    handleChange,
    nextPage,
    setSort,
    changeDate,
    clearDates,
    setCheckForNoOccupationOrEmployer,
  };

  return (
    <>
      <BackButton
        history={history}
        pageTitle={getLabel('Contributions', session)}
      />
      <div className="ViewAllContributions depth-1">
        <header className="contributions-header" ref={backToTopButtonRef}>
          <PrimaryButton
            menuIconProps={{
              iconName: 'CaretDown',
            }}
            menuProps={menuProps}
            text="Add"
            iconProps={{
              iconName: 'PlusCircle',
            }}
          />
        </header>
        <div className="view-all-contributions-content">
          <ObservableContributionsList
            history={history}
            editItem={editClick}
            contributions={contributions}
            getContributionsStatus={getContributionsStatus}
            session={session}
            filterText={filterText}
            startDate={startDate}
            endDate={endDate}
            checkForNoOccupationOrEmployer={checkForNoEmployeeOrOccupation}
            actions={actions}
            sortField={state.sortBy || 'updatedAt'}
            sortDirection={state.sortDir || sortDirections.DESC}
          />
        </div>
        <ConfirmContinueUpdateDialog
          dialogHidden={state.confirmContinueUpdateHidden}
          cancel={cancelContinueUpdate}
          confirm={() => edit(state.editItemId)}
          messageList={state.continueEditMessageList}
          instruction={itemUpdateWarning}
        />
        {showBackToTopButton && (
          <div className="back-to-top">
            <IconButton
              iconProps={{ iconName: 'ArrowUp' }}
              onClick={scrollToTop}
              title="Back to Top"
            />
          </div>
        )}
      </div>
    </>
  );
};

ViewAllContributions.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ViewAllContributions);
