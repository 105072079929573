import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Toggle,
  DefaultButton,
  PrimaryButton,
  Checkbox,
} from 'office-ui-fabric-react';
import {
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react/lib/Dialog';
import { Grid, GridRow, Column, PasswordField } from '../common';
import { getLabel, businessNameLabel } from '../../helpers/labelHelper';
import { toastTypes, FECSourceTypeCodes } from '../../helpers/constants';
import { useContactSearch } from '../../hooks/useContactSearch';
import {
  IntegrationPartnerPicker,
  ContactPicker,
} from '../Pickers';

import { Toast } from '../Toast';
import { validate, validateConfirmPassword } from './integrationSettingDialogValidations';
import { initialState, integrationSettingDialogReducer, actions } from './integrationSettingDialogReducer';

import './IntegrationSettingDialog.css';

export const IntegrationSettingDialog = ({
  formAction,
  onSave,
  onCancel,
  onDelete,
  campaign,
  session,
  dialogHidden,
  messagingActions,
  integrationSetting,
  messages,
}) => {
  const [state, localDispatch] = useReducer(integrationSettingDialogReducer, initialState);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const reduxDispatch = useDispatch();

  const onResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const clearFields = () => {
    localDispatch({
      type: actions.CLEAR_FIELDS_FOR_NEW,
    });
  };

  const doOnCancel = () => {
    onCancel();
    clearFields();
  };

  useEffect(() => {
    if (formAction === 'update') {
      localDispatch({
        type: actions.SET_INTEGRATION_SETTING,
        data: {
          integrationSetting,
        },
      });
    }
  }, [integrationSetting, formAction]);

  const clearSelectedContact = () => {
    localDispatch({ type: actions.CLEAR_SELECTED_CONTACT });
  };

  // form change handlers
  const handleChange = fieldName => (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName,
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleCredentialsCheck = (e, value) => {
    localDispatch({ type: actions.CLEAR_PASS_FIELDS });
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'updateCredentials',
        value,
      },
    });
  };

  const save = (formAction) => {
    const errors = validate({ state });

    localDispatch({ type: actions.SET_ERRORS, data: { errors } });

    if (Object.values(errors).some(c => c.length > 0)) {
      reduxDispatch({
        type: messagingActions.SET_TOAST,
        data: {
          toastType: toastTypes.ERROR,
          message: 'There are form errors, please correct any to continue',
        },
      });
    } else {
      const payload = {
        integrationPartner: state.integrationPartner,
        conduitContactId: state?.selectedContact?._id || undefined,
        memoText: state.memoText || undefined,
        username: state.username || undefined,
        password: state.password || undefined,
        confirmPassword: state.confirmPassword || undefined,
        automaticallyImport: state.automaticallyImport,
        active: state.active,
      };

      onSave(payload, formAction);
    }
  };

  const doOnSave = (formAction) => {
    save(formAction);
    clearFields();
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    state.election,
    campaign.isNonCandidateCommittee
      ? new Date().getFullYear()
      : state.electionYear,
    state.contactType,
    'Loan',
  );

  const onItemSelected = contact => {
    localDispatch({ type: actions.ON_CONTACT_SELECTED, data: { contact } });
    return null;
  };

  const resetContactFields = () => {
    localDispatch({ type: actions.RESET_SELECTED_CONTACT_INFO });
  };

  const getContactPicker = () => {
    return (
      <ContactPicker
        session={session}
        selectedContact={state.selectedContact}
        disallowCreate={true}
        clearSelectedContact={clearSelectedContact}
        cancelAddNew={clearSelectedContact}
        onResolveSuggestions={onResolveSuggestions}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        onItemSelected={onItemSelected}
        onChangeContactField={handleChange}
        resetContactFields={resetContactFields}
        contactType={{
          label: 'ConduitType',
          field: 'contactType',
          required: true,
          show: true,
          errorMessage: state.errors.lenderTypeError,
          value: state.contactType,
          disabled: false,
          options: session.isFederal()
            ? [
              {
                key: FECSourceTypeCodes.IND,
                text: 'Individual (a person)',
              },
              {
                key: FECSourceTypeCodes.ORG,
                text: 'Organization (not a committee and not a person)',
              },
              {
                key: FECSourceTypeCodes.CAN,
                text: 'Candidate',
              },
            ]
            : ['Individual', 'Business'],
        }}
        committeeFecId={{
          label: 'FEC ID',
          field: 'committeeFecId',
          value: state.committeeFecId,
          show: true,
        }}
        salutation={{
          label: 'Salutation',
          field: 'salutation',
          value: state.salutation,
          required: false,
          show: true,
        }}
        firstName={{
          label: 'First Name',
          field: 'firstName',
          value: state.firstName,
          required: true,
          errorMessage: state.errors.firstNameError,
          show: true,
        }}
        middleName={{
          label: 'Middle Name',
          field: 'middleName',
          value: state.middleName,
          required: false,
          show: true,
        }}
        lastName={{
          label: 'Last Name',
          field: 'lastName',
          value: state.lastName,
          required: true,
          errorMessage: state.errors.lastNameError,
          show: true,
        }}
        suffix={{
          label: 'Suffix',
          field: 'suffix',
          value: state.suffix,
          required: false,
          show: true,
        }}
        employer={{
          label: 'Employer',
          field: 'employer',
          value: state.employer,
          required: false,
          show: true,
        }}
        occupation={{
          label: 'Occupation',
          field: 'occupation',
          value: state.occupation,
          required: false,
          show: true,
        }}
        address1={{
          label: getLabel('Address Line 1', session),
          field: 'addressLine1',
          value: state.addressLine1,
          required: false,
          show: true,
        }}
        address2={{
          label: getLabel('Address Line 2', session),
          field: 'addressLine2',
          value: state.addressLine2,
          required: false,
          show: true,
        }}
        city={{
          label: 'City',
          field: 'city',
          value: state.city,
          required: false,
          show: true,
        }}
        state={{
          label: 'State',
          field: 'state',
          value: state.state,
          required: false,
          show: true,
        }}
        zipCode={{
          label: 'Zip Code',
          field: 'zipCode',
          value: state.zipCode,
          required: false,
          show: true,
        }}
        county={{
          label: 'County',
          field: 'county',
          value: state.county,
          required: false,
          show: true,
        }}
        phone1={{
          label: 'Phone 1',
          field: 'phone1',
          value: state.phone1,
          show: true,
        }}
        phone2={{
          label: 'Phone 2',
          field: 'phone2',
          value: state.phone2,
          show: true,
        }}
        email={{
          label: 'Email Address',
          field: 'email',
          value: state.email,
          show: true,
          errorMessage: state.errors.emailError,
        }}
        candidateOffice={{
          label: 'Candidate Office',
          field: 'candidateOffice',
          value: state.candidateOffice,
          show: true,
        }}
        candidateState={{
          label: 'Candidate State',
          field: 'candidateState',
          value: state.candidateState,
          show: true,
        }}
        candidateDistrict={{
          label: 'Candidate District',
          field: 'candidateDistrict',
          value: state.candidateDistrict,
          show: true,
        }}
        candidateFecId={{
          label: 'Candidate FEC ID',
          field: 'candidateFecId',
          value: state.candidateFecId,
          show: true,
        }}
        candidateFirstName={{
          label: 'Candidate First Name',
          field: 'candidateFirstName',
          value: state.candidateFirstName,
          show: true,
        }}
        candidateMiddleName={{
          label: 'Candidate Middle Name',
          field: 'candidateMiddleName',
          value: state.candidateMiddleName,
          show: true,
        }}
        candidateLastName={{
          label: 'Candidate Last Name',
          field: 'candidateLastName',
          value: state.candidateLastName,
          show: true,
        }}
        businessName={{
          label: businessNameLabel(state.contactType),
          field: 'businessName',
          value: state.businessName,
          show: true,
          required: true,
          errorMessage: state.errors.businessNameError,
        }}
        businessType={{
          label: session.isFederal()
            ? 'Organization Type'
            : 'Business Type',
          field: 'businessType',
          value: state.businessType,
          required: false,
          show: true,
        }}
        contactName={{
          label: 'Contact Name',
          field: 'contactName',
          value: state.contactName,
          required: false,
          show: true,
        }}
        committeeAffiliation={{
          label: 'Committee Type',
          field: 'committeeAffiliation',
          value: state.committeeAffiliation,
          show: true,
        }}
        commonSource={{
          label: 'Common Source',
          field: 'commonSource',
          value: state.commonSource,
          show: true,
        }}
        specific={{
          label: 'Specific',
          field: 'specific',
          value: state.specific,
          show: true,
        }}
      />
    );
  };

  const validateConfirm = () => {
    const errors = validateConfirmPassword({ state });
    localDispatch({ type: actions.SET_ERRORS, data: { errors } });
  };

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={onCancel}
      minWidth={screenWidth < 910 ? '95%' : '50%'}
      maxMidth={screenWidth < 910 ? '95%' : 775}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: `${formAction === 'new' ? 'Create' : 'Update'} API Integration`,
      }}
      modalProps={{
        isBlocking: true,
      }}
    >
      <div className="IntegrationSettingForm">
        <Grid>
          <GridRow>
            <Column lg={8}>
              <IntegrationPartnerPicker
                label="Integration Partner"
                required
                value={state.integrationPartner}
                onChange={handleChange('integrationPartner')}
                errorMessage={state.errors.integrationPartnerError}
              />
            </Column>
            <Column lg={2}>
              <Toggle
                label={state.active ? <span>Active<br />(Accept requests)</span> : <span>Active<br />(No longer accept requests)</span>}
                onText="Yes"
                offText="No"
                checked={state.active}
                onChange={handleChange('active')}
              />
            </Column>
            {!state.automaticallyImport &&
            <Column lg={2}>
              <Toggle
                label="Auto Import (permanent once activated)"
                onText="Yes"
                offText="No"
                checked={state.automaticallyImport}
                onChange={handleChange('automaticallyImport')}
              />
            </Column>
            }
          </GridRow>
          {state.integrationPartner === 'WinRed' &&
            <>
              <GridRow>
                <Column>
                  {getContactPicker()}
                </Column>
              </GridRow>
              <GridRow>
                <Column>
                  <TextField
                    value={state.memoText}
                    onChange={handleChange('memoText')}
                    label="Memo Text"
                  />
                </Column>
              </GridRow>
            </>
          }
          {state.integrationPartner === 'ActBlue' &&
            <>
              {formAction !== 'new' &&
              <GridRow style={{ marginTop: '16px' }}>
                <Column>
                  <Checkbox
                    label="Change existing username and/or password"
                    checked={state.updateCredentials}
                    onChange={handleCredentialsCheck}
                  />
                </Column>
              </GridRow>
              }
              {(formAction === 'new' || state.updateCredentials) &&
              <GridRow>
                <Column lg={4}>
                  <TextField
                    value={state.username}
                    onChange={handleChange('username')}
                    label="User Name"
                  />
                </Column>
                <Column lg={4}>
                  <div style={{ marginTop: '28px' }}>
                    <PasswordField
                      label="Password:"
                      underlined
                      autofocus
                      required
                      name="password"
                      value={state.password}
                      errorMessage={state.passwordError}
                      onChange={handleChange('password')}
                    />
                  </div>
                </Column>
                <Column lg={4}>
                  <div style={{ marginTop: '28px' }}>
                    <PasswordField
                      label="Confirm Password:"
                      underlined
                      autofocus
                      required
                      name="confirmPassword"
                      value={state.confirmPassword}
                      errorMessage={state.errors.confirmPasswordError}
                      onBlur={validateConfirm}
                      onChange={handleChange('confirmPassword')}
                    />
                  </div>
                </Column>
              </GridRow>
              }
            </>
          }
        </Grid>
        {messages.message && (
        <Toast
          message={messages.message}
          toastType={messages.toastType}
          position={messages.position}
        />
      )}
        <DialogFooter>
          <div className="integration-setting-action-wrapper">
            <div className="actions">
              <DefaultButton
                text="Cancel"
                onClick={doOnCancel}
                className="integration-setting-btn"
              />
              <PrimaryButton
                text="Delete"
                onClick={onDelete}
                className="integration-setting-btn"
                disabled={formAction === 'new'}
              />
              <PrimaryButton
                text="Save"
                onClick={() => doOnSave(formAction)}
                className="integration-setting-btn"
              />
            </div>
          </div>
        </DialogFooter>
      </div>
    </Dialog>
  );
};

IntegrationSettingDialog.propTypes = {
  formAction: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  campaign: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  messagingActions: PropTypes.object.isRequired,
  integrationSetting: PropTypes.object,
  saveButtonDisabled: PropTypes.bool.isRequired,
  messages: PropTypes.object.isRequired,
};

IntegrationSettingDialog.defaultProps = {
  onDelete: null,
  integrationSetting: null,
};
